import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Switch
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
// import Login from './pages/admin/Login';
// import Home from './pages/admin/Home';
// import ControllerPasscodeList from './pages/admin/ControllerPasscodeList';
// import ControllerAccessLog from './pages/admin/ControllerAccessLog';
// import ControllerDeviceList from './pages/admin/ControllerDeviceList';
// import CompanyUserAccessLog from './pages/admin/CompanyUserAccessLog';
// import CompanyUserDoorQRCode from './pages/admin/CompanyUserDoorQRCode';
// import CompanyUserRole from './pages/admin/CompanyUserRole';
// import CompanyList from './pages/admin/CompanyList';
// import CompanyDoor from './CompanyDoor';
// import CompanyDoor from './pages/admin/CompanyDoor';
// import CompanyUserDoor from './pages/admin/CompanyUserDoor';

// import CompanyUserList from './pages/admin/CompanyUserList';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
// import UserQRCode from './UserQRCode';
// import CompanyAdminLogin from './pages/company/Login';
// import CompanyAdminHome from './pages/company/Home';
// import CompanyAdminCompanyUserAccessLog from './pages/company/CompanyUserAccessLog';
// import CompanyAdminCompanyDoor from './pages/company/CompanyDoor';
// import CompanyAdminCompanyUser from './pages/company/CompanyUserList';
// import CompanyAdminCompanyUserDoor from './pages/company/CompanyUserDoor';
// import CompanyAdminCompanyDoorQRCode from './pages/company/CompanyDoorQRCode';
// import OrderInfo from './pages/company/OrderInfo';
// import OrderInfoForm from './pages/company/OrderInfoForm';
// import ProductItemList from './pages/company/ProductItemList';
// import ProductItemForm from './pages/company/ProductItemForm';
// import InvoiceInfoList from './pages/company/InvoiceInfoList';
// import InvoiceInfoForm from './pages/company/InvoiceInfoForm';
// import ReceiptInfoList from './pages/company/ReceiptInfoList';
// import ReceiptInfoForm from './pages/company/ReceiptInfoForm';
// import UserAccount from './pages/company/UserAccount';
import RegForm from './pages/member/RegForm';
import RegNotice from './pages/member/RegNotice';
import RegJoinInfoForm from './pages/member/RegJoinInfoForm';
import RegSucessForm from './pages/member/RegSucessForm';
// import UserAccountContainer from './pages/company/UserAccountContainer';
// import Setting from './pages/company/Setting';
// import SiteLogoForm from './pages/company/SiteLogoForm';
// import OptionSetting from './pages/company/OptionSetting';

// import CompanyList from './pages/admin/CompanyList'
// import CompanyDetails from './pages/admin/CompanyDetail'
// import AdminAccountList from './pages/admin/AdminAccountList';
// import MemberList from './pages/admin/MemberList';
// import MemberDetails from './pages/admin/MemberDetails';
// import MemberAccessLog from './pages/admin/MemberAccessLogList';
// import CompanyList from './pages/admin/CompanyList';
// import CompanyDetails from './pages/admin/CompanyDetails';
// import AdminSettings from './pages/admin/AdminSettings';
// import AdminSettingsDetails from './pages/admin/AdminSettingsDetails'

import Login from './pages/company/Login';
import Home from './pages/company/Home';
import AdminAccountList from './pages/company/AdminAccountList';
import MemberList from './pages/company/MemberList';
import MemberDetails from './pages/company/MemberDetails';
import MemberAccessLog from './pages/company/MemberAccessLogList';
import CompanyList from './pages/company/CompanyList';
import CompanyDetails from './pages/company/CompanyDetails';
import AdminSettings from './pages/company/AdminSettings';
import AdminSettingsDetails from './pages/company/AdminSettingsDetails'


import CompanyQuotaList from './pages/company/QuotaInfo/CompanyQuotaList'
import QuotaList from './pages/company/QuotaList'
import CompanyUserQuotaLogList from './pages/company/QuotaLog/CompanyUserQuotaLogList'
import UserbookingContainer from './pages/company/BookingInfo/UserbookingContainer'
import BookingList from './pages/company/BookingInfo/BookingList'
import CompanyUserBookingLogList from './pages/company/BookingLog/CompanyUserBookingLogList'

import PostListDetails from './pages/company/PostsList';
import AdminResetPassword from './pages/admin/AdminResetPassword';
//QuotaList
class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          {/* Admin Router */}
          {/* <PublicRoute restricted path="/admin/login" component={Login} exact />
          <PrivateRoute restricted path="/admin/home" component={Home} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/member/list" component={MemberList} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/member/details" component={MemberDetails} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/list" component={AdminAccountList} privilege={'admin'} exact />
          <PrivateRoute restricted path="/admin/access_log" component={MemberAccessLog} privilege={'admin'} exact />
          <PublicRoute restricted path="/admin/company/list" component={CompanyList} privilege={'admin'} exact />
          <PublicRoute restricted path="/admin/company/details" component={CompanyDetails} privilege={'admin'} exact />
          <PublicRoute restricted path="/admin/setting" component={AdminSettings} privilege={'admin'} exact />
          <PublicRoute restricted path="/admin/setting/details" component={AdminSettingsDetails} privilege={'admin'} exact /> */}

          {/* Member Router */}
          <PublicRoute restricted path="/member/register" component={RegNotice} exact />
          <PublicRoute restricted path="/member/form" component={RegForm} exact />
          <PublicRoute restricted path="/member/terms" component={RegJoinInfoForm} exact />
          <PublicRoute restricted path="/member/success" component={RegSucessForm} exact />

          {/* Company Router */}
          <PublicRoute restricted path="/company/login" component={Login} exact />
          <PrivateRoute restricted path="/company/home" component={Home} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/member/list" component={MemberList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/member/details" component={MemberDetails} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/list" component={AdminAccountList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/access_log" component={MemberAccessLog} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/company/list" component={CompanyList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/company/details" component={CompanyDetails} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/reset_password" component={AdminResetPassword} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/setting" component={AdminSettings} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/setting/details" component={AdminSettingsDetails} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/bookingItem" component={UserbookingContainer} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/bookingItem/List" component={BookingList} privilege={'company'} exact />
          
          <PrivateRoute restricted path="/company/quota/list" component={QuotaList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/quota/list/log" component={CompanyUserQuotaLogList} privilege={'company'} exact />


          <PrivateRoute restricted path="/company/postList" component={PostListDetails} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/bookingItem/list/log" component={CompanyUserBookingLogList} privilege={'company'} exact />


          
         
          {/* <PrivateRoute restricted path="/company/user_account_form" component={UserAccountContainer} privilege={'company'} exact /> */}
          {/* <PublicRoute restricted path="/company/login" component={CompanyAdminLogin} exact />
          <PrivateRoute restricted path="/company/home" component={CompanyAdminHome} privilege={'company'} exact />
          {/*<PrivateRoute restricted path="/company/order_info" component={OrderInfo} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/order_info_form" component={OrderInfoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/product_item" component={ProductItemList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/product_item_form" component={ProductItemForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/invoice_info" component={InvoiceInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/invoice_info_form" component={InvoiceInfoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/receipt_info" component={ReceiptInfoList} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/receipt_info_form" component={ReceiptInfoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/user_account" component={UserAccount} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/setting" component={Setting} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/site_logo_form" component={SiteLogoForm} privilege={'company'} exact />
          <PrivateRoute restricted path="/company/option_setting" component={OptionSetting} privilege={'company'} exact /> */}
          {/* <PrivateRoute restricted path="/company/companyuser/accesslog" component={CompanyAdminCompanyUserAccessLog} privilege={'company'} exact /> */}
          {/* <PrivateRoute restricted path="/company/company/door" component={CompanyAdminCompanyDoor} privilege={'company'} exact /> */}
          {/* <PrivateRoute restricted path="/company/company/user" component={CompanyAdminCompanyUser} privilege={'company'} exact /> */}
          {/* <PrivateRoute restricted path="/company/company/user/door" component={CompanyAdminCompanyUserDoor} privilege={'company'} exact /> */}
          {/* <PrivateRoute restricted path="/company/company/door/qrcode" component={CompanyAdminCompanyDoorQRCode} privilege={'company'} exact /> */}

          <Redirect exact from="/company/*" to="/company/home" />
          <Redirect exact from="*" to="/member/register" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
