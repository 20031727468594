import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Space, Row, Col, Form, Input, Select, message, Tabs, DatePicker, TimePicker, Switch, notification, Divider, InputNumber, Radio, AutoComplete, Modal, Typography, Tooltip, Button
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import * as debugLib from 'debug';
import * as Service from '../core/Service';
import { useTranslation } from 'react-i18next';
// import Button from './t9UIComponents/t9Button';
import { useSelector } from "react-redux";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

const BookingInfoModal = (props) => {
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
  );
  // formType {1}: company, {2}: user
  const { t } = useTranslation();
  const {
    formType, dataObj, modalVisible, setModalVisible, handleFormSubmit
  } = props
  const [loading, setLoading] = useState(true);
  const [targetUser, setTargetUser] = useState(0);
  const [quotaLeft, setQuotaLeft] = useState('0');
  const [userOption, setUserOption] = useState('user');
  const [number, setNumber] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isCompany, setIsCompany] = useState();
  const [userList, setUserList] = useState([])
  let data;

  // console.log('userList>>>', userList)
  const [form] = Form.useForm();
  const defaultFormData = { mobile_prefix: '+852', user_option: userOption }
  useEffect(() => {
    form.resetFields();
    // form.setFieldsValue(defaultFormData)
    getUserList(dataObj.type)

    if (dataObj.status === 1) {

      setIsActive(true);
    } else {
      setIsActive(false);
    }
    // setTargetUser(dataObj.user_id)
  }, [dataObj]);



  useEffect(() => {
    if (targetUser === 0) {
      setQuotaLeft('0');
    } else {
      getUserQuota(targetUser, dataObj.item_category_id);
    }
  }, [targetUser])

  // useEffect(() => {

  //   let formData = form.getFieldsValue();

  //   let newFormData = { ...formData, user_option: userOption }
  //   form.setFieldsValue(newFormData);

  // }, [userOption])

  const getUserQuota = async (user_id, item_category_id) => {
    let { status, data } = await Service.call('get', `/api/user_quota/list/${user_id}/${item_category_id}`);
    // console.log("testestes", `/api/user_quota/list/${user_id}/${item_category_id}`)
    if (!_.isUndefined(data)) {
      if (data.expired === 1) {
        setQuotaLeft('0')
      } else {
        setQuotaLeft(data.quota_amount)
      }
    } else {
      setQuotaLeft('0')
    }
  }

  const getUserList = async (value) => {
    setLoading(true)

    if (dataObj.user_id === 0) {
      form.setFieldsValue({ user_id: "" })
      form.setFieldsValue({ remarks: "" })
      setQuotaLeft('0')
    }

    let user_ListArr;
    if (value === 1) {

      //individualMember
      user_ListArr = await Service.call('get', "/api/user/list?type=individualMember");
    } else if (value == 2) {
      user_ListArr = await Service.call('get', "/api/user/list?type=student");
    } else {
      user_ListArr = await Service.call('get', "/api/user/list?type=companyMember");
    }
    // console.log('user_ListArr>>>', user_ListArr)
    // let userArr = await Service.call('get', "/api/user/list/1");
    let userArrMap = []
    _.forEach(user_ListArr, (user) => {
      if ((value === 2 || value === 1) && user.is_active === 1) {
        userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.user_key+" "+user.first_name + " " + user.last_name}</Option>)
      } else if (user.is_active === 1) {
        userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.user_key+" "+user.company_name_en}</Option>)
      }
    })
    setUserList(userArrMap)
    setLoading(false)
  }


  // let userArr = await Service.call('get', "/api/user/list/1");
  // let userArrMap = []
  // _.forEach(userArr, (user) => {
  //   userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.first_name + " " + user.last_name}</Option>)
  // })

  // setUserList(userArrMap)

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  // console.log('dataObj.user_id', dataObj.user_id)
  // console.log('check', targetUser)

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!_.isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setNumber(value);
      form.setFieldsValue({ mobile: value })
    }
  };

  const renderUserBookingForm = () => {
    return (
      <div>
        <Form.Item
          label="會員類別"
          name="type"
          rules={[
            {
              required: true,
              message: "必須填寫"
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t('selectLabel')}
            onChange={(value) => {
              setIsCompany(value)
              getUserList(value)
            }}
            disabled={!isActive}
          >
            <Option key={1} value={1}>一般會員</Option>
            <Option key={2} value={2}>學生會員</Option>
            <Option key={3} value={3}>公司會員</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={t('會員名稱')}
          name="user_id"
        >
          <Select
            onChange={(value) => {
              setTargetUser(value);
              getUserQuota(value, dataObj.item_category_id)
            }}
            disabled={!isActive}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>  
              // console.log('input>>', input)
              // console.log('option.props.children.toLowerCase().indexOf(input.toLowerCase())>>', option.props.children.toLowerCase().indexOf(input.toLowerCase()))
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {userList}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('remarks')}
          name="remarks"
        >
          <Input maxLength={128} disabled={!isActive} />
        </Form.Item>

        <Form.Item
          wrapperCol={24}
          style={{
            textAlign: "right",
            fontSize: 15,
            color: '#8c8c8c',
            display: !isActive ? "None" : null
          }}
        >
          <Typography.Text>
            {t('available_quota')}
            {/* Available Quota: */}
            {quotaLeft}
          </Typography.Text>
        </Form.Item>


      </div>
    )
  };

  const renderGuestBookingForm = () => {
    return (
      <div>
        <Form.Item
          label={t('first_name')}
          name="first_name"
        >
          <Input maxLength={20} disabled={!isActive} />
        </Form.Item>

        <Form.Item
          label={t('last_name')}
          name="last_name"
        >
          <Input maxLength={20} disabled={!isActive} />
        </Form.Item>

        <Form.Item
          label={t('nickname')}
          name="nickname"
        >
          <Input maxLength={32} disabled={!isActive} />
        </Form.Item>

        <Form.Item
          label={t('email')}
          name="email"
          rules={[{ type: 'email' }]}
        >
          <Input maxLength={128} disabled={!isActive} />
        </Form.Item>
        <Form.Item
          label={t('moblie')}
          name="mobile"
        >
          <Tooltip>
            <Input
              value={number}
              onChange={onNumberChange}
              addonBefore={(
                <Form.Item name="mobile_prefix" noStyle>
                  <Select style={{ width: 80 }} disabled={!isActive}>
                    <Option value="+852">+852</Option>
                  </Select>
                </Form.Item>
              )}
              maxLength={15}
              style={{ width: '100%' }}
              disabled={!isActive}
            />
          </Tooltip>
        </Form.Item>
        <Form.Item
          label={t('remarks')}
          name="remarks"
        >
          <Input maxLength={128} disabled={!isActive} />
        </Form.Item>
      </div>
    )
  };

  const renderCompanyEditForm = () => {
    return (
      <div>
        {/* <Form.Item
          label={t('user_option')}
          name="user_option"
        >
          <Radio.Group
            onChange={(value) => {
              setUserOption(value.target.value);
            }}
            disabled={!isActive}
          >
            <Radio value="user">{t('user')}</Radio>
            <Radio value="guest">{t('guest')}</Radio>
          </Radio.Group>
        </Form.Item> */}
        {renderUserBookingForm()}
        {/* {userOption === 'user' ? renderUserBookingForm() : renderGuestBookingForm()} */}
        <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            htmlType="submit"
          >
            {dataObj.status === 1 ? t('confirm_booking') : t('cancel_booking')}
          </Button>
        </Form.Item>
      </div>
    )
  }

  const renderUserEditForm = () => {
    if (dataObj.status === 1) {
      return (
        <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            htmlType="submit"
          >
            {t('confirm_booking')}
          </Button>
        </Form.Item>
      )
    }
    if (dataObj.status === 10) {
      return (
        <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
          <Button
            type="danger"
            htmlType="submit"
          >
            {t('cancel_booking')}
          </Button>
        </Form.Item>
      )
    }
    return (
      <Form.Item wrapperCol={16} style={{ textAlign: "right" }}>
        <Button
          disabled
        >
          {t('fulled')}
        </Button>
      </Form.Item>
    )
  }

  const renderBasicForm = () => {
    return (
      <div>
        <Form.Item label={t('name')} name="item_name">
          {dataObj.item_name}
        </Form.Item>

        {dataObj.addBooking === 1 ? (

          <Form.Item
            label={t('booking_date')}
            name="booking_date"
          >
            <DatePicker defaultValue={moment()} />
          </Form.Item>
        )
          : (<Form.Item label={t('booking_date')}>
            {moment.unix(dataObj.start_time).format('YYYY-MM-DD ')}
          </Form.Item>)
        }



      </div>

    )


  }
  return (
    <Modal
      title={t('quota_details')}
      // title={dataObj.title}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        form.resetFields()
        // setTargetUser(dataObj.user_id)
        form.setFieldsValue(defaultFormData)
        setQuotaLeft('0')
        setUserList([])
        setModalVisible(false)
      }}
      bodyStyle={{ paddingBottom: "1px" }}
      style={{ width: '70vw', maxWidth: '410px' }}
      forceRender
    >
      <Form
        {...layout}
        form={form}
        name="BookingInfoForm"
        layout="horizontal"
        initialValues={dataObj}
        onFinish={(formData) => {
          let postObj = formData

          postObj.booking_id = dataObj.booking_id
          postObj.item_name = dataObj.item_name
          postObj.category_name = dataObj.category_name
          postObj.start_time = moment.unix(dataObj.start_time).format('YYYY-MM-DD HH:mm')
          postObj.end_time = moment.unix(dataObj.end_time).format('YYYY-MM-DD HH:mm')
          postObj.section_duration = dataObj.section_duration / 60
          postObj.section_price = dataObj.section_price
          postObj.item_feature_image = dataObj.item_feature_image
          postObj.booking_id = dataObj.booking_id
          postObj.status = dataObj.status



          // console.log("postObj", postObj.booking_date)
          if (dataObj.addBooking === 1) {
            let todayTo
            let todayFrom
            if (!_.isUndefined(postObj.booking_date)) {
              todayTo = new Date(new Date(postObj.booking_date).setHours(9, 0))
              todayFrom = new Date(new Date(postObj.booking_date).setHours(18, 0))


            } else {
              todayTo = new Date(new Date().setHours(9, 0))
              todayFrom = new Date(new Date().setHours(18, 0))


            }
            postObj.start_time = moment(todayTo).unix()
            postObj.end_time = moment(todayFrom).unix()


          }
          // if (!_.isUndefined(postObj.booking_date)) {
          //   let todayTo = new Date(new Date(postObj.booking_date).setHours(9, 0))
          //   let todayFrom = new Date(new Date(postObj.booking_date).setHours(18, 0))

          //   postObj.start_time = moment(todayTo).unix()
          //   postObj.end_time = moment(todayFrom).unix()

          // }



          // postObj.name = dataObj.first_name + "" + dataObj.last_name

          if (postObj.user_id === 0) {
            notification.error({ message: t('please_select_user') });
          } else {
            // console.log("postObj", postObj)
             handleFormSubmit(postObj)
          }
        }}
      >
        {renderBasicForm()}
        {/* 
        <Form.Item label={t('name')} name="item_name">
          {data.item_name}
        </Form.Item> */}

        {/* <Form.Item label={t('category_name')}>
          {dataObj.category_name}
        </Form.Item> */}

        {/* <Form.Item label={t('item')}>
          {dataObj.title}
        </Form.Item> */}



        {/* <Form.Item label={t('end_booking_time')}>
          {moment.unix(dataObj.end_time).format('YYYY-MM-DD')}
        </Form.Item>

        <Form.Item label={t('section_duration')}>
          {dataObj.section_duration / 60 + "mins"}
        </Form.Item> */}

        {/* <Form.Item label={t('buffer')}>
          {dataObj.buffer / 60 + "mins"}
        </Form.Item> */}

        {/* <Form.Item label={t('section_price')}>
          {'$' + dataObj.section_price}
        </Form.Item> */}

        {formType === 1 ? renderCompanyEditForm() : renderUserEditForm()}


      </Form>
    </Modal>
  )
}

export default BookingInfoModal;
