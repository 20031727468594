import React, { Component } from 'react';
import {
  Upload, message, Button
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import debugLib from 'debug';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

const debug = debugLib('app:components/form/FormUploadFile');

const { Dragger } = Upload;

function FormUploadFile(props) {
  const {
    style,
    type,
    imageURL,
    data,
    onChange,
    buttonText,
  } = props;

  let DisplayContent;

  if (type === 'button') {
    DisplayContent = <UploadButton style={style} data={data} onChange={onChange} buttonText={buttonText} />;
  } else {
    DisplayContent = <UploadWidget style={style} imageURL={imageURL} data={data} onChange={onChange} />;
  }

  return DisplayContent;
}

function UploadButton(props) {
  const {
    style,
    data,
    onChange,
    buttonText,
  } = props;
  return (
    <Upload
      style={style}
      {...uploadProps}
      data={data}
      onChange={onChange}
    >
      <Button type="primary">
        <UploadOutlined />
        {buttonText || 'Click to Upload'}
      </Button>
    </Upload>
  );
}

function UploadWidget(props) {
  const {
    style,
    imageURL,
    data,
    onChange,
  } = props;
  return (
    <Dragger
      style={style}
      {...uploadProps}
      data={data}
      onChange={onChange}
    >
      {
        imageURL
          ? (
            <div>
              <img src={imageURL} style={{maxWidth: '100%'}} alt="" />
            </div>
          ) : (
            <div>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">點擊或拖曳圖片上載</p>
              <p className="ant-upload-hint">
                請使用橫向封面圖片，只可上載JPG或PNG
              </p>
            </div>
          )
      }
    </Dragger>
  );
}

const uploadProps = {
  name: 'file',
  // multiple: true,
  action: '/api/admin/media',
  // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  showUploadList: false,
  // onChange(info) {
    // debug(info)
    // const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    // if (status === 'done') {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  // },
  onError(err) {
    console.error(err)
    message.error(`上載失敗，請重試`);
  }
};

export default FormUploadFile;
