import React, { Component } from 'react'
import { Anchor, Divider, Layout, Menu } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  ScheduleOutlined,
  CrownOutlined,
  DollarOutlined,
  MailOutlined,
  HistoryOutlined,
  UnorderedListOutlined,
  ShoppingCartOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  CalendarOutlined,
  AppstoreAddOutlined,
  CarryOutOutlined
} from '@ant-design/icons';
import * as Service from '../core/Service';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../redux/actions';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// const history = useHistory();
const { Sider } = Layout;
const { SubMenu } = Menu;

let dataList;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      logout: false,
    };
  }

  async toggle() {
    let { collapsed } = this.state
    this.setState({ collapsed: !collapsed });
  }

  async logout() {
    // await Service.logout();
    this.setState({ logout: true });

    //this.props.setAuth(false)

    // this.props.setAdmin({})
    // this.props.setCompanyAdmin({})
    //  this.props.setAuth(false)




    // history.push('/admin/login');
  }

  renderMenuItem() {
    let admin = false;
    admin = _.isEmpty(this.props.app.company_admin);
    if (!_.isEmpty(this.props.app.company_user)) {
      admin = false;
    }

    return dataList.map((pathData, i) => {
      if (pathData.submenu && admin) {
        return (
          <SubMenu
            
            key={pathData.key}
            icon={pathData.icon}
            title={pathData.title}
          >
            {pathData.submenu.map((subPathData, i) => {
              return (
                <Menu.Item key={subPathData.key}>
                  <Link to={subPathData.path}>
                    {subPathData.icon}
                    {subPathData.title}
                  </Link>
                </Menu.Item>
              )
            })}
          </SubMenu>
        )
      }

      //  privilege remark: all / admin / company
      if (!admin && pathData.privilege === 'admin') { //  Company user not allow to access admin page
        return;
      }

      if (admin && pathData.privilege === 'company') { //  Admin user not allow to access company user page
        return;
      }

      let redirectPath = pathData.path;
      if (!admin) {
        redirectPath = pathData.companyPath;
      }

      return (
        <Menu.Item
          key={pathData.key}
          onClick={this.handleClick}
          className={
            pathData.className ? pathData.className.join(' ') : ''
          }
        >
          <Link to={redirectPath}>
            {pathData.icon}
            <span>{pathData.title}</span>
          </Link>
        </Menu.Item>
      )
    })
  }

  render() {
    let { defaultSelectedKeys, t } = this.props;

    dataList = [
      // { key: 'dashboard', title: t('dashboard'), path: '/admin/home', companyPath: '/company/home', icon: <DashboardOutlined />, privilege: 'all' },
      // { key: 'general_member', title: t('general_member'), path: '/admin/member/list', icon: <UserOutlined />, privilege: 'admin' },
      // { key: 'comapny_member', title: t('company_member'), path: '/admin/company/list', icon: <TeamOutlined />, privilege: 'admin' },
      // { key: 'access_log', title: t('access_log'), path: '/admin/access_log', icon: <ScheduleOutlined />, privilege: 'admin' },
      // { key: 'admin_user', title: t('admin_user'), path: '/admin/list', icon: <CrownOutlined />, privilege: 'admin' },
      // { key: 'setting', title: t('setting'), path: '/admin/setting', icon: <SettingOutlined />, privilege: 'admin' },

      { key: 'dashboard', title: t('dashboard'), path: '/admin/home', companyPath: '/company/home', icon: <DashboardOutlined />, privilege: 'all' },
      { key: 'general_member', title: t('general_member'), companyPath: '/company/member/list', icon: <UserOutlined />, privilege: 'company' },
      { key: 'comapny_member', title: t('company_member'), companyPath: '/company/company/list', icon: <TeamOutlined />, privilege: 'company' },
      { key: 'access_log', title: t('memberAccessLogTable'), companyPath: '/company/access_log', icon: <ScheduleOutlined />, privilege: 'company' },
      { key: 'admin_user', title: t('admin_user'), companyPath: '/company/list', icon: <CrownOutlined />, privilege: 'company' },
      { key: 'bookingItem', title: t('bookingItem'), companyPath: '/company/bookingItem', icon: <CalendarOutlined />, privilege: 'company' },
      { key: 'bookingItemList', title: t('bookingItemList'), companyPath: '/company/bookingItem/List', icon: <CalendarOutlined />, privilege: 'company' },
    
      { key: 'bookingLog', title: t('bookingLog'), companyPath: '/company/bookingItem/list/log', icon: <CarryOutOutlined />, privilege: 'company' },
      
      
      
///company/postList
      // { key: 'bookingItem', title: t('bookingItem'), companyPath: '/company/bookingItem', icon: <SettingOutlined />, privilege: 'company' },
      { key: 'quotalist', title: t('member_quota_table'), companyPath: '/company/quota/list', icon: <AppstoreAddOutlined />, privilege: 'company' },
      { key: 'quotalistLog', title: t('quotalistLog'), companyPath: '/company/quota/list/log', icon: <CarryOutOutlined />, privilege: 'company' },
      // { key: 'quotalist', title: t('member_quota_table'), companyPath: '/company/quota/list', icon: <AppstoreAddOutlined />, privilege: 'company' },
      { key: 'postList', title: t('company_postList'), companyPath: '/company/postList', icon: <CrownOutlined />, privilege: 'company' },      
      { key: 'setting', title: t('setting'), companyPath: '/company/setting', icon: <SettingOutlined />, privilege: 'company' },

    ]

    return (
      <Sider
        breakpoint="sm"
        collapsedWidth="0"
        width={250}
        style={{
          height: '100%', opacity: 1, position: 'relative', backgroundColor: 'white'
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[defaultSelectedKeys]}
          defaultOpenKeys={['1']}
          style={{ paddingBottom: '50px', paddingTop: '20px' }}
        >
          {this.renderMenuItem()}
        </Menu>
        <Menu mode="inline" style={{ opacity: 1, position: 'relative' }}>
          {/* <Divider /> */}
          <Menu.Item key="99" onClick={() => { this.logout(); }}>
            <span>{t('logout')}</span>
          </Menu.Item>
        </Menu>

        {
          this.state.logout === true
            ? <Redirect to="/company/login" />
            : null
        }
      </Sider>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sidebar));
