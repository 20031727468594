import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, Space
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import CompanyUserQuotaLogListTable from './CompanyUserQuotaLogListTable';
import FormUploadFile from '../../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const debug = require('debug')('app:admin:client:src:AdvertisementList');


//const involvedModelName = "company";
// const title = "公司會員列表";
//const selectedKey = 3;
//const tableIDName = "company_user_id";
const { TabPane } = Tabs;

function UploadSection(props) {

  const {
    onSuccess,
  } = props;


  const onChange = (info) => {
    debug(info);
    // return;

    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
        // webcast_event_id
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

const CompanyUserQuotaLogList = (props) => {

  const app = useSelector((state) => state.app);
  const { t } = useTranslation()
  const title = t('quotalistLog');
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [defaultActiveKey, setDefaultActiveKey] = useState('3');
  const history = useHistory();
  let location = useLocation();
  const [logList, setLogList] = useState([]);
  const { key } = queryString.parse(location.search);
  const [getTapkey, setgetTapkey] = useState(1);
  let defaultActiveKey = key




  useEffect(() => {
    getAllData();

  }, []);

  const getAllData = async () => {
    setLoading(true)

    let userDataLog = []
    let orderedUserDataLog = []
    try {
      let url = "/api/user_quota_log/listByCompany";
      let logArr = await Service.call('get', url);
      userDataLog = logArr

      // get first /last name by using user_id ,and map data
      let UserArr = await Service.call('get', "/api/user/list/1")
      let UserArrMap = _.keyBy(UserArr, "user_id")
  


      _.each(logArr, (user, i) => {
        if (!_.isUndefined(UserArrMap[user.user_id].company_name_en)) {
          userDataLog[i].username = UserArrMap[user.user_id].company_name_en;
        } else {
          userDataLog[i].username = UserArrMap[user.user_id].first_name + ' ' + UserArrMap[user.user_id].last_name;
        }
        userDataLog[i].type = UserArrMap[user.user_id].type
        userDataLog[i].user_key = UserArrMap[user.user_id].user_key
        
        // userDataLog[i].first_name = UserArrMap[user.user_id] ? UserArrMap[user.user_id].first_name : ""
        // userDataLog[i].last_name = UserArrMap[user.user_id] ? UserArrMap[user.user_id].last_name : ""
      })


      // item_category name by using item_category_id ,and map data
      let CategoryArr = await Service.call('get', "/api/item/category/list")
      let CategoryArrMap = _.keyBy(CategoryArr, "item_category_id")
      _.each(logArr, (bookingItem, i) => {
        userDataLog[i].category_name = CategoryArrMap[bookingItem.item_category_id] ? CategoryArrMap[bookingItem.item_category_id].category_name : ""
      })

      let companyUserArr = await Service.call('get', "/api/company/user")
      let companyUserArrMap = _.keyBy(companyUserArr, "company_user_id")
      _.each(logArr, (companyUser, i) => {
        userDataLog[i].companyUserName = companyUserArrMap[companyUser.company_user_id] ? companyUserArrMap[companyUser.company_user_id].first_name + " " + companyUserArrMap[companyUser.company_user_id].last_name : ""
      })
      orderedUserDataLog = _.orderBy(userDataLog, ["ctime"], ["desc"])
      console.log("orderedUserDataLog", orderedUserDataLog)
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setLogList(orderedUserDataLog);
      setLoading(false)
    }
  }
  const onClickExportExcel = async () => {
    // let url = "/api/export/access_log/excel";
    // let data = await Service.call('get', url);
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    window.location = `${app.config.API_SERVER_URL}/api/export/quota_log/excel`;
  }


  return (
    <NavAndSideFrame title={title} selectedKey={"user_quota_log"}>
      <Button
        type="primary"
        onClick={() => onClickExportExcel()}
      >
        導出紀錄 (Excel)
      </Button>
      <Divider />
      <CompanyUserQuotaLogListTable
        logList={logList}
        loading={loading}
      />
    </NavAndSideFrame>
  )
}

export default CompanyUserQuotaLogList;