import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, Space
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
// import MemberListTable from './MemberListTable';

import CompanyUserBookingLogListTable from './CompanyUserBookingLogListTable';
import FormUploadFile from '../../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
// import UserQuotaModal from './UserQuotaModal';
// import MemberListTableConfirm from './MemberListTableConfirm';
const { TabPane } = Tabs;

const debug = require('debug')('app:admin:client:src:AdvertisementList');


const involvedModelName = "company";

const selectedKey = 3;
const tableIDName = "company_user_id";

function UploadSection(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    debug(info);
    // return;
    // console.log('info>>>', info)
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
        // webcast_event_id
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

const CompanyUserBookingLogList = (props) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const [logList, setLogList] = useState([]);
  const [shareLogList, setShareLogList] = useState([]);
  const [kungfuLogList, setKungfuLogList] = useState([]);
  const [getTabKey, setTabKey] = useState(1);

  const title = t('bookingLog');


  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)

    let userDataLog = []
    let orderedUserDataLog = []
    let kungfuDataLog = []
    let sharingDataLog = []
    try {
      let url = "/api/booking_item/log";
      let logArr = await Service.call('get', url);
      userDataLog = logArr
      // console.log("logArr",logArr)
      // get first /last name by using user_id or user_guest_id ,and map data
      let UserArr = await Service.call('get', "/api/user/list/1")
      let UserArrMap = _.keyBy(UserArr, "user_id")
      // console.log("UserArrMap", UserArrMap)


      // let guestUserArr = await Service.call('get', "/api/user/guest/list");
      // let guestUserArrMap = _.keyBy(guestUserArr, "user_guest_id");

      let companyUserArr = await Service.call('get', "/api/company/user")
      let companyUserArrMap = _.keyBy(companyUserArr, "company_user_id")


      _.each(logArr, (user, i) => {
        // console.log("userDataLog",user.user_id)
        if (user.user_id !== 0) {
          userDataLog[i].name = UserArrMap[user.user_id].first_name + " " + UserArrMap[user.user_id].last_name
          userDataLog[i].company_name_en = UserArrMap[user.user_id].company_name_en

          userDataLog[i].phone = UserArrMap[user.user_id].mobile
          if (user.type === 3) {
            userDataLog[i].phone = UserArrMap[user.user_id].company_phone
          } else {
            userDataLog[i].phone = UserArrMap[user.user_id].mobile
          }



          userDataLog[i].user_type = UserArrMap[user.user_id].type
          userDataLog[i].user_key = UserArrMap[user.user_id].user_key
        } else if (user.user_guest_id !== 0) {
          // userDataLog[i].first_name = guestUserArrMap[user.user_guest_id].first_name
          // userDataLog[i].last_name = guestUserArrMap[user.user_guest_id].last_name
          // userDataLog[i].user_type = 'guest'
        }
        userDataLog[i].companyUserName = companyUserArrMap[user.company_user_id] ? companyUserArrMap[user.company_user_id].first_name + " " + companyUserArrMap[user.company_user_id].last_name : ""
        userDataLog[i].id = user.user_id || user.user_guest_id || user.company_user_id || 0;
      })
      // console.log("userDataLog",userDataLog)


      // // item name by using item_category_id ,and map data
      let itemArr = await Service.call('get', "/api/item/list")
      let itemArrMap = _.keyBy(itemArr, "item_id")
      _.each(logArr, (Item, i) => {


        userDataLog[i].item_category_id = itemArrMap[Item.item_id] ? itemArrMap[Item.item_id].item_category_id : ""

        // userDataLog[i].item_type = itemArrMap[Item.item_id].item_category_id 

        userDataLog[i].item_name = itemArrMap[Item.item_id] ? itemArrMap[Item.item_id].item_name : ""
        userDataLog[i].start_day = itemArrMap[Item.item_id] ? itemArrMap[Item.item_id].start_day : ""
        // console.log("asss",itemArrMap[Item.item_id])
      })

      orderedUserDataLog = _.orderBy(userDataLog, ['ctime'], ['desc'])
      for (var i = 0; i < orderedUserDataLog.length; i++) {
        orderedUserDataLog[i].start_time = moment.unix(orderedUserDataLog[i].start_time).format('YYYY-MM-DD')
        if (orderedUserDataLog[i].item_category_id === 1) {

          kungfuDataLog.push(orderedUserDataLog[i])
        } else {
          sharingDataLog.push(orderedUserDataLog[i])
        }
      }
      setKungfuLogList(kungfuDataLog)
      setShareLogList(sharingDataLog)
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      // console.log("orderedUserDataLog",orderedUserDataLog)
      setLogList(orderedUserDataLog);
      setLoading(false)
    }
  }
  function getKey(key) {
    setTabKey(key)
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>


      <Tabs type="card" defaultActiveKey="user_form" onChange={(key) => getKey(key)}>
        <TabPane tab={t('gonfu')} key="1">
          <CompanyUserBookingLogListTable
            //kungfuLogList
            logList={shareLogList}
            loading={loading}
            dataKey={getTabKey}
          />
        </TabPane>
        <TabPane tab={t('sharingTable')} key="2">
          <CompanyUserBookingLogListTable
            logList={kungfuLogList}

            loading={loading}
            dataKey={getTabKey}
          />
        </TabPane>

      </Tabs>

    </NavAndSideFrame>
  )
}

export default CompanyUserBookingLogList;