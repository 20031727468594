import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Checkbox
} from 'antd';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;


const CompanyResetForm = (props) => {
  // const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation()


  let { initialValues } = props

  const [UserForm] = Form.useForm();

  const { id } = queryString.parse(location.search);
  const history = useHistory();
  const [initialValuesForm, setInitialValues] = useState({
    gender: "m",
    contactPersonMobilePrefix: "+852",
    contactEmergencyMobilePrefix: "+852"
  })

  useEffect(() => {
    if (id > 0) {
      // initialValues append to initialValuesForm
      let newObject = { ...initialValuesForm, ...initialValues }
      setInitialValues(newObject)
    }

  }, [initialValues])


  // if (id > 0) {
  //   let newObject = { ...initialValuesForm, ...initialValues }
  //   setInitialValues(newObject)
  //   console.log(newObject)
  // }

  useEffect(() => {
    UserForm.resetFields()
  }, [initialValuesForm])

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }

  const onFinish = async (formData) => {
    formData.user_id = id;
    // console.log(formData)

    if (formData.password != formData.confirm_password) {
      notification.error({
        message: t("notMatchPassword"),
      });
      return;
    }

    if (id > 0) {
      let response = await Service.call('patch', `/api/user/password`, formData);
      // console.log("response>>", response)
      if (response.status === -1) {
        notification.error({
          message: t("error"),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
      history.push("/company/company/list")
    }
  }

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      initialValues={initialValuesForm}
      onFinish={onFinish}
    >
      <Form.Item
        label={t("editPassword")}
        name="password"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={t("confirmPassword")}
        name="confirm_password"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyResetForm;
