import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Spin, Tooltip, message, Tabs, notification, Input, Space
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import Highlighter from 'react-highlight-words';

import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import UserQuotaModal from './UserQuotaModal';
const debug = require('debug')('app:admin:client:src:AdvertisementList');

const MemberListTable = (props) => {
  const app = useSelector((state) => state.app);
  const tableIDName = "user_id";
  const { t } = useTranslation()
  const { callBack, handleFormSubmit } = props
  const [type, setType] = useState('new');
  const [dataList, setDataList] = useState(props.dataObj);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  // const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput
  const { TabPane } = Tabs;

  const { defaultActiveKey, modalVisible, setModalVisible } = props
  //defaultActiveKey

  debug(`modalVisible cp2>>>`, modalVisible)
  debug(`setModalVisible cp1>>>`, setModalVisible)
  useEffect(() => {
    debug(`modalVisible>>>`, setModalVisible)
  }, [modalVisible]);


  useEffect(() => {
    setDataList(props.dataObj)

  }, [props.dataObj]);

  const SucessfulNotificationWithIcon = type => {
    notification[type]({
      message: 'Notification Title',
      description:
        'added',
    });
  };


  const putAccessLog = async (user_id) => {
    let postObj = {
      user_id
    }
    let response = await Service.call('put', '/api/user/accesslog/ams?type=individual', postObj)
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
  }

  const putEmail = async (user_id) => {
    console.log("test>>")


    let postObj = {
      user_id
    }
    let response = await Service.call('post', '/api/user/email/confirm_member?type=individual', postObj)


    //call back function getAllData

    callBack()
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
    // callBack()
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // console.log("selectedKeys >>", selectedKeys[0])
    // console.log("dataIndex >>", dataIndex)
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000000' }} />,
    onFilter: (value, record) => {
      // console.log("value >>> ", value)
      // console.log("record >>> ", record)
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const setTableHeader = () => {

    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('edit_quota')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={<EditOutlined />}
                  disabled={record.expired === 1 ? true : false}
                  onClick={() => {
                    // console.log("test",record)

                    setType('quota');
                    setModalVisible(true);
                    setSelectedRecord(record)


                    // setEditOption(true)
                    // EditOption = true
                  }}
                // disabled={!isAdmin}
                />
              </Tooltip>
              <Tooltip title={t('edit_expiry_date')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={<CalendarOutlined />}
                  disabled={record.expired === 1 ? true : false}
                  onClick={() => {
                    // console.log("test",record)

                    setType('date');
                    setModalVisible(true);
                    setSelectedRecord(record)


                    // setEditOption(true)
                    // EditOption = true
                  }}
                // disabled={!isAdmin}
                />
              </Tooltip>

            </span>
          )
        }
      },
      {
        title: t('user_id'),
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key - b.user_key,
        ...getColumnSearchProps('user_key')
      },
      {
        title: t('會員類型'),
        dataIndex: 'type',
        render: (value) => displayType(value),
        sorter: (a, b) => a.type - b.type
      },
      {
        title: t('username'),
        dataIndex: 'username',
        sorter: (a, b) => a.username.localeCompare(b.username),
        ...getColumnSearchProps('username')
      },
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.category_name.localeCompare(b.category_name),
      },
      {
        title: t('quota_amount'),
        dataIndex: 'quota_amount',
        sorter: (a, b) => a.quota_amount - b.quota_amount,
      },

      {
        title: '狀態',
        dataIndex: 'expired',
        render: (value) => displayIsExpired(value),
        sorter: (a, b) => a.expired - b.expired
      },
      {
        title: t('expiry_date'),
        dataIndex: 'expiry_date',
        sorter: (a, b) => a.expiry_date - b.expiry_date,
        render: (value) => moment.unix(value).format('YYYY-MM-DD')
      },



      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => moment.unix(value).format('YYYY-MM-DD HH:mm')
      },
    ];
    return columns;
  }


  const displayIsExpired = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已到期 ";
        tagColor = 'red';
        break;
      case 0:
        displayStr = "未到期";
        tagColor = 'green';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = ""
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已確認";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未確認";
        tagColor = 'red';
        break;
      case -1:
        displayStr = "已過期";
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayEmailStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已發送";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未發送";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const onClickExportExcel = async () => {
    // let url = "/api/export/access_log/excel";
    // let data = await Service.call('get', url);
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    window.location = `${app.config.API_SERVER_URL}/api/export/quota_list_log/excel`;
  }
  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setType('new')
          setModalVisible(true)
          setSelectedRecord({
            user_quota_id: 0,
            user_id: null
          })
          // setEditOption(true)
        }}
      // disabled={!isAdmin}
      >
        {t('add_user_quota')}
      </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Button
        type="primary"
        onClick={() => onClickExportExcel()}
      >
        導出紀錄 (Excel)
      </Button>

      <UserQuotaModal
        type={type}
        selectedRecord={selectedRecord}
        categoryList={dataList}
        modalVisible={modalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        // particularUserView={particularUserView}

        setModalVisible={
          (value) => {
            setModalVisible(value)
            callBack()
            // getAllData()
          }
        }
      // handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
      <Tabs defaultActiveKey={defaultActiveKey}
        onChange={(value) => {
          // getTapValue(value)
        }}>
        <TabPane >

          <Table
            rowKey={tableIDName}
            scroll={{ x: 'max-content' }}
            dataSource={dataList}
            columns={setTableHeader()}
            bordered
            size="small"
            pagination={{ pageSize: 100 }}
          />

        </TabPane>
      </Tabs>


    </div>

  )
}

export default MemberListTable;