import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, message, notification, Input, Space
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const MemberListTable = (props) => {
  const tableIDName = "user_id";
  const { t } = useTranslation()
  const { callBack } = props
  const [dataList, setDataList] = useState(props.dataObj);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput
  const { defaultActiveKey } = props
  //defaultActiveKey

  
  useEffect(() => {
    setDataList(props.dataObj)
    
  }, [props.dataObj]);

  const SucessfulNotificationWithIcon = type => {
    notification[type]({
      message: 'Notification Title',
      description:
        'added',
    });
  };
 

  const putAccessLog = async (user_id) => {
    let postObj = {
      user_id
    }
    let response = await Service.call('put', '/api/user/accesslog/ams?type=individual', postObj)
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
  }

  const putEmail = async (user_id) => {
   


    let postObj = {
      user_id
    }
    let response = await Service.call('post', '/api/user/email/confirm_member?type=individual', postObj)
   

    //call back function getAllData

    callBack()
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
    // callBack()
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000000' }} />,
    onFilter: (value, record) => {

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const setTableHeader = () => {

    const columns = [
      {
        title: '操作',
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title="修改">
                <Link
                  to={{
                    pathname: "/company/member/details/",
                    search: `?id=${value}&key=${defaultActiveKey}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 8 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>

              {record.status === 1 && record.is_active === 1 &&(
                <Tooltip title="簽到">
                  <Popconfirm
                    title="確認簽到?"
                    onConfirm={() => putAccessLog(value)}
                    okText="是"
                    cancelText="否"
                  >
                    <Button
                      shape="circle"
                      style={{ marginRight: 8 }}
                      icon={<EnvironmentOutlined />}
                      href="#"

                    />
                  </Popconfirm>
                </Tooltip>
              )}
              {record.status === 1 && record.is_active === 1 &&(
                <Tooltip title="發送電郵">
                  {/* <Popconfirm
                  title="確認登記?"
                  onConfirm={() => putAccessLog(value)}
                  okText="是"
                  cancelText="否"
                > */}
                  <Button
                    shape="circle"
                    style={{ marginRight: 8 }}
                    icon={<MailOutlined />}
                    onClick={() => putEmail(value)}

                  />
                  {/* </Popconfirm> */}
                </Tooltip>
              )}

            </span>
          )
        }
      },

      {
        title: '會員編號',
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key.localeCompare(b.user_key),
        // sorter: (a, b) => a.user_key - b.user_key,
        ...getColumnSearchProps('user_key'),

      },
      {
        title: '會員種類',
        dataIndex: 'type',
        render: (value) => displayType(value),
        sorter: (a, b) => a.type - b.type
      },
     
      {
        title: '名字 (英文)',
        dataIndex: 'first_name',
        sorter: (a, b) => a.first_name.localeCompare(b.first_name)
      },
      {
        title: '姓氏 (英文)',
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name)
      },
      {
        title: '姓氏 (中文)',
        dataIndex: 'last_name_zh',
        sorter: (a, b) => a.last_name_zh.localeCompare(b.last_name_zh)
      },
      {
        title: '名字 (中文)',
        dataIndex: 'first_name_zh',
        sorter: (a, b) => a.first_name_zh.localeCompare(b.first_name_zh)
      },
      // {
      //   title: '類別',
      //   dataIndex: 'type',
      //   sorter: (a, b) => a.gender.localeCompare(b.type),
      //   render: (value) => displayType(value)
      // },
      {
        title: '手機號碼',
        dataIndex: 'mobile',
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
        ...getColumnSearchProps('mobile'),
      },
      {
        title: '電子郵件',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        ...getColumnSearchProps('email'),
      },
      {
        title: '使用狀態',
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: '確認狀態',
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: '發送電郵狀態',
        dataIndex: 'is_confirm_send_email',
        render: (value) => displayEmailStatus(value),
        sorter: (a, b) => a.is_confirm_send_email - b.is_confirm_send_email
      },
      {
        title: '申請時間',
        dataIndex: 'create_time',
        render: (date) => {
          return moment.unix(date).format('YYYY-MM-DD HH:mm')
        },
        // render: (value) => displayEmailStatus(value),
       sorter: (a, b) => a.ctime - b.ctime
      },
      {
        title: '最後更新時間',
        dataIndex: 'utime',
        render: (date) => {
          return moment.unix(date).format('YYYY-MM-DD HH:mm')
        },
        // render: (value) => displayEmailStatus(value),
       sorter: (a, b) => a.utime - b.utime
      },
    ];
    return columns;
  }



  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "使用中 Active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "停用 Inactive";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = ""
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已確認";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未確認";
        tagColor = 'red';
        break;
      case -1:
        displayStr = "已過期";
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayEmailStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已發送";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未發送";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
      />
    </Layout>

  )
}

export default MemberListTable;