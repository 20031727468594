import React, { useState, useEffect } from 'react';
import {
  Button, Divider, notification, Tabs, Spin
} from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import moment from 'moment';
import {
  LoadingOutlined
} from '@ant-design/icons';

import * as Service from '../../core/Service';

import NavAndSideFrame from '../../components/NavAndSideFrame';
// import OrderInfoTable from './OrderInfoTable';
import CompanyForm from './CompanyForm';
import CompanyStatusForm from './CompanyStatusForm';
import CompanyResetPasswordForm from './CompanyResetPasswordForm';
import CompanyQuotaTable from './CompanyQuotaTable';
import CompanyQuotaRecordTable from './CompanyQuotaRecordTable';
import CompanyAccessLogTable from './CompanyAccessLogTable';


const { TabPane } = Tabs;

const MemberDetails = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { id } = queryString.parse(location.search);
  const { t } = useTranslation();

  const [orderDataList, setOrderDataList] = useState([]);
  const [userObj, setUserObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataLogList, setDataLogList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [logList, setLogList] = useState([]);


  useEffect(() => {
    checkNewRecordAndFetchData()
    getAllData()
    getAllBookingRecordData()
    getAccessLogAllData()
    // getAllQuotaRecordData()
  }, []);

  let isNewRecord = false
  if (id === 0) {
    isNewRecord = true
  }

  const checkNewRecordAndFetchData = () => {
    if (!isNewRecord) return fetchUser()
  }

  // this is for user quota table
  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    let dataList2 = []; // for other status
    let inactive_dataList = [];
    try {
      let url = `/api/user_quota/list/Bykey/${id}`;
      console.log("testurl", url)
      let response = await Service.call('get', url);
      dataList = response;


      url = `/api/user/list/1`;
      let userArr = await Service.call('get', url);
      let userArrMap = _.keyBy(userArr, "user_id");

      url = '/api/item/category/list';
      let categoryArr = await Service.call('get', url);
      let categoryArrMap = _.keyBy(categoryArr, "item_category_id");


      _.each(response, (quotaItem, i) => {
        if (!_.isUndefined(userArrMap[quotaItem.user_id].company_name_en)) {
          dataList[i].username = userArrMap[quotaItem.user_id].company_name_en;
        } else {
          dataList[i].username = userArrMap[quotaItem.user_id].first_name + ' ' + userArrMap[quotaItem.user_id].last_name;
        }
        dataList[i].type = userArrMap[quotaItem.user_id].type
        // dataList[i].companyUsername = companyUserArrMap[quotaItem.company_user_id].first_name + ' ' + companyUserArrMap[quotaItem.company_user_id].last_name;
        dataList[i].item_category_id = categoryArrMap[quotaItem.item_category_id].item_category_id
        dataList[i].category_name = categoryArrMap[quotaItem.item_category_id].category_name;
      })
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(dataList);

      setLoading(false)
    }
  }

  const getAccessLogAllData = async () => {
    setLoading(true)
    let dataList = [];
    try {
      let url = `/api/user/accesslog/${id}`;
      let data = await Service.call('get', url);
      data = _.orderBy(data, ["access_time"], ["desc"])
      dataList = data;
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataLogList(dataList);
      setLoading(false)
    }
  }

  // this is for booking record

  const getAllBookingRecordData = async () => {
    setLoading(true)

    let userDataLog = []
    let orderedUserDataLog = []
    try {
      let url = `/api/booking_item/log/byUserKey/${id}`;
      let logArr = await Service.call('get', url);
      userDataLog = logArr
      // console.log("logArr",logArr)
      // get first /last name by using user_id or user_guest_id ,and map data
      let UserArr = await Service.call('get', "/api/user/list/1")
      let UserArrMap = _.keyBy(UserArr, "user_id")
      console.log("UserArrMap", UserArrMap)


      // let guestUserArr = await Service.call('get', "/api/user/guest/list");
      // let guestUserArrMap = _.keyBy(guestUserArr, "user_guest_id");

      let companyUserArr = await Service.call('get', "/api/company/user")
      let companyUserArrMap = _.keyBy(companyUserArr, "company_user_id")


      _.each(logArr, (user, i) => {
        // console.log("userDataLog",user.user_id)
        if (user.user_id !== 0) {
          userDataLog[i].name = UserArrMap[user.user_id].first_name + " " + UserArrMap[user.user_id].last_name
          userDataLog[i].company_name_en = UserArrMap[user.user_id].company_name_en
          userDataLog[i].user_type = UserArrMap[user.user_id].type
          userDataLog[i].user_key = UserArrMap[user.user_id].user_key
        } else if (user.user_guest_id !== 0) {
          // userDataLog[i].first_name = guestUserArrMap[user.user_guest_id].first_name
          // userDataLog[i].last_name = guestUserArrMap[user.user_guest_id].last_name
          // userDataLog[i].user_type = 'guest'
        }
        userDataLog[i].companyUserName = companyUserArrMap[user.company_user_id] ? companyUserArrMap[user.company_user_id].first_name + " " + companyUserArrMap[user.company_user_id].last_name : ""
        userDataLog[i].id = user.user_id || user.user_guest_id || user.company_user_id || 0;
      })


      // // item name by using item_category_id ,and map data
      let itemArr = await Service.call('get', "/api/item/list")
      let itemArrMap = _.keyBy(itemArr, "item_id")
      _.each(logArr, (Item, i) => {
        userDataLog[i].item_name = itemArrMap[Item.item_id] ? itemArrMap[Item.item_id].item_name : ""
      })

      orderedUserDataLog = _.orderBy(userDataLog, ['ctime'], ['desc'])
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setLogList(orderedUserDataLog);
      // console.log("orderedUserDataLog",orderedUserDataLog)
      setLoading(false)
    }
  }

  const getAllQuotaRecordData = async () => {
    setLoading(true)

    let userDataLog = []
    let orderedUserDataLog = []
    try {
      // getUserQuotaBykey

      let url = `/api/user_quota_log/listByCompany/${id}`;
      let logArr = await Service.call('get', url);
      userDataLog = logArr

      // get first /last name by using user_id ,and map data
      // let UserArr = await Service.call('get', `/api/user/company/${id}`);

      let UserArr = await Service.call('get', "/api/user/list/1")
      // let UserArr = await Service.call('get', `/api/user/company/${id}`);


      let UserArrMap = _.keyBy(UserArr, "user_id")


      _.each(logArr, (user, i) => {
        if (!_.isUndefined(UserArrMap[user.user_id].company_name_en)) {
          userDataLog[i].username = UserArrMap[user.user_id].company_name_en;
        } else {
          userDataLog[i].username = UserArrMap[user.user_id].first_name + ' ' + UserArrMap[user.user_id].last_name;
        }
        userDataLog[i].type = UserArrMap[user.user_id].type
        // userDataLog[i].first_name = UserArrMap[user.user_id] ? UserArrMap[user.user_id].first_name : ""
        // userDataLog[i].last_name = UserArrMap[user.user_id] ? UserArrMap[user.user_id].last_name : ""
      })


      // item_category name by using item_category_id ,and map data
      let CategoryArr = await Service.call('get', "/api/item/category/list")
      let CategoryArrMap = _.keyBy(CategoryArr, "item_category_id")
      _.each(logArr, (bookingItem, i) => {
        userDataLog[i].category_name = CategoryArrMap[bookingItem.item_category_id] ? CategoryArrMap[bookingItem.item_category_id].category_name : ""
      })

      let companyUserArr = await Service.call('get', "/api/company/user")
      let companyUserArrMap = _.keyBy(companyUserArr, "company_user_id")
      _.each(logArr, (companyUser, i) => {
        userDataLog[i].companyUserName = companyUserArrMap[companyUser.company_user_id] ? companyUserArrMap[companyUser.company_user_id].first_name + " " + companyUserArrMap[companyUser.company_user_id].last_name : ""
      })
      orderedUserDataLog = _.orderBy(userDataLog, ["ctime"], ["desc"])
      console.log("orderedUserDataLog", orderedUserDataLog)
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setLogList(orderedUserDataLog);
      setLoading(false)
    }
  }


  const fetchUser = async () => {
    setLoading(true)
    let url_string = window.location;
    let url = new URL(url_string);
    let id = url.searchParams.get("id");

    try {
      let result = await Service.call('get', `/api/user/company/${id}`);

      let [company_phone_prefix, company_phone] = _.split(result.company_phone, '-');
      let [contact_phone_prefix, contact_phone] = _.split(result.contact_phone, '-');

      result.company_phone_prefix = company_phone_prefix;
      result.company_phone = company_phone;

      result.contact_phone_prefix = contact_phone_prefix;
      result.contact_phone = contact_phone;

      if (id > 0) {
        result.date_of_establish = moment.unix(result.date_of_establish)
      }


      if (!_.isUndefined(result.receive_market_info_option_source)) {
        result.receive_market_info_option_source = result.receive_market_info_option_source.split(",")
      }

      if (!_.isUndefined(result.receive_news_info_option_source)) {
        result.receive_news_info_option_source = result.receive_news_info_option_source.split(",")
      }
      if (!_.isUndefined(result.source_info)) {
        result.source_info = result.source_info.split(",")
        for (let i = 0; i < result.source_info.length; i++) {
          if (result.source_info[i] == 6) {
            result.isNotNull_source_other = 6;
          }
        }
      }

      // result.receive_market_info_option_source = result.receive_market_info_option_source.split(",")
      // result.receive_news_info_option_source = result.receive_news_info_option_source.split(",")
      // result.source_info = result.source_info.split(",")

      // for (var i = 0; i < result.source_info.length; i++) {
      //   if (result.source_info[i] == 6) {
      //     result.isNotNull_source_other = 6;
      //   }
      // }


      setUserObj(result);
      setLoading(false)
    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    }
  }

  const handleFormSubmit = async (submitRecord) => {
    let { expiry_date } = submitRecord;
    if (expiry_date) submitRecord.expiry_date = moment(expiry_date).unix();
    let submit = await Service.call('patch', '/api/user_quota', submitRecord)
    getAllData()
    setModalVisible(false)

    notification.success({
      message: t("success"),
    });
  }


  return (
    <NavAndSideFrame {...props} title={(id > 0) ? `${t('edit_member')}` : `${t('add_new_member')}`} selectedKey="user_account">
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <Tabs type="card" defaultActiveKey="user_form" onChange={(key) => null}>
          <TabPane tab={t('user')} key="user_form">
            <CompanyForm
              initialValues={userObj}
            />

          </TabPane>
          {id > 0 && (
          <TabPane tab={t('confirmation_status')} key="userStatus">
            <CompanyStatusForm
              initialValues={userObj}
            />
          </TabPane>
          )}

          {id > 0 && (
          <TabPane tab={t('member_quota_table')} key="CompanyQuotaTable">
            <CompanyQuotaTable
              dataObj={dataList}
              adminChangeData={getAllData}
              defaultActiveKey={1}
              handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              callBack={() => getAllData()}
              initialValues={userObj}
            />
          </TabPane>
          )}

          {id > 0 && (
          <TabPane tab={t('member_quotaRecord_table')} key="member_quotaRecord_table">
            <CompanyQuotaRecordTable
              dataObj={logList}
              adminChangeData={getAllData}
              defaultActiveKey={1}
              handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
              // modalVisible={modalVisible}
              // setModalVisible={setModalVisible}
              callBack={() => getAllData()}
              initialValues={userObj}
            />
          </TabPane>
          )}

          {id > 0 && (
          <TabPane tab={t('memberAccessLogTable')} key="memberAccessLogTable">
            <CompanyAccessLogTable
              dataObj={dataLogList}
              adminChangeData={getAllData}
              defaultActiveKey={1}
              handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
              // modalVisible={modalVisible}
              // setModalVisible={setModalVisible}
              callBack={() => getAllData()}
              initialValues={userObj}
            />
          </TabPane>
          )}


          {id > 0 && (
          <TabPane tab={t('editPassword')} key="userPassword">
            <CompanyResetPasswordForm
              initialValues={userObj}
            />
          </TabPane>
          )}

        </Tabs>
      </Spin>
    </NavAndSideFrame>
  )
}

export default MemberDetails;
