import React, { Component, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, Divider, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';
import 'react-quill/dist/quill.snow.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const StaticPageEditForm = (props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [contentValue, setContentValue] = useState('');

  const [form] = Form.useForm();
  let query = useQuery()
  let site_meta_id = query.get("site_meta_id")

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //   form.resetFields()
  // }, [dataSource])

  const fetchData = async () => {
    const result = await Service.call('get', `/api/admin/meta/id?site_meta_id=${site_meta_id}`)
    let [siteMeta] = result.meta
    let {
      pathname,
      meta_value,
      meta_key,
    } = siteMeta
    setDataSource(siteMeta);
    form.setFieldsValue({ pathname, meta_key })
    setContentValue(_.toString(meta_value))
  }

  const handleFormSubmit = (formData) => {
    let postData = formData
    postData.site_meta_id = site_meta_id
    postData.meta_value = contentValue
    let response = Service.call('patch', `/api/admin/meta`, postData);

    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return;
    }
    notification.success({
      message: t("success"),
    });
    history.push("/company/setting")
  }

  return (
    <Form
      style={{ width: '100%' }}
      layout="vertical"
      form={form}
      onFinish={handleFormSubmit}
      initialValues={dataSource}
    >
      <Form.Item
        label="相關"
        name="meta_key"
      >
        <p>{dataSource.meta_key}</p>
      </Form.Item>
      <Form.Item
        label="路徑"
        name="pathname"
      >
        <p>{dataSource.pathname}</p>
      </Form.Item>
      <ReactQuill
        theme="snow"
        value={contentValue}
        onChange={setContentValue}
        modules={{
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { align: 'center' },
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
                { color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }
              ],
              ['link'],
              ['clean'],
            ],
          },
        }}
      />
      <Divider />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default StaticPageEditForm
