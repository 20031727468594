import React, { Component, useState, useEffect } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  Avatar,
  Divider,
  Form,
  Icon,
  Layout,
  Menu,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
  Row,
  Col,
  Select,
  notification,
  Calendar,
  Badge,
  Spin,
  Button,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";
// import 'antd/dist/antd.css';
import moment from "moment";
import _ from "lodash";
import * as Service from "../../../core/Service";
import * as Main from "../../../core/Main";
import NavAndSideFrame from "../../../components/NavAndSideFrame";
// import CompanyUserQuotaLogListTable from './CompanyUserQuotaLogListTable';
import * as debugLib from "debug";
import FormUploadFile from "../../../components/form/FormUploadFile";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import UserbookingListCalender from "./UserbookingListCalender";
import floor_plan from "../../../assets/JHUB_Floorplan_Workshop_Blank Tables.jpg";
import BookingInfoModal from "../../../components/BookingInfoModal";

const debug = debugLib("app:pages/UserbookingList");

const involvedModelName = "company";
const selectedKey = "booking_list";
const tableIDName = "item_category_id";

// let sampleText = "apple";

const UserbookingListCalendarView = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { Option } = Select;
  const [categoryList, setCategoryList] = useState([]);
  const [ItemList, setItemList] = useState([]);
  const [BookingItem, setBookingItem] = useState([]);
  const [BookingStatusItem, setBookingStatusItem] = useState("0");
  const [SelectedItemList, setSelectedItemList] = useState();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [itemCategoryFilter, setItemCategoryFilter] = useState(1);
  const [item, setItem] = useState([]);
  const [itemFilter, setItemFilter] = useState([]);
  const [viewStartDate, setViewStartDate] = useState(0);
  const [viewEndDate, setViewEndDate] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);
  const [eventObj, setEventObj] = useState([]);

  // const [nextWeek, setnextWeek] = useState(0);
  // const [previousWeek, setpreviousWeek] = useState(0);

  // const [bookingItemStatus, setBookingItemStatus] = useState(0)
  const [bookingItemStatusFilter, setBookingItemStatusFilter] = useState(0);

  // For if user choose "All" catergory (First Select button)
  // if isFiltered == 0 , the item select button (second select button) disabled , else , abled

  useEffect(() => {
    setLoading(true);
    getItemCategoryList();
    getItemList();
    getBookingItem(); // Booking infor
    getViewDate();
    setLoading(false);
  }, []);

  useEffect(() => {
    getItemList();
  }, [itemCategoryFilter]);

  useEffect(() => {
    getBookingItem();
    // getItemList();
  }, [
    itemCategoryFilter,
    itemFilter,
    bookingItemStatusFilter,
    viewStartDate,
    viewEndDate,
  ]);

  const getItemCategoryList = async () => {
    let itemCategoryArr = await Service.call("get", "/api/item/category/list");
    setItemCategory(itemCategoryArr);
  };

  const getItemList = async () => {
    let itemArr = await Service.call("get", "/api/item/list");
    // Filter Items
    if (itemCategoryFilter !== 0) {
      itemArr = _.filter(itemArr, ["item_category_id", itemCategoryFilter]);
    }
    setItem(itemArr);
  };

  const handleItemCategoryFilterChange = (value) => {
    setItemFilter([]);
    if (value === 0) {
      setItemCategoryFilter(0);
    }
    if (value > 0) {
      setItemCategoryFilter(value);
    }
    // console.log('categorychange', itemFilter)
  };

  const handleItemFilterChange = (value) => {
    // if (value.length > 0) {
    setItemFilter(value);
    // }
    // console.log('itemchange', itemFilter)
  };

  const handleStatusFilterChange = (value) => {
    setBookingItemStatusFilter(value);
  };

  let nextWeek = 0;
  let previousWeek = 0;
  let thisweek_startof = moment().startOf("month").unix();
  let thisweek_endof = moment().endOf("month").unix();
  // console.log("thisweek_startof",thisweek_startof)

  const getViewDate = () => {
    setViewStartDate(thisweek_startof);
    setViewEndDate(thisweek_endof);
  };

  const selectDate = (prevOrNext, focusWeek) => {
    nextWeek = moment(focusWeek).endOf("month").unix();
    previousWeek = moment(focusWeek).startOf("month").unix();

    setViewStartDate(previousWeek);
    setViewEndDate(nextWeek);

    getBookingItem();
  };

  const getBookingItem = async () => {
    setLoading(true);
    let calendarData = [];
    let orderedCalendarData = [];

    try {
      // Get & format calander data
      let itemArr = await Service.call("get", "/api/item/list");
      let itemArrMap = _.keyBy(itemArr, "item_id");
      // console.log("itemArr",itemArr)
      let userArr = await Service.call("get", "/api/user/list/1");
      let userArrMap = _.keyBy(userArr, "user_id");
      // let guestArr = await Service.call('get', "/api/user/guest/list");
      // let guestArrMap = _.keyBy(guestArr, "user_guest_id");

      // let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/1610586000/1610812799`)
      let bookingItemArr = await Service.call(
        "get",
        `/api/booking_item/list/bydate/${viewStartDate}/${viewEndDate}`
      );

      calendarData = _.filter(bookingItemArr, "status");
      // console.log("calendarData", `/api/booking_item/list/bydate/${viewStartDate}/${viewEndDate}`)
      // Mapping Data
      _.each(calendarData, (bookingItem, i) => {
        calendarData[i].item_name = itemArrMap[bookingItem.item_id].item_name;
        calendarData[i].title = itemArrMap[bookingItem.item_id].item_name;
        calendarData[i].item_feature_image =
          itemArrMap[bookingItem.item_id].item_feature_image;
        // if (_.indexOf(_.keys(userArrMap), bookingItem.user_id.toString()) !== -1) {
        //   calendarData[i].title += " - " + userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
        // } else if (_.indexOf(_.keys(guestArrMap), bookingItem.user_guest_id.toString()) !== -1) {
        //   calendarData[i].title += " - [GUEST] " + guestArrMap[bookingItem.user_guest_id].first_name + ' ' + guestArrMap[bookingItem.user_guest_id].last_name
        //   calendarData[i].first_name = guestArrMap[bookingItem.user_guest_id].first_name
        //   calendarData[i].last_name = guestArrMap[bookingItem.user_guest_id].last_name
        //   calendarData[i].nickname = guestArrMap[bookingItem.user_guest_id].nickname
        //   calendarData[i].email = guestArrMap[bookingItem.user_guest_id].email
        //   calendarData[i].mobile = guestArrMap[bookingItem.user_guest_id].mobile
        //   calendarData[i].remarks = guestArrMap[bookingItem.user_guest_id].remarks
        // }
        if (
          _.indexOf(_.keys(userArrMap), bookingItem.user_id.toString()) !== -1
        ) {
          if (
            userArrMap[bookingItem.user_id].type == 1 ||
            userArrMap[bookingItem.user_id].type == 2
          ) {
            calendarData[i].title +=
              " - " +
              userArrMap[bookingItem.user_id].first_name +
              " " +
              userArrMap[bookingItem.user_id].last_name;
          }
          if (userArrMap[bookingItem.user_id].type == 3) {
            calendarData[i].title +=
              " - " + userArrMap[bookingItem.user_id].company_name_en;
          }
        }
        calendarData[i].remarks = bookingItem.remarks;
        calendarData[i].item_category_id =
          itemArrMap[bookingItem.item_id].item_category_id;
        calendarData[i].start = moment.unix(bookingItem.start_time).toDate();
        calendarData[i].end = moment.unix(bookingItem.end_time).toDate();
      });

      // Filter Items Category
      if (itemCategoryFilter !== 0) {
        calendarData = _.filter(calendarData, [
          "item_category_id",
          itemCategoryFilter,
        ]);
      }

      // Filter Items
      if (itemFilter.length > 0) {
        let calendarDataFiltered = [];
        _.each(itemFilter, (itemFilterRc) => {
          itemFilterRc = _.toInteger(itemFilterRc);
          calendarDataFiltered.push(
            _.filter(calendarData, ["item_id", itemFilterRc])
          );
          calendarDataFiltered = _.flatten(calendarDataFiltered);
        });
        calendarData = calendarDataFiltered;
      }

      calendarData = _.filter(calendarData, (value) => {
        if (value.item_id < 13 || (value.status == 10 && value.item_id == 13)) {
          return value;
        }
      });
      // Filter Status
      if (bookingItemStatusFilter !== 0) {
        calendarData = _.filter(calendarData, [
          "status",
          bookingItemStatusFilter,
        ]);
      }

      orderedCalendarData = _.orderBy(calendarData, ["item_id"], ["asc"]);
    } catch (error) {
      console.error("error >>> ", error);
    } finally {
      setCalendarEvents(orderedCalendarData);
    }
    setLoading(false);
  };

  const AddSharingTableBooking = async () => {
    // setLoading(true)
    let event = {};
    // event.item_category_id = 13
    let categoryArr = await Service.call("get", "/api/item/list");
    let categoryArrMap = _.keyBy(categoryArr, "item_id");
    event.item_name = categoryArrMap[13].item_name;
    event.start_time = moment().unix();
    event.status = 1;
    event.item_category_id = 2;
    // it is for checking booking function
    event.addBooking = 1;
    // let userArr = await Service.call('get', `/api/user/${event.user_id}`);
    // event.type = userArr.type

    ///api/user/:user_id'
    let userArr = await Service.call("get", "/api/user/list/1");
    let userArrMap = [];
    _.forEach(userArr, (user) => {
      userArrMap.push(
        <Option key={user.user_id} value={user.user_id}>
          {user.first_name + " " + user.last_name}
        </Option>
      );
    });

    // setUserList(userArrMap)

    let getbookStatus = await Service.call("get", "/api/booking_item/status");
    //statusDataList = getbookStatus
    setEventObj(event);

    // console.log("testevent",event)

    //setbookStatus()
    setLoading(false);
    setModalVisible(true);
  };

  const handleFormSubmit = async (submitRecord) => {
    thisweek_startof = moment().startOf("day").unix();
    thisweek_endof = moment().endOf("day").unix();
    // let todayFrom = new Date(new Date().setHours(9, 0))
    // let todayTo = new Date(new Date().setHours(18,0 ))

    // // todayFrom = moment(todayFrom).unix()
    // // console.log("Date",todayFrom)

    // submitRecord.start_time = moment(todayFrom).unix()
    // submitRecord.end_time = moment(todayTo).unix()

    let response = await Service.call("put", "/api/booking_item", submitRecord);
    console.log("response>>>", response);
    if (response.status && response.errorCode === -80101) {
      notification.error({
        message: t("insufficient_quota"),
      });
      return;
    }
    if (response.status && response.status < 1) {
      notification.error({
        message: t("fail"),
      });
      return;
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t("success"),
      });
      getBookingItem();
      setModalVisible(false);
    }

    ///api/booking_item
    // // setLoading(true)
    // let response
    // let to = submitRecord.email
    // // console.log('submitRecord>>>', submitRecord.user_id)

    // let patchData = {
    //   status: submitRecord.status,
    //   user_id: submitRecord.user_id || 'Guest',
    //   booking_id: submitRecord.booking_id,
    //   remarks: submitRecord.remarks
    // }

    // // console.log('patchData>>>', patchData)
    // if (patchData && patchData.status === 1) {
    //   response = await Service.call('patch', '/api/booking_item/confirm', patchData)
    //   let userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

    //   // let emailResult = await Service.call('post', '/api/email/template', {
    //   //   type: "booking_confirmation",
    //   //   // to: "kevinchoi012580@gmail.com",
    //   //   subject: "Booking Confirmation",

    //   //   data: {
    //   //     site_logo: "",
    //   //     booking_id: submitRecord.booking_id,
    //   //     user_id: submitRecord.user_id,
    //   //     booking_items: submitRecord,
    //   //   }
    //   // });
    //   // console.log('template send', emailResult);
    // }
    // if (patchData && patchData.status === 10) {
    //   response = await Service.call('patch', '/api/booking_item/cancel', patchData)
    // }

    // // console.log("submitRecord", submitRecord)
    // if (submitRecord.user_option === 'user') {
    //   // let patchData = {
    //   //   status: submitRecord.status,
    //   //   user_id: submitRecord.user_id || 'Guest',
    //   //   booking_id: submitRecord.booking_id
    //   // }
    //   // if (patchData && patchData.status === 1) {
    //   //   response = await Service.call('patch', '/api/booking_item/confirm', patchData)

    //   //   let userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

    //   //   let emailResult = await Service.call('post', '/api/email/template', {
    //   //     type: "booking_confirmation",
    //   //     // to: "kevinchoi012580@gmail.com",
    //   //     subject: "Booking Confirmation",

    //   //     data: {
    //   //       site_logo: "",
    //   //       booking_id: submitRecord.booking_id,
    //   //       user_id: submitRecord.user_id,
    //   //       booking_items: submitRecord,
    //   //     }
    //   //   });
    //   //   console.log('template send', emailResult);
    //   // }
    //   // if (patchData && patchData.status === 10) {
    //   //   response = await Service.call('patch', '/api/booking_item/cancel', patchData)
    //   // }
    // } else {
    //   // let patchData = {
    //   //   status: submitRecord.status,
    //   //   email: submitRecord.email,
    //   //   mobile: submitRecord.mobile,
    //   //   first_name: submitRecord.first_name,
    //   //   last_name: submitRecord.last_name,
    //   //   nickname: submitRecord.nickname,
    //   //   remarks: submitRecord.remarks,
    //   //   booking_id: submitRecord.booking_id
    //   // }

    //   // if (patchData && patchData.status === 1) {
    //   //   response = await Service.call('patch', '/api/booking_item//guest/confirm', patchData)
    //   // }
    //   // if (patchData && patchData.status === 10) {
    //   //   console.log("test")
    //   //   // response = await Service.call('patch', '/api/booking_item/guest/cancel', patchData)
    //   // }
    //   // console.log("to>>",to)
    //   // let emailResult = await Service.call('post', '/api/email/template', {
    //   //   type: "booking_confirmation",
    //   //   to: to,
    //   //   subject: "Booking Confirmation",

    //   //   data: {
    //   //     site_logo: "",
    //   //     booking_id: submitRecord.booking_id,
    //   //     user_id: submitRecord.user_id,
    //   //     booking_items: submitRecord,
    //   //   }
    //   // });
    //   // console.log('template send', emailResult);
    // }

    // // console.log('response>>>', response)
    // if (response.status && response.errorCode === -80101) {
    //   notification.error({
    //     message: t('insufficient_quota'),
    //   });
    //   return
    // }
    // if (response.status && response.status < 1) {
    //   notification.error({
    //     message: t('fail'),
    //   });
    //   return
    // }
    // if (response.status && response.status > 0) {
    //   notification.success({
    //     message: t('success'),
    //   });
    //   callBack()
    //   setModalVisible(false)
    // }
  };

  return (
    <div>
      <Row gutter={12}>
        <Col>
          <p>{t("category_name")}</p>
          <Select
            defaultValue={1}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Category"
            optionFilterProp="children"
            onChange={(value) => handleItemCategoryFilterChange(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {/* <Option key={0} value={0}>全部</Option> */}
            {_.map(itemCategory, (itemCategoryRc) => (
              <Option
                key={itemCategoryRc.item_category_id}
                value={itemCategoryRc.item_category_id}
              >
                {itemCategoryRc.category_name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <p>{t("item_name")}</p>
          <Select
            mode="multiple"
            style={{ width: 300 }}
            placeholder="Search to Item"
            disabled={itemCategoryFilter === 0}
            value={itemFilter}
            // value={(isFiltered) ? SelectedItemList : []}
            onChange={(value) => handleItemFilterChange(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.map(item, (itemRc) => (
              <Option key={itemRc.item_id}>{itemRc.item_name}</Option>
            ))}
          </Select>
        </Col>
        <Col>
          <p>{t("status")}</p>
          <Select
            defaultValue={0}
            style={{ width: 200 }}
            placeholder="Search Status"
            onChange={(value) => handleStatusFilterChange(value)}
          >
            <Option value={0} key={0}>
              {t("all")}
            </Option>
            <Option value={1} key={1}>
              {t("available")}
            </Option>
            <Option value={10} key={10}>
              {t("booked")}
            </Option>
          </Select>
        </Col>
        <Col>
          <p> &nbsp;</p>
          <Button
            type="primary"
            onClick={() => AddSharingTableBooking()}
            // onClick={() => {
            //   setModalVisible(true)
            //   // setSelectedRecord({
            //   //   user_quota_id: 0,
            //   //   user_id: null
            //   // })
            //   // setEditOption(true)
            // }}
            // disabled={!isAdmin}
          >
            {t("add_cowork")}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin indicator={<LoadingOutlined />} spinning={loading}>
        <UserbookingListCalender
          BookingItem={calendarEvents}
          setLoading={setLoading}
          callBack={() => getBookingItem()}
          selectDateCallBack={(prevOrNext, focusWeek) =>
            selectDate(prevOrNext, focusWeek)
          }
        />
      </Spin>

      <img
        alt="logo"
        src={floor_plan}
        style={{
          width: "90%",
          // maxWidth: '400px',
          padding: "100px",
        }}
      />
      <Divider />
      <BookingInfoModal
        formType={1}
        //userObj={userObj}
        // userList={userList}
        dataObj={eventObj}
        //eventObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        // handleFormSubmit={}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />
    </div>
  );
};

export default UserbookingListCalendarView;
