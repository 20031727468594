import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import * as _ from 'lodash';

// export default function PrivateRoute(props) {
//   const app = useSelector((state) => state.app);

//   return (
//     <Route
//       render={() => {
//         return (
//           app.auth === true
//             ? <Component />
//             : <Redirect to="/login" />
//         )}}
//     />
//   );
// }

class PrivateRoute extends Component {
  render() {
    // const {component: Component, app, ...rest} = this.props;
    const {
      component: AppComponent,
      privilege,
      app,
    } = this.props;

    if (app.auth === null) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
          <Spin indicator={antIcon} />
        </div>
      )
    }

    return (
      <Route
        render={props => {
      if(app.auth) {
        if (privilege=='admin' && app.admin.admin_id) {
          return <AppComponent />
        }
        if (privilege=='company' && (app.company_admin.company_admin_id || app.company_user.company_user_id)) {
          return <AppComponent /> 
        }
        if (privilege=='admin' && !app.admin.admin_id) {
          return <Redirect to="/company/home"/> 
        }
        if (privilege=='company' && !app.company_admin.company_admin_id) {
          return <Redirect to="/admin/home"/> 
        }
      }
      
      return <Redirect to="/company/login" />
      // return (
      //   app.auth
      //   ? <AppComponent {...props}/>
      //   : <Redirect to="/admin/login" />
      // )
    }} />
    );
  }
};

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);
