import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, message, notification, Input, Space,
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined,
  SearchOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';


import queryString from 'query-string';
import * as Service from '../../core/Service';
import UserQuotaModal from './UserQuotaModal';

const debug = require('debug')('app:admin:client:src:AdvertisementList');

const CompanyQuotaRecordTable = (props) => {
  let location = useLocation();
  const tableIDName = "user_id";
  const { t } = useTranslation()
  const { callBack, handleFormSubmit, initialValues } = props
  const [dataList, setDataList] = useState(props.dataObj);
  const [searchText, setSearchText] = useState("");
  const { id } = queryString.parse(location.search);

  // const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(0);

  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput
  const [modalVisible, setModalVisible] = useState(false);
  const { defaultActiveKey } = props
  // defaultActiveKey

  debug(`modalVisible cp2>>>`, modalVisible)
  debug(`setModalVisible cp1>>>`, setModalVisible)
  useEffect(() => {
    debug(`modalVisible>>>`, setModalVisible)
  }, [modalVisible]);


  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const SucessfulNotificationWithIcon = (type) => {
    notification[type]({
      message: 'Notification Title',
      description:
        'added',
    });
  };


  const putAccessLog = async (user_id) => {
    let postObj = {
      user_id
    }
    let response = await Service.call('put', '/api/user/accesslog/ams?type=individual', postObj)
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
  }

  const putEmail = async (user_id) => {
    console.log("test>>")


    let postObj = {
      user_id
    }
    let response = await Service.call('post', '/api/user/email/confirm_member?type=individual', postObj)


    // call back function getAllData

    callBack()
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
    // callBack()
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    // console.log("selectedKeys >>", selectedKeys[0])
    // console.log("dataIndex >>", dataIndex)
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000000' }} />,
    onFilter: (value, record) => {
      // console.log("value >>> ", value)
      // console.log("record >>> ", record)
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const setTableHeader = () => {
    const columns = [
      // {
      //   title: t('user_id'),
      //   dataIndex: 'user_id',
      //   sorter: (a, b) => a.user_id - b.user_id,
      // },
      // {
      //   title: t('會員類型'),
      //   dataIndex: 'type',
      //   render: (value) => displayType(value),
      //   sorter: (a, b) => a.type - b.type
      // },

      // {
      //   title: t('user_type'),
      //   dataIndex: 'user_type',
      //   sorter: (a, b) => a.user_type.localeCompare(b.user_type),
      //   render: (value) => displayUserType(value)
      // },


      {
        title: t('company_name'),
        dataIndex: 'company_name_en',
        sorter: (a, b) => a.company_name_en - b.company_name_en,
      },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name - b.item_name,
      },
      {
        title: t('booking_date'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time - b.start_time,
        render: (start_time) => {
          return moment.unix(start_time).format('YYYY-MM-DD')
        },
      },
      {
        title: t('quota_required'),
        dataIndex: 'quota',
        sorter: (a, b) => a.quota - b.quota,
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        sorter: (a, b) => a.remarks - b.remarks,
      },


      // {
      //   title: t('end_booking_time'),
      //   dataIndex: 'end_time',
      //   sorter: (a, b) => a.end_time - b.end_time,
      //   render: (end_time) => {
      //     return moment.unix(end_time).format('YYYY-MM-DD HH:mm')
      //   },
      // },
      // {
      //   title: t('section_duration'),
      //   dataIndex: 'section_duration',
      //   sorter: (a, b) => a.section_duration - b.section_duration,
      //   render: (value) => {
      //     return Math.round(value / 60) + t('mins')
      //   },
      // },
      // {
      //   title: t('section_price'),
      //   dataIndex: 'section_price',
      //   sorter: (a, b) => a.section_price - b.section_price,
      //   render: (value) => {
      //     return '$' + value
      //   },
      // },

      {
        title: t('relevant_admin'),
        dataIndex: 'companyUserName',
        sorter: (a, b) => a.companyUserName - b.companyUserName,
      },
      // {
      //   title: t('is_active'),
      //   dataIndex: 'is_active',
      //   render: (value) => displayIsActive(value),
      //   sorter: (a, b) => a.is_active - b.is_active
      // },

      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: t('booking_type'),
        dataIndex: 'booking_type',
        render: (value) => displayChangeType(value),
        sorter: (a, b) => a.booking_type - b.booking_type
      },

      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD')
        },
      }


    ];
    return columns;
  }
  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayChangeType = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("system_booking");
        tagColor = 'blue';
        break;
      case 2:
        displayStr = t("admin");
        tagColor = 'red';
        break;
      case 3:
        displayStr = t("system_schedule");
        tagColor = 'green';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }


  const displayIsExpired = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已到期 ";
        tagColor = 'red';
        break;
      case 0:
        displayStr = "未到期";
        tagColor = 'green';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = ""
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);

    switch (statusValue) {
      case 10:
        displayStr = "已預約";
        tagColor = '#5cc461';
        break;
      case 8:
        displayStr = "已簽到";
        tagColor = '#5099a3';
        break;
      case 1:
        displayStr = "已取消";
        tagColor = '#dee0d1';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#FF1A53';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayEmailStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已發送";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未發送";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (

    <div>
      {/* <Button
        type="primary"
        onClick={() => {
          setModalVisible(true)
          setSelectedRecord({
            user_quota_id: 0,
            user_id: initialValues.user_id,
            type: initialValues.type
          })
          // setEditOption(true)
        }}
      // disabled={!isAdmin}
      >
        {t('add_user_quota')}
      </Button>
      <Divider /> */}

      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
      />
    </div>

  )
}

export default CompanyQuotaRecordTable;
