import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, notification, Input, Space
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../../../core/Service';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const CompanyUserQuotaLogListTable = (props) => {
  const tableIDName = "user_id";
  const { t } = useTranslation()
  const [LogInfoForm] = Form.useForm();
  const { callBack, logList, loading } = props
  const [dataList, setDataList] = useState(props.dataObj);
  const [logInfoModalVisible, setLogInfoModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { defaultActiveKey } = props
  let searchInput


  useEffect(() => {
    setDataList(logList)
  }, [logList]);

  // useEffect(() => {
  //   setDataList(props.dataObj)
  // }, [props.dataObj]);

  const onFinish = async (formData) => {
    // let postObj = {
    //   user_id,
    //   first_name: "test",
    //   last_name: "test",
    //   email: "test",
    //   mobile: "test",
    // }
    formData.user_id = selectedRecord
    let response = await Service.call('put', '/api/user/accesslog/ams?type=company', formData)
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
    setLogInfoModalVisible(false)
  }
  const putEmail = async (user_id) => {

    let postObj = {
      user_id
    }
    let response = await Service.call('post', '/api/user/email/confirm_member?type=company', postObj)
    //call back function get all data
    callBack()
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000000' }} />,
    onFilter: (value, record) => {

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const setTableHeader = () => {
    const columns = [
      {
        title: t('會員編號'),
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key - b.user_key,
        ...getColumnSearchProps('user_key'),
      },
      {
        title: t('會員類型'),
        dataIndex: 'type',
        render: (value) => displayType(value),
        sorter: (a, b) => a.type - b.type
      },
      {
        title: t('username'),
        dataIndex: 'username',
        sorter: (a, b) => a.username.localeCompare(b.username),
        // sorter: (a, b) => a.username - b.username,
        ...getColumnSearchProps('username'),
      },
      // {
      //   title: t('last_name'),
      //   dataIndex: 'last_name',
      //   sorter: (a, b) => a.last_name - b.last_name,
      // },
      {
        title: t('category_name'),
        dataIndex: 'category_name',
        sorter: (a, b) => a.category_name - b.category_name,
      },
      {
        title: t('change_amount'),
        dataIndex: 'change_amount',
        sorter: (a, b) => a.change_amount - b.change_amount,
      },
      {
        title: t('balance'),
        dataIndex: 'balance',
        sorter: (a, b) => a.balance - b.balance
      },
      {
        title: t('relevant_admin'),
        dataIndex: 'companyUserName',
        sorter: (a, b) => a.companyUserName - b.companyUserName,
      },
      // {
      //   title: t('status'),
      //   dataIndex: 'status',
      //   render: (value) => displayStatus(value),
      //   sorter: (a, b) => a.status - b.status
      // },
      // {
      //   title: t('change_type'),
      //   dataIndex: 'change_type',
      //   render: (value) => displayChangeType(value),
      //   sorter: (a, b) => a.change_type - b.change_type
      // },
      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD')
        },
      }
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "使用中 Active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "停用 Inactive";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = ""
        break;
    }
    return displayStr;
  }

  const displayEmailStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已發送";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未發送";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const displayChangeType = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("system_booking");
        tagColor = 'blue';
        break;
      case 2:
        displayStr = t("admin");
        tagColor = 'red';
        break;
      case 3:
        displayStr = t("system_schedule");
        tagColor = 'green';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 2:
        displayStr = t("waiting");
        tagColor = 'dark grey';
        break;
      case 1:
        displayStr = t('success');
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("waiting");
        tagColor = 'red';
        break;
      case -1:
        displayStr = t('fail');
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }


  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
        loading={loading}
      />
      <Modal
        title="輸入訪客資料"
        visible={logInfoModalVisible}
        footer={null}
        onCancel={() => setLogInfoModalVisible(false)}
      >
        <Form
          form={LogInfoForm}
          name="UserForm"
          onFinish={onFinish}
        >

          <Form.Item
            label="名稱"
            name="username"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="電郵"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="電話"
            name="mobile"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </Layout>
  )
}

export default CompanyUserQuotaLogListTable;