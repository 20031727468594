import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Checkbox, Tooltip
} from 'antd';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;


const MemberForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation()


  let { initialValues } = props

  const [UserForm] = Form.useForm();

  const [isStudent, setIsStudent] = useState();
  const [isReceive, setIsReceive] = useState('');
  const [isOther, setIsOther] = useState('');
  const [isOtherField, setIsOtherField] = useState(false);
  const [isReceiveNotice2, setIsReceiveNotice2] = useState('');
  const { id } = queryString.parse(location.search);
  const { key } = queryString.parse(location.search);
  const [MaxBirth, setMaxBirth] = useState('')
  const [number, setNumber] = useState("");
  const [ec_number, setec_number] = useState("");
  const [creatTime, setcreateTime] = useState("");
  const [updateTime, setupdateTime] = useState("");
  const [initialValuesForm, setInitialValues] = useState({
    gender: "m",
    mobilePrefix: "+852",
    contactEmergencyMobilePrefix: "+852",
    type: 1
  })

  useEffect(() => {
    if (id > 0) {
      // initialValues append to initialValuesForm
      let newObject = { ...initialValuesForm, ...initialValues }
      setcreateTime("申請時間:" + " " + moment.unix(initialValues.create_time).format('YYYY-MM-DD HH:mm'))
      setupdateTime("最後更新時間:" + " " + moment.unix(initialValues.utime).format('YYYY-MM-DD HH:mm'))
      setInitialValues(newObject)
      setNumber(initialValues.mobile)
      setec_number(initialValues.contact_Emergency_mobile)
    }
    updateMaxBirth();
  }, [initialValues])


  // if (id > 0) {
  //   let newObject = { ...initialValuesForm, ...initialValues }
  //   setInitialValues(newObject)
  //   console.log(newObject)
  // }

  useEffect(() => {
    UserForm.resetFields()
    setIsReceive(props.initialValues.receive_news_info || 0)
    setIsReceiveNotice2(props.initialValues.receive_market_info || 0)
    setIsStudent(props.initialValues.type || 1)
    setIsOtherField(props.initialValues.isNotNull_source_other)

  }, [initialValuesForm])

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }




  const openOtherField = e => {

    if (e.target.checked) {
      setIsOtherField(true)
    } else {
      setIsOtherField(false)
    }
  };
  const updateMaxBirth = () => {



    setMaxBirth(moment().subtract(16, 'years').format("YYYY-MM-DD"))
  }

  const disabledDate = (current) => {

    let customDate = moment().subtract(16, 'years').format("YYYY-MM-DD");

    return current && current >= moment(customDate, "YYYY-MM-DD");

  }



  const onFinish = async (formData) => {
    let postData = formData

    postData.is_active = initialValues.is_active
    postData.status = initialValues.status


    if (!_.isUndefined(postData.mobilePrefix && postData.mobile)) {
      postData.mobile = _.toString(`${postData.mobilePrefix}-${postData.mobile}`)
    }

    if (!_.isUndefined(postData.contactEmergencyMobilePrefix && postData.contact_Emergency_mobile)) {
      postData.ec_tel = _.toString(`${postData.contactEmergencyMobilePrefix}-${postData.contact_Emergency_mobile}`)
    }

    if (!_.isUndefined(postData.source_info)) {
      postData.source_info = _.toString(postData.source_info)
    }
    if (!_.isUndefined(postData.receive_news_info_option_source)) {
      postData.receive_news_info_option_source = postData.receive_news_info_option_source.join()
    }
    if (!_.isUndefined(postData.receive_market_info_option_source)) {
      postData.receive_market_info_option_source = postData.receive_market_info_option_source.join()
    }
    postData.date_of_birth = moment(postData.date_of_birth).unix()

    // postData.type = history.location.query.id


    postData.user_id = id;

    let response;
    if (id > 0) {
      response = await Service.call('patch', `/api/user`, postData);
    } else {
      response = await Service.call('put', `/api/user?byAdmin=true`, postData);
    }

    if (response.status === -1) {
      if (response.errorCode === -40102) {
        notification.error({
          message: t("First Name , Last Name , Mobile and Email Duplicated"),
        });
        return;
      }
      if (response.errorCode === -40101) {
        notification.error({
          message: t("First Name , Last Name and Mobile Duplicated"),
        });
        return;
      }
      notification.error({
        message: t("error"),
      });
      return;
    }
    notification.success({
      message: t("success"),
    });

    // if (initialValuesForm.is_active != formData.is_active) {
    //   history.push("/admin/member/list?id=3")
    // }
    // if (formData.status === 0) {
    //   history.push("/admin/member/list?id=2")
    // }
    // if (formData.status === 1) {
    //   history.push("/admin/member/list?id=1")
    // }
    // if (initialValuesForm.status === 0) {
    //   history.push("/admin/member/list?id=2")
    // } else {
    //   history.push("/admin/member/list?id=3")
    // }
    // // console.log("dfasfdas", initialValuesForm.status)
    if (id === 0) {
      history.push("/company/member/list")
    } else {
      history.push(`/company/member/list?key=${key}`)
    }

    // history.push("/admin/member/list")
  }

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setNumber(value);
      UserForm.setFieldsValue({ mobile: value })
    }
  };
  const ec_onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setec_number(value);
      UserForm.setFieldsValue({ contact_Emergency_mobile: value })
    }
  };




  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      initialValues={initialValuesForm}
      onFinish={onFinish}
    >
      <Form.Item
        label="會員類別"
        name="type"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t('selectLabel')}
          onChange={(value) => {
            setIsStudent(value)
          }}
        >
          <Option value={1}>個人會員</Option>
          <Option value={2}>學生會員</Option>
        </Select>
      </Form.Item>

      {/* <Form.Item
        label="會員編號"
        name="user_key"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item> */}

      <Form.Item
        label={`${t('Last_name_en')} ${t('description_name')}`}
        name="last_name"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('first_name_en')} ${t('description_name')}`}
        name="first_name"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('Last_name_ch')} ${t('description_name')}`}
        name="last_name_zh"
      // rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('first_name_ch')} ${t('description_name')}`}
        name="first_name_zh"
      // rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>
    
      <Form.Item
        label={t('gender')}
        name="gender"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Select>
          <Option value="m">{t('male')}</Option>
          <Option value="f">{t('female')}</Option>
        </Select>
      </Form.Item>


      <Form.Item
        label={t('date_of_birth')}
        name="date_of_birth"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <DatePicker
          // onChange={(date, dateString) => {console.log(moment().unix(date))}}
          format="YYYY-MM-DD"
          placeholder=""
        />
      </Form.Item>

      <Form.Item
        // contact_person_mobile
        label={t('moblie')}
        name="mobile"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Tooltip>
          <Input
            value={number}
            onChange={onNumberChange}
            addonBefore={(
              // contactPersonMobilePrefix
              <Form.Item name="mobilePrefix" noStyle>
                <Select style={{ width: 80 }} >
                  <Option value="+852">+852</Option>
                  <Option value="+86">+86</Option>
                </Select>
              </Form.Item>
            )}
            style={{ width: '100%' }}
          />
        </Tooltip>
      </Form.Item>

      <Form.Item
        label={t('email')}
        name="email"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('en')}${t('address')}`}
        name="address"
        // rules={[
        //   {
        //     required: true,
        //     message: "必須填寫"
        //   },
        // ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('zh')}${t('address')}`}
        name="address_zh"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('hkid')}
        name="hkid"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      {isStudent === 1 ? (
        <Form.Item
          label={t('occupation')}
          name="occupation"
          // rules={[{ required: true, message: t("must") }]}
        >
          <Input />
        </Form.Item>
      )
        : null}
      {isStudent === 2 ? (
        <Form.Item
          label={t('student_no')}
          name="student_no"
          rules={[{ required: true, message: t("must") }]}
        >
          <Input />
        </Form.Item>
      )
        : null}

      {isStudent === 2 ? (
        <Form.Item
          label={t('school_name')}
          name="school_name"
          rules={[{ required: true, message: t("must") }]}
        >
          <Input />
        </Form.Item>
      )
        : null}

      <Form.Item
        label={t('ec_name_en')}
        name="ec_name_en"
        // rules={[
        //   {
        //     required: true,
        //     message: "必須填寫"
        //   },
        // ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('ec_name_zh')}
        name="ec_name_zh"
      >
        <Input />
      </Form.Item>

      <Form.Item
        // contact_person_mobile
        label={t('ec_tel')}
        name="contact_Emergency_mobile"
        // rules={[
        //   {
        //     required: true,
        //     message: "必須填寫"
        //   },
        // ]}
      >
        <Tooltip>
          <Input

            value={ec_number}
            onChange={ec_onNumberChange}
            addonBefore={(
              // contactPersonMobilePrefix
              <Form.Item name="contactEmergencyMobilePrefix" noStyle>
                <Select style={{ width: 80 }} >
                  <Option value="+852">+852</Option>
                  <Option value="+86">+86</Option>
                </Select>
              </Form.Item>
            )}
            style={{ width: '100%' }}
          />
        </Tooltip>

      </Form.Item>

      <Form.Item
        label={t('rwp')}
        name="rwp"
        // rules={[
        //   {
        //     required: true,
        //     message: "必須填寫"
        //   },
        // ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="語言選擇"
        name="preferred_language"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t('selectLabel')}

        >
          <Option value={"tc"}>繁體</Option>
          <Option value={"sc"}>简体</Option>
          <Option value={"en"}>English</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t('notice_two')}
        name="receive_news_info"
        rules={[{ required: true, message: t("must") }]}
      >
        <Radio.Group onChange={(value) => {
          setIsReceive(value.target.value)
        }}
        >
          <Radio value={1}>{t("receive")}</Radio>
          <Radio value={0}>{t("not_receive")}</Radio>
        </Radio.Group>
      </Form.Item>

      {isReceive === 1 ? (
        <Form.Item
          name="receive_news_info_option_source"
        >
          <Checkbox.Group>
            <Checkbox value="1">Whatsapp</Checkbox>
            <Checkbox value="2">{t("email")}</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      )
        : null
      }

      <Form.Item
        label={t("notice_one")}
        name="receive_market_info"
        rules={[{ required: true, message: t("must") }]}
      >
        <Radio.Group onChange={(value) => {
          setIsReceiveNotice2(value.target.value)
        }}
        >
          <Radio value={1}>{t("receive")}</Radio>
          <Radio value={0}>{t("not_receive")}</Radio>
        </Radio.Group>
      </Form.Item>

      {isReceiveNotice2 === 1 ? (
        <Form.Item
          name="receive_market_info_option_source"
        >
          <Checkbox.Group >
            <Checkbox value="1">Whatsapp</Checkbox>
            <Checkbox value="2">{t("email")}</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      )
        : null}

      <Form.Item
        label={t("source")}
        name="source_info"
      >
        <Checkbox.Group>
          <Checkbox value="1">{t("facebook")}</Checkbox>
          <Checkbox value="2">{t("instagrtam")}</Checkbox>
          <Checkbox value="3">{t("friend")}</Checkbox>
          <Checkbox value="4">{t("Jewelry")}</Checkbox>
          <Checkbox value="5">{t("Jewelry_web")}</Checkbox>
          <Checkbox value="6" onChange={openOtherField}>{t("other")}</Checkbox>
        </Checkbox.Group>
      </Form.Item>


      {isOtherField ? (
        <Form.Item
          name="source_other"
          rules={[{ required: true, message: t("must") }]}
        >
          <Input
            placeholder={t("fill_in")}
          />
        </Form.Item>
      )
        : null
      }

      <Form.Item
        label="同意條款"
        name="agree_tnc"
        rules={[{ required: true, message: t("must") }]}
      >
        <Radio.Group>
          <Radio value={1}>是</Radio>
          <Radio value={0}>否</Radio>
        </Radio.Group>
      </Form.Item>

      {/* <Form.Item
        label="Application Date"
        name="application_date"
      >
        <DatePicker
          format="YYYY-MM-DD"
        />
      </Form.Item> */}
      {/* 
      <Form.Item
        label="Application Receved"
        name="application_status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value={1}>Recevie</Radio>
          <Radio value={0}>Not recevie</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Payment Method"
        name="payment_method"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio value={1}>Cheque </Radio>
          <Radio value={0}>Cash</Radio>
          <Radio value={1}>Others </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Members No"
        name="user_key"
      >
        <Input />
      </Form.Item> */}

      {/* <Form.Item
        label="Date"
        name="payment_method"
      >
        <DatePicker
          format="YYYY-MM-DD"
        />
      </Form.Item> */}

      <Form.Item
        label="備註"
        name="remark"
      >
        <TextArea rows={4} />
      </Form.Item>
      <p>{creatTime}</p>
      <p>{updateTime}</p>


      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default MemberForm;
