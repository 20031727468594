import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, Space,Input
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
// import MemberListTable from './MemberListTable';

import QuotaListTable from './QuotaListTable';
import FormUploadFile from '../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import UserQuotaModal from './UserQuotaModal';
import { useTranslation } from 'react-i18next';
import PostsFormModal from '../../components/PostsFormModal';
import Fuse from 'fuse.js';
// import MemberListTableConfirm from './MemberListTableConfirm';
import {
  PlusOutlined,
  UserOutlined,
  ScheduleOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DownOutlined,
  UpOutlined,
  DeleteOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;
const debug = require('debug')('app:admin:client:src:AdvertisementList');


const involvedModelName = "company";
// const title = "會員列表";
const selectedKey = 3;
const tableIDName = "company_user_id";
const openKey = "app_config";
const filterableFields = [
  // `post_status`,
  `post_date`,
  `post_title`,
  `post_excerpt`,
  `keywords`,
  `admin_id`,
];
function UploadSection(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    debug(info);
    // return;
    console.log('info>>>', info)
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
        // webcast_event_id
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

const PostsList = (props) => {
  const app = useSelector((state) => state.app);

  const { t } = useTranslation()
  const title = t('member_quota_table');
  const [isChangingIsActive, setChangingIsActive] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getTapkey, setgetTapkey] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  // debug(`modalVisible cp1>>>`, modalVisible)
  const [selectedRecord, setSelectedRecord] = useState(1);
  const [filterDataList, setFilterDataList] = useState([]);

  const history = useHistory();
  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key

  useEffect(() => {
    getAllData();
  }, []);

  // debug(`modalVisible cp1>>>`, modalVisible)
  // useEffect(() => {
  //   debug(`modalVisible>>>`, modalVisible)
  // }, [modalVisible]);

  const getAllData = async () => {
    setLoading(true)
    let endpoint = `/api/posts/list`;
    let resp = await Service.call('GET', endpoint);
    setDataList(resp);
    setFilterDataList(resp);
    // setSortingList(resp);
    // let lastRecord = _.last(resp);
    setLoading(false);
  }

  const callback = async () => {
    console.log("call back")
  }

  const importUserCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/user/import', {
      filepath,
    });

    if (!resp || resp.status <= 0) {
      notification.error({
        message: 'User import failed, please contact administrator',
        description: '',
      });
      return;
    }

    // if (resp.statusError === -1) {
    //   notification.error({
    //     message: `User import failed. CSV index ${resp.row}`,
    //     description: `The First Name ${resp.first_name},Last Name ${resp.last_name} and Mobile ${resp.mobile} Duplicated.`,
    //     duration: 0
    //   });
    //   getAllData();
    //   return;
    // }
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }
    notification.success({
      message: `User import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description: description,
      duration: 0
    });

    getAllData();
  }


  const deletePost = async (post_id) => {
    setLoading(true)
    let endpoint = `/api/posts/${post_id}`;
    let resp = await Service.call('delete', endpoint);
    if (!resp || resp.status <= 0) {
      notification.error({
        message: 'User delete failed, please contact administrator',
        description: '',
      });
      return;
    }
    message.success(t('modal_success'));
    getAllData()
  }

  ///api/posts/

  // const getTapValue = (activeKey) => {
  //   setgetTapkey(activeKey)

  // };


  const setTableHeader = () => {
    let columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title={t('operation_edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={<EditOutlined />}
                  // disabled={record.is_active ? true : false}
                  onClick={() => {
                    setSelectedRecord(record)
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={record.post_status === `public` ? t('post_hide') : t('post_publish')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 4}}
                  icon={record.post_status === "public" ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                  onClick={async () => {
                    if (isChangingIsActive) return;
                    setChangingIsActive(true);
                    let post_status = record.post_status === "public" ? "hide" : "public"
                    await Service.call('PATCH', '/api/posts/status',
                      {
                        post_id: record.post_id,
                        post_status
                      });
                    getAllData();
                    setChangingIsActive(false);
                  }}
                />
              </Tooltip>

              <Tooltip title="operation_delete">
                  <Popconfirm
                    title="確認刪除?"
                    onConfirm={() => deletePost(record.post_id)}
                    okText="是"
                    cancelText="否"
                  >
                    <Button
                      shape="circle"
                      style={{ marginRight: 4}}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
              </Tooltip>
            </span>
          )
        },
      },
      {
        title: t('active_status'),
        dataIndex: 'post_status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.post_status.localeCompare(b.post_status)
      },
      // {
      //   title: t('sorting'),
      //   dataIndex: 'sorting',
      //   render: (value, record) => {
      //     return (
      //       <Row>
      //         <Space>
      //           <Col>
      //             <Button
      //               size="small"
      //               onClick={() => moveUp(record)}
      //               disabled={record.sorting === 1}
      //             >
      //               <UpOutlined />
      //             </Button>
      //           </Col>
      //           <Col>
      //             {value}
      //           </Col>
      //           <Col>
      //             <Button
      //               size="small"
      //               onClick={() => moveDown(record)}
      //               disabled={record.sorting === sortingList.length}
      //             >
      //               <DownOutlined />
      //             </Button>
      //           </Col>
      //         </Space>
      //       </Row>
      //     );
      //   },
      //   sorter: (a, b) => a.sorting - b.sorting
      // },
      {
        title: t('post_date'),
        dataIndex: 'post_date',
        render: (value) => displayMoment(value),
        sorter: (a, b) => a.post_date - b.post_date
      },
      {
        title: t('post_title'),
        dataIndex: 'post_title',
        // sorter: (a, b) => a.post_title - b.post_title
      },
      // {
      //   title: t('post_excerpt'),
      //   dataIndex: 'post_excerpt',
      //   // sorter: (a, b) => a.post_excerpt - b.post_excerpt
      // },
      // {
      //   title: t('post_content'),
      //   dataIndex: 'post_content',
      //   sorter: (a, b) => a.post_content - b.post_content
      // },
      // {
      //   title: t('keywords'),
      //   dataIndex: 'keywords',
      //   // sorter: (a, b) => a.keywords - b.keywords
      // },
      // {
      //   title: t('on_landing'),
      //   dataIndex: 'on_landing',
      //   render: (value) => displayBoolean(value),
      //   sorter: (a, b) => a.on_landing - b.on_landing
      // },
      // {
      //   title: t('admin_user'),
      //   dataIndex: 'admin_id',
      //   sorter: (a, b) => a.admin_id - b.admin_id
      // },
      {
        title: t('ctime'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => displayMoment(value),
      },
      {
        title: t('utime'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => displayMoment(value),
      },
    ];
    return columns;
  }

  const displayBoolean = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let isTrue = _.toInteger(value);
    switch (isTrue) {
      case 1:
        displayStr = t("yes");
        tagColor = 'green';
        break;
      case 0:
        displayStr = t("no");
        tagColor = 'red';
        break;
      default:
        displayStr = t("fail");
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm:ss') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toString(value);
    switch (statusValue) {
      case `public`:
        displayStr = t("public");
        tagColor = 'green';
        break;
      case `hide`:
        displayStr = t("hide");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const getDataBySearchKeyword = (filterObj) => {
    let filterList = dataList;
    try {
      const options = {
        includeScore: true,
        findAllMatches: true,
        threshold: 0, // exact match
        keys: filterableFields,
        ignoreLocation: true, // also search within strings not only from start
      }
      const fuse = new Fuse(filterList, options)
      const result = fuse.search(filterObj);
      console.log(result);
      filterList = _.map(result, 'item');
      console.log(filterList);
      filterList = _.orderBy(filterList, ['user_id'], ['desc']);
    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      filterList = _.orderBy(filterList, 'user_id');
      setFilterDataList(filterList);
    }
  }

  let timeout;
  const debounce = (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };

  const onChange = (keyword) => {
    debounce(() => {
      getDataBySearchKeyword(keyword);
      if (_.isEmpty(keyword)) {
        setFilterDataList(dataList);
      }
    }, 400);
  };

  return (
    <NavAndSideFrame {...props} title={t('posts_list')} selectedKey={selectedKey} openKey={openKey}>
      <Row>
        <Col>
          <Button
            icon={<PlusOutlined />}
            style={{marginBottom: 10}}
            type="primary"
            onClick={() => {
              setSelectedRecord({ post_id: 0, sorting: 1});
              setModalVisible(true);
            }}
          >
            {t('create')}
          </Button>
        </Col>

        <Col style={{marginTop: 5, marginLeft: 10}}>
          {t(`search`) + `:`}
        </Col>
        <Col style={{marginLeft: 20}}>
          <Input
            placeholder={t(`please_input_keyword`)}
            style={{ minWidth: 270, width: '50%' }}
            onChange={
              (values) => {
                onChange(values.target.value);
              }
            }
          />
        </Col>
      </Row>
      {/* <Divider /> */}
      <Spin spinning={loading}>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={filterDataList}
          columns={setTableHeader()}
          size="middle"
        />
        <PostsFormModal
          modalVisible={modalVisible}
          selectedRecord={selectedRecord}
          setModalVisible={setModalVisible}
          callBack={() => getAllData()}
        />
      </Spin>
      {/* <Row justify="end">
        <Button
          type="primary"
          // onClick={updateSorting}
        >
          {t('submit')}
        </Button>
      </Row> */}
    </NavAndSideFrame>
  );
}
export default PostsList;