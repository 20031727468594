import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Calendar, Badge, Spin, Card
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import { useTranslation } from 'react-i18next';
import * as debugLib from 'debug';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
// import CompanyUserQuotaLogListTable from './CompanyUserQuotaLogListTable';
import FormUploadFile from '../../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';


import UserbookingListCalendarView from './UserbookingListCalendarView';


const debug = debugLib('app:pages/UserbookingContainer');

const involvedModelName = "company";
const selectedKey = "booking_list";
const tableIDName = "item_category_id";
const { Option } = Select;

// let sampleText = "apple";

const UserbookingContainer = (props) => {

  const { t } = useTranslation()
  const title = t('bookingItem');

  const [view, setView] = useState(1);

  const renderView = () => {
    if (view === 1) {
      return <UserbookingListCalendarView />
    }
    // if (view === 2) {
    //   return <UserbookingListTableView />

    // }
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      {/* <Select
        defaultValue={1}
        onChange={(value) => setView(value)}
      >
        <Option key={1} value={1}>{t("calendar_view")}</Option>
        <Option key={2} value={2}>{t("table_view")}</Option>
      </Select> */}
      <Card bordered={false}>
        {renderView()}
      </Card>
    </NavAndSideFrame>
  )
}

export default UserbookingContainer;
