import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, message, notification, Input, Space
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../../../core/Service';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const CompanyUserBookingLogListTable = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "booking_log_id";
  const app = useSelector((state) => state.app);
  const {
    logList, loading,dataKey
  } = props;
 

  const [dataList, setDataList] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  let searchInput
  useEffect(() => {
    // console.log("logList",logList)
    setDataList(logList)
  }, [logList]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };
 
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const setTableHeader = () => {
    const columns = [
      // {
      //   title: t('user_id'),
      //   dataIndex: 'id',
      //   sorter: (a, b) => a.id - b.id,
      // },
      {
        title: t('user_key'),
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key.localeCompare(b.user_key),
        // sorter: (a, b) => a.user_key - b.user_key,
        ...getColumnSearchProps('user_key')
      },
      {
        title: t('user_type'),
        dataIndex: 'user_type',
        sorter: (a, b) => a.user_type - b.user_type,
        render: (value) => displayUserType(value)
      },
      {
        title: t('name'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...getColumnSearchProps('name')
      },
      {
        title: t('company_name'),
        dataIndex: 'company_name_en',
        sorter: (a, b) => a.name.localeCompare(b.name),
        ...getColumnSearchProps('name')
      },
      // {
      //   title: t('company_name'),
      //   dataIndex: 'company_name_en',
      //   ...getColumnSearchProps('company_name_en'),
      //   // sorter: (a, b) => a.company_name_en.localeCompare(b.company_name_en),
      //   // sorter: (a, b) => a.company_name_en - b.company_name_en,
      // },
      {
        title: t('item_name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name.localeCompare(b.item_name),


      },
      {
        title: t('Tel_Contact_Person'),
        dataIndex: 'phone',
        sorter: (a, b) => a.phone.localeCompare(b.phone),
        // sorter: (a, b) => a.phone - b.phone,
      },
      {
        title: t('booking_date'),
        dataIndex: 'start_time',
        sorter: (a, b) => a.start_time.localeCompare(b.start_time),
        ...getColumnSearchProps('start_time'),
        //  sorter: (a, b) => a.start_time - b.start_time,
        // render: (start_time) => {
        //   return moment.unix(start_time).format('YYYY-MM-DD')
        // },

      },
      // {
      //   title: t('end_booking_time'),
      //   dataIndex: 'end_time',
      //   sorter: (a, b) => a.end_time - b.end_time,
      //    ...getColumnSearchProps('end_time'),
      //   render: (end_time) => {
      //     return moment.unix(end_time).format('YYYY-MM-DD ')
      //   },

      // },
      // {
      //   title: t('section_duration'),
      //   dataIndex: 'section_duration',
      //   sorter: (a, b) => a.section_duration - b.section_duration,
      //   render: (value) => {
      //     return Math.round(value / 60) + t('mins')
      //   },
      // },
      // {
      //   title: t('section_price'),
      //   dataIndex: 'section_price',
      //   sorter: (a, b) => a.section_price - b.section_price,
      //   render: (value) => {
      //     return '$' + value
      //   },
      // },
      {
        title: t('quota_required'),
        dataIndex: 'quota',
        sorter: (a, b) => a.quota - b.quota,
      },
      {
        title: t('remarks'),
        dataIndex: 'remarks',
        sorter: (a, b) => a.remarks.localeCompare(b.remarks),

      },
      {
        title: t('relevant_admin'),
        dataIndex: 'companyUserName',
        sorter: (a, b) => a.companyUserName.localeCompare(b.companyUserName),
        // sorter: (a, b) => a.companyUserName - b.companyUserName,
      },
      // {
      //   title: t('is_active'),
      //   dataIndex: 'is_active',
      //   render: (value) => displayIsActive(value),
      //   sorter: (a, b) => a.is_active - b.is_active
      // },

      {
        title: t('status'),
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: t('booking_type'),
        dataIndex: 'booking_type',
        render: (value) => displayChangeType(value),
        sorter: (a, b) => a.booking_type - b.booking_type
      },

      {
        title: t('create_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (ctime) => {
          return moment.unix(ctime).format('YYYY-MM-DD')
        },
      }
    ];
    return columns;
  }

  const displayUserType = (userType) => {
    let displayStr = '';
    let tagColor = 'green';

    switch (userType) {

      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = "error"
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayChangeType = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("system");
        tagColor = 'blue';
        break;
      case 2:
        displayStr = t("admin_user");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'blue';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = t("active");
        tagColor = 'blue';
        break;
      case 0:
        displayStr = t("inactive");
        tagColor = 'red';
        break;
      default:
        displayStr = t("error")
        tagColor = 'red';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);

    switch (statusValue) {
      case 10:
        displayStr = "已預約";
        tagColor = '#5cc461';
        break;
      case 8:
        displayStr = "已簽到";
        tagColor = '#5099a3';
        break;
      case 1:
        displayStr = "已取消";
        tagColor = '#dee0d1';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#FF1A53';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const onClickExportExcel = async () => {
    // let url = "/api/export/access_log/excel";
    // let data = await Service.call('get', url);
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    // console.log("data",dataKey)
    window.location = `${app.config.API_SERVER_URL}/api/export/booking_log/excel`;
  }


  return (
    <div>
      <Button
        type="primary"
        onClick={() => onClickExportExcel()}
      >
        導出紀錄 (Excel)
      </Button>
      <Divider />


      <Layout>


        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{ pageSize }}
          onChange={(pagination, filters, sorter, extra) => {
            if (pageSize !== pagination.pageSize) {
              setPageSize(pagination.pageSize)
            }
          }}
          loading={loading}
        />
      </Layout>
    </div>


  )
}

export default CompanyUserBookingLogListTable;