import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, Space
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import CompanyListTable from './CompanyListTable';
import FormUploadFile from '../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

const debug = require('debug')('app:admin:client:src:AdvertisementList');


//const involvedModelName = "company";
const title = "公司會員列表";
//const selectedKey = 3;
//const tableIDName = "company_user_id";
const { TabPane } = Tabs;

function UploadSection(props) {

  const {
    onSuccess,
  } = props;


  const onChange = (info) => {
    debug(info);
    // return;
  
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
        // webcast_event_id
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

const CompanyList = (props) => {

  const app = useSelector((state) => state.app);
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const [countActive, setCountActive] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [defaultActiveKey, setDefaultActiveKey] = useState('3');
  const history = useHistory();
  let location = useLocation();
  const { key } = queryString.parse(location.search);
  const [getTapkey, setgetTapkey] = useState(1);
  let defaultActiveKey = key




  useEffect(() => {
    getAllData();

  }, []);

 
  const getAllData = async () => {
    setLoading(true)

    let url = "/api/user/list?type=company";
    let data = await Service.call('get', url);
  

    // reorder records
    data = _.orderBy(data, ['utime'], ['desc'])

    let dataList = [];
    let dataList2 = []; //for other status
    let inactive_dataList = []

    let count = 0
    for (let i = 0; i < data.length; i++) {
      if (data[i].status === 1 && data[i].is_active === 1) {
        dataList.push(data[i]);
        count += 1;
      }
      if (data[i].status === 0 && data[i].is_active === 1) {
        dataList2.push(data[i]);
      }
      if (data[i].is_active === 0) {
        inactive_dataList.push(data[i]);
      }
    }

    if (dataList.status === -1) {
      console.error('error');
      setLoading(false)
      return
    }
    setCountActive(count)
    setDataList(dataList);
    setDataList2(dataList2);
    setInactive_dataList(inactive_dataList);
    setLoading(false)
    // reorder records
  }

  const importUserCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/user/import/company', {
      filepath,
    });

    if (!resp || resp.status <= 0) {
      notification.error({
        message: 'User import failed, please contact administrator',
        description: '',
      });
      return;
    }
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }

    notification.success({
      message: `User import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description: description,
      duration: 0
    });

    getAllData();
  }

  const onClickExportExcel = async () => {
    window.location = `${app.config.API_SERVER_URL}/api/export/user/excel?type=company`;
  }
  const getTapValue = (activeKey) => {
    setgetTapkey(activeKey)

  };

  return (
    <NavAndSideFrame title={title} selectedKey="admin_company">
      <div
      // style={{display: 'flex', justifyContent: 'space-between'}}
      >
        <Space size={8}>
          <Link
            to={{
              pathname: "/company/company/details/",
              search: "?id=0",
            }}
          >
            <Button
              type="primary"
            >
              新增公司會員
          </Button>
          </Link>


          <Button
            type="primary"
            onClick={() => onClickExportExcel()}
          >
            導出紀錄 (Excel)
         </Button>

      


          <UploadSection
            webcast_event_id="1"
            onSuccess={(resp) => {
              debug(`resp >>SendEmailButton `, resp);
              const {
                originalname,
                filename,
                filepath,
                url,
              } = resp;
              importUserCSV(filepath)
              // this.importUserCSV(webcast_event_id, filepath);
            }}
          />



        </Space>
        <Divider />
        <p>公司會員: {countActive}</p>
      </div>
      <Divider />
      <Tabs defaultActiveKey={defaultActiveKey}
        onChange={(value) => {
          getTapValue(value)
        }}>
        <TabPane tab="已確認" key="1">
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <CompanyListTable
              defaultActiveKey={1}
              dataObj={dataList}
              adminChangeData={getAllData}
              callBack={() => getAllData()}
            />
          </Spin>
        </TabPane>
        <TabPane tab="等待確認" key="2">
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <CompanyListTable
              defaultActiveKey={2}
              dataObj={dataList2}
              adminChangeData={getAllData}
              callBack={() => getAllData()}
            />
          </Spin>
        </TabPane>

        <TabPane tab="停用" key="3">
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <CompanyListTable
              defaultActiveKey={3}
              dataObj={inactive_dataList}
              adminChangeData={getAllData}
              callBack={() => getAllData()}
            />
          </Spin>
        </TabPane>


      </Tabs>
    </NavAndSideFrame>
  )
}

export default CompanyList;