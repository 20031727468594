import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Checkbox, Tooltip
} from 'antd';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;


const CompanyForm = (props) => {
  const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation()


  let { initialValues } = props

  const [UserForm] = Form.useForm();

  const [isStudent, setIsStudent] = useState('');
  const [isReceive, setIsReceive] = useState('');
  const [isOther, setIsOther] = useState('');
  const [isOtherField, setIsOtherField] = useState(false);
  const [isReceiveNotice2, setIsReceiveNotice2] = useState('');
  const [number, setNumber] = useState("");
  const [cn_number, setcnNumber] = useState("");
  const { id } = queryString.parse(location.search);
  const { key } = queryString.parse(location.search);
  const [creatTime, setcreateTime] = useState("");
  const [updateTime, setupdateTime] = useState("");

  const [initialValuesForm, setInitialValues] = useState({
    company_phone_prefix: '+852',
    contact_phone_prefix: '+852',
  });

  useEffect(() => {
    if (id > 0) {
      // initialValues append to initialValuesForm
      let newObject = { ...initialValuesForm, ...initialValues }
      setcreateTime("申請時間:" + " " + moment.unix(initialValues.create_time).format('YYYY-MM-DD HH:mm'))
      setupdateTime("更新時間:" + " " + moment.unix(initialValues.utime).format('YYYY-MM-DD HH:mm'))
      setNumber(initialValues.company_phone)
      setcnNumber(initialValues.contact_phone)
      setInitialValues(newObject)

    }


  }, [initialValues])

  useEffect(() => {
    UserForm.resetFields()
    setIsReceive(props.initialValues.receive_news_info || 0)
    setIsReceiveNotice2(props.initialValues.receive_market_info || 0)
    setIsStudent(props.initialValues.type || 1)
    setIsOtherField(props.initialValues.isNotNull_source_other)
  }, [initialValuesForm])

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }
  const openOtherField = e => {
   
    if (e.target.checked) {
      setIsOtherField(true)
    } else {
      setIsOtherField(false)
    }
  };

  const onFinish = async (formData) => {
    let postData = formData
    postData.is_active = initialValues.is_active
    postData.status = initialValues.status
    if (!_.isUndefined(postData.company_phone_prefix && postData.company_phone)) {
      postData.company_phone = _.toString(`${postData.company_phone_prefix}-${postData.company_phone}`)
    }

    if (!_.isUndefined(postData.contact_phone_prefix && postData.contact_phone)) {
      postData.contact_phone = _.toString(`${postData.contact_phone_prefix}-${postData.contact_phone}`)
    }

    if (!_.isUndefined(postData.source_info)) {
      postData.source_info = _.toString(postData.source_info)
    }
    if (!_.isUndefined(postData.receive_news_info_option_source)) {
      postData.receive_news_info_option_source = postData.receive_news_info_option_source.join()
    }
    if (!_.isUndefined(postData.receive_market_info_option_source)) {
      postData.receive_market_info_option_source = postData.receive_market_info_option_source.join()
    }
    postData.date_of_establish = moment(postData.date_of_establish).unix()

    // postData.type = history.location.query.id

    postData.user_id = id;
    postData.user_company_info_id = initialValuesForm.user_company_info_id
    

    let response;
    if (id > 0) {
      response = await Service.call('patch', `/api/user/company`, postData);
    } else {
      response = await Service.call('put', `/api/user/company`, postData);
    }

    if (response.status === -1) {

      if (response.errorCode === -40102) {
        notification.error({
          message: t("Contact First Name ,Contact Last Name , Company Mobile and Company Email Duplicated"),
        });
        return;
      }
      if (response.errorCode === -40101) {
        notification.error({
          message: t("Contact First Name ,Contact Last Name and Company Mobile Duplicated"),
        });
        return;
      }

      notification.error({
        message: t("error"),
      });
      return;
    }
    notification.success({
      message: t("success"),
    });
    // if (initialValuesForm.status === 0) {
    //   history.push("/admin/company/list?id=2")
    // } else {
    //   history.push("/admin/company/list?id=1")
    // }
    if (id === 0) {
      history.push("/company/company/list")
    } else {
      history.push(`/company/company/list?key=${key}`)
    }
    // history.push("/admin/company/list")
  }

  const onNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setNumber(value);
      UserForm.setFieldsValue({ company_phone: value })
    }
  };

  const oncnNumberChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setcnNumber(value);
      UserForm.setFieldsValue({ contact_phone: value })
    }
  };

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      initialValues={initialValuesForm}
      onFinish={onFinish}
    >
      {/* 
      <Form.Item
        label="會員編號"
        name="user_key"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item> */}

      <Form.Item
        label="公司名稱 (中文)"
        name="company_name_zh"
      
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="公司名稱 (英文)"
        name="company_name_en"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        // contact_person_mobile
        label="公司電話"
        name="company_phone"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Tooltip>
          <Input
            value={number}
            onChange={onNumberChange}
            addonBefore={(
              // contactPersonMobilePrefix
              <Form.Item name="company_phone_prefix" noStyle>
                <Select style={{ width: 80 }} >
                  <Option value="+852">+852</Option>
                  <Option value="+86">+86</Option>
                </Select>
              </Form.Item>
            )}

            style={{ width: '100%' }}
          />

        </Tooltip>

      </Form.Item>

      <Form.Item
        label="公司電郵"
        name="company_email"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('address')} (${t('zh')})`}
        name="company_address_zh"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('address')} (${t('en')})`}
        name="company_address_en"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('business_nature')}
        name="business_nature"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('HKBR_No')}
        name="br_no"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('Number_of_employee')}`}
        name="no_employee"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('date_of_establish')}
        name="date_of_establish"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <DatePicker
          format="YYYY-MM-DD"
        />
      </Form.Item>


      <Form.Item
        label={t('rep_first_name_en')}
        name="rep_first_name_en"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('rep_last_name_en')}
        name="rep_last_name_en"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('rep_first_name_zh')}
        name="rep_first_name_zh"

      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('rep_last_name_zh')}
        name="rep_last_name_zh"
      >
        <Input />
      </Form.Item>


      <Form.Item
        label={`${t('TitleOfRepentative')} (${t('en')})`}
        name="rep_title_en"
        rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('TitleOfRepentative')} (${t('zh')})`}
        name="rep_title_zh"
        // rules={[{ required: true, message: t("must") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('contact_person_first_name_en')}`}
        name="contact_first_name_en"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('contact_person_last_name_en')}`}
        name="contact_last_name_en"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Input />
      </Form.Item>




      <Form.Item
        label={t('contact_first_name_zh')}
        name="contact_first_name_zh"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('contact_last_name_zh')}
        name="contact_last_name_zh"
      >
        <Input />
      </Form.Item>



      <Form.Item
        // contact_person_mobile
        label={t('Tel_Contact_Person')}
        name="contact_phone"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Tooltip>
          <Input
            value={cn_number}
            onChange={oncnNumberChange}
            addonBefore={(
              // contactPersonMobilePrefix
              <Form.Item name="contact_phone_prefix" noStyle>
                <Select style={{ width: 80 }}>
                  <Option value="+852">+852</Option>
                  <Option value="+86">+86</Option>
                </Select>
              </Form.Item>
            )}
            style={{ width: '100%' }}
          />

        </Tooltip>

      </Form.Item>

      <Form.Item
        label="語言選擇"
        name="preferred_language"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t('selectLabel')}

        >
          <Option value={"tc"}>繁體</Option>
          <Option value={"sc"}>简体</Option>
          <Option value={"en"}>English</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t("notice_two")}
        name="receive_news_info"
        rules={[{ required: true, message: t("must") }]}
      >
        <Radio.Group onChange={(value) => {
          setIsReceive(value.target.value)
        }}
        >
          <Radio value={1}>{t("receive")}</Radio>
          <Radio value={0}>{t("not_receive")}</Radio>
        </Radio.Group>
      </Form.Item>

      {isReceive === 1 ? (
        <Form.Item
          name="receive_news_info_option_source"
        >
          <Checkbox.Group >
            <Checkbox value="1">Whatsapp</Checkbox>
            <Checkbox value="2">{t("email")}</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      )
        : null
      }

      <Form.Item
        label={t("notice_one")}
        name="receive_market_info"
        rules={[{ required: true, message: t("must") }]}
      >
        <Radio.Group onChange={(value) => {
          setIsReceiveNotice2(value.target.value)
        }}>
          <Radio value={1}>{t("receive")}</Radio>
          <Radio value={0}>{t("not_receive")}</Radio>
        </Radio.Group>
      </Form.Item>

      {isReceiveNotice2 === 1 ? (
        <Form.Item
          name="receive_market_info_option_source"
        >
          <Checkbox.Group >
            <Checkbox value="1">Whatsapp</Checkbox>
            <Checkbox value="2">{t("email")}</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      )
        : null
      }

      <Form.Item
        label={t("source")}
        name="source_info"
      >
        <Checkbox.Group>
          <Checkbox value="1">{t("facebook")}</Checkbox>
          <Checkbox value="2">{t("instagrtam")}</Checkbox>
          <Checkbox value="3">{t("friend")}</Checkbox>
          <Checkbox value="4">{t("Jewelry")}</Checkbox>
          <Checkbox value="5">{t("Jewelry_web")}</Checkbox>
          <Checkbox value="6" onChange={openOtherField}>{t("other")}</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      {isOtherField ? (
        <Form.Item
          name="source_other"
          rules={[{ required: true, message: t("must") }]}
        >
          <Input
            placeholder={t("fill_in")}
          />
        </Form.Item>
      )
        : null
      }

      <Form.Item
        label="同意條款"
        name="agree_tnc"
        rules={[{ required: true, message: t("must") }]}
      >
        <Radio.Group>
          <Radio value={1}>是</Radio>
          <Radio value={0}>否</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="備註"
        name="remark"
      >
        <TextArea rows={4} />
      </Form.Item>

      <p>{creatTime}</p>
      <p>{updateTime}</p>
      
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyForm;
