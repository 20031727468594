import React, { useEffect } from 'react';
import {
  ExclamationCircleOutlined
} from '@ant-design/icons';
import {
  Button, Tabs, message, Modal
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import NavAndSideFrame from '../../components/NavAndSideFrame'

const { TabPane } = Tabs;
const { confirm } = Modal;

const selectedKey = 3;

const AdminResetPassword = () => {
  const { t } = useTranslation()
  const app = useSelector((state) => state.app);

  useEffect(() => {
    // setLoading(false)
  }, []);

  const onReset = async () => {
    window.location = `${app.config.API_SERVER_URL}/api/export/user_account/excel`;
  }

  function showConfirm() {
    confirm({
      title: '確定要重置所有會員密碼？',
      icon: <ExclamationCircleOutlined />,
      content: '這是不可逆操作，一旦確定將無法返回。',
      okText: '確定',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        message.success(t('success'));
        onReset();
      },
    });
  }

  return (
    <NavAndSideFrame title={t('setting')} selectedKey={selectedKey}>
      <Button
        type="danger"
        onClick={showConfirm}
        size="large"
      >
        Reset Password
      </Button>
    </NavAndSideFrame>
  )
}

export default AdminResetPassword
