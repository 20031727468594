import React, { Component } from 'react';
import {
  Layout, Menu, Avatar
} from 'antd';
import 'antd/dist/antd.css';
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux';
import { Link } from "react-router-dom";
import { ActionCreators } from '../redux/actions';
import logo from '../assets/Logo_White.png';
import LanguageSelector from './LanguageSelector';

const { Header } = Layout;

class Navbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      redirectHome: false,
    };
  }

  redirectHome() {
    this.setState({redirectHome: true})
  }

  render() {
    const {
      admin,
      company_admin,
      company_user
    } = this.props.app;

    // console.log(`user >> `, user);
    let nickname = '';
    if (company_user.company_user_id != null) {
      nickname = company_user.nickname;
    }

    if (company_admin.company_admin_id != null) {
      nickname = company_admin.nickname;
    }

    if (admin.admin_id != null) {
      nickname = admin.nickname;
    }

    return (
      <Header className="header" style={{background: 'linear-gradient(to right,#c67660 0,#c19e44 100%)'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Link
            to={{
              pathname: "/admin/home",
            }}
          >
            <img style={{height: 50}} src={logo} alt="" />
          </Link>
          <div>
            {/* <LanguageSelector style={{marginRight: '10px'}} /> */}
            <Avatar style={{margin: '0 10px'}}>{nickname ? nickname.charAt(0) : ''}</Avatar>
            {nickname}
          </div>
        </div>
      </Header>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
