import React, { useState, useEffect } from 'react';
import { LockOutlined, MailOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Descriptions, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAuth, setCompanyAdmin, setCompanyUser } from '../../redux/actions/common'

import logo from '../../assets/Logo_White.png';
import zh_individual from '../../assets/individual.png';
import zh_student from '../../assets/student.png';
import en_individual from '../../assets/EN_personal.png';
import en_student from '../../assets/EN_student.png';
import sc_individual from '../../assets/SC_personal.png';
import sc_student from '../../assets/SC_student.png';
import { MemberFrame } from '../../components/MemberFrame'
import LanguageSelector from '../../components/LanguageSelector';

const { Option } = Select;
// const [whatLang, setwhatLang] = useState('');

const RegNotice = () => {
  const { t, i18n } = useTranslation();
  const [whatLang, setwhatLang] = useState('');
  const history = useHistory();

  let type = 2;
  // console.log(i18n.language);
  if (i18n.language == "en") {
    type = 0;
  }
  if (i18n.language == "zh_hans") {
    type = 1;
  }
  if (i18n.language == "zh") {
    type = 2;
  }
  function setSelectedItem(optionType) {
    //optionType is which user such as student or personal
    // window.location.href = "/member/terms?optionType=" + optionType

    history.push(`/member/terms?optionType=${optionType}`)

  }

  const [openModal, setOpenModal] = useState(false);
  // const [selectedItem, setSelectedItem] = useState("");
  const renderModal = () => {

    let content = (
      <p>

      </p>
    )

    // return (
    //   <Modal
    //     width="90%"
    //     visible={openModal}
    //     footer={null}
    //     onCancel={() => setOpenModal(false)}
    //   >
    //     <h2>入會申請須知</h2>
    //     <p>{content}</p>
    //     <div style={{ textAlign: "right" }}>
    //       <Link
    //         to={{
    //           pathname: "/member/form",
    //           query: { id: selectedItem }
    //         }}
    //       >
    //         <Button
    //           type="primary"
    //           style={{ background: "#D1A42B", borderColor: "#D1A42B" }}
    //         >
    //           確認並繼續申請
    //         </Button>
    //       </Link>
    //     </div>
    //   </Modal>
    // )
  }

  return (
    <MemberFrame>
      <Row justify="space-between" align="middle" gutter={[48, 24]}>
        <Col xs={24} sm={24} md={4} />
        <Col xs={24} sm={24} md={16} style={{ textAlign: 'center' }}>
          <img
            alt="logo"
            src={logo}
            style={{
              width: '100%',
              maxWidth: '400px',
              padding: '20px'
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={4} style={{ textAlign: 'center' }}>
          <LanguageSelector
            size="large"
            style={{ padding: '20px 0' }}
          />
        </Col>
        <Col xs={0} sm={0} md={24} style={{ height: '80px' }} />
        <Col xs={24} sm={24} md={12}
          style={{ textAlign: 'right' }}
          //setOpenModal
          onClick={() => { setSelectedItem(1); }}
        >

          {type === 2 ? (
            <img
              alt="logo"
              src={zh_individual}
              style={{
                width: '100%',
                maxWidth: '400px',
                // padding: '20px',
              }}
            />
          )
            : null }
          {type === 0 ? (
            <img
              alt="logo"
              src={en_individual}
              style={{
                width: '100%',
                maxWidth: '400px',
                // padding: '20px',
              }}
            />
          )
            : null}
          {type === 1 ? (
            <img
              alt="logo"
              src={sc_individual}
              style={{
                width: '100%',
                maxWidth: '400px',
                // padding: '20px',
              }}
            />
          )
            : null
          }




        </Col>
        <Col
          xs={24} sm={24} md={12}
          style={{ textAlign: 'left' }}
          // setOpenModal
          onClick={() => { setSelectedItem(2); }}
        >

          {type == 2 ?
            <img
              alt="logo"
              src={zh_student}
              style={{
                width: '100%',
                maxWidth: '400px',
                // padding: '20px',
              }}
            />
            : null
          }
          {type == 0 ?
            <img
              alt="logo"
              src={en_student}
              style={{
                width: '100%',
                maxWidth: '400px',
                // padding: '20px',
              }}
            />
            : null
          }
          {type == 1 ?
            <img
              alt="logo"
              src={sc_student}
              style={{
                width: '100%',
                maxWidth: '400px',
                // padding: '20px',
              }}
            />
            : null
          }
          {/* <img
            alt="logo"
            src={zh_student}
            style={{
              width: '100%',
              maxWidth: '400px',

              // padding: '20px'
            }}
          /> */}
        </Col>
      </Row>
      {renderModal()}
    </MemberFrame>
  )
}

export default RegNotice;