import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, message, notification, Input, Space
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../../../core/Service';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import BookingInfoModal from '../../../components/BookingInfoModal';


const BookingListTable = (props) => {
  const tableIDName = "user_id";
  const { t } = useTranslation()
  const { callBack } = props
  const [dataList, setDataList] = useState(props.dataObj);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRecord2, setSelectedRecord2] = useState(0);
  // console.log('selectedRecord2 cp1', selectedRecord2)
  let searchInput
  const [modalVisible, setModalVisible] = useState(false);
  const { defaultActiveKey, dataObj } = props

  // const { defaultActiveKey, modalVisible, setModalVisible, dataObj } = props
  // const { defaultActiveKey } = props
  //defaultActiveKey


  useEffect(() => {
    setDataList(props.dataObj)

  }, [props.dataObj]);

  const SucessfulNotificationWithIcon = type => {
    notification[type]({
      message: 'Notification Title',
      description:
        'added',
    });
  };


  const putSigned = async (booking_id,user_id) => {
    let postObj = {
      booking_id,
      user_id,
      status: 8
    }
  
    let response = await Service.call('patch', '/api/booking_item/signed', postObj)
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
    callBack()
  }

  const putEmail = async (user_id) => {



    let postObj = {
      user_id
    }
    let response = await Service.call('post', '/api/user/email/confirm_member?type=individual', postObj)


    //call back function getAllData

    callBack()
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
    // callBack()
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };




  const handleFormSubmit = async (submitRecord) => {

    // setLoading(true)
    let response
    let to = submitRecord.email
    // console.log('submitRecord>>>', submitRecord.user_id)


    let patchData = {
      status: submitRecord.status,
      user_id: submitRecord.user_id || 'Guest',
      booking_id: submitRecord.booking_id,
      remarks: submitRecord.remarks
    }

    // console.log('patchData>>>', patchData)
    if (patchData && patchData.status === 1) {
      response = await Service.call('patch', '/api/booking_item/confirm', patchData)
      let userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

      // let emailResult = await Service.call('post', '/api/email/template', {
      //   type: "booking_confirmation",
      //   // to: "kevinchoi012580@gmail.com",
      //   subject: "Booking Confirmation",

      //   data: {
      //     site_logo: "",
      //     booking_id: submitRecord.booking_id,
      //     user_id: submitRecord.user_id,
      //     booking_items: submitRecord,
      //   }
      // });
      // console.log('template send', emailResult);
    }
    if (patchData && patchData.status === 10) {
      response = await Service.call('patch', '/api/booking_item/cancel', patchData)
    }







    // console.log("submitRecord", submitRecord)
    if (submitRecord.user_option === 'user') {
      // let patchData = {
      //   status: submitRecord.status,
      //   user_id: submitRecord.user_id || 'Guest',
      //   booking_id: submitRecord.booking_id
      // }
      // if (patchData && patchData.status === 1) {
      //   response = await Service.call('patch', '/api/booking_item/confirm', patchData)

      //   let userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

      //   let emailResult = await Service.call('post', '/api/email/template', {
      //     type: "booking_confirmation",
      //     // to: "kevinchoi012580@gmail.com",
      //     subject: "Booking Confirmation",

      //     data: {
      //       site_logo: "",
      //       booking_id: submitRecord.booking_id,
      //       user_id: submitRecord.user_id,
      //       booking_items: submitRecord,
      //     }
      //   });
      //   console.log('template send', emailResult);
      // }
      // if (patchData && patchData.status === 10) {
      //   response = await Service.call('patch', '/api/booking_item/cancel', patchData)
      // }
    } else {
      // let patchData = {
      //   status: submitRecord.status,
      //   email: submitRecord.email,
      //   mobile: submitRecord.mobile,
      //   first_name: submitRecord.first_name,
      //   last_name: submitRecord.last_name,
      //   nickname: submitRecord.nickname,
      //   remarks: submitRecord.remarks,
      //   booking_id: submitRecord.booking_id
      // }

      // if (patchData && patchData.status === 1) {
      //   response = await Service.call('patch', '/api/booking_item//guest/confirm', patchData)
      // }
      // if (patchData && patchData.status === 10) {
      //   console.log("test")
      //   // response = await Service.call('patch', '/api/booking_item/guest/cancel', patchData)
      // }
      // console.log("to>>",to)
      // let emailResult = await Service.call('post', '/api/email/template', {
      //   type: "booking_confirmation",
      //   to: to,
      //   subject: "Booking Confirmation",

      //   data: {
      //     site_logo: "",
      //     booking_id: submitRecord.booking_id,
      //     user_id: submitRecord.user_id,
      //     booking_items: submitRecord,
      //   }
      // });
      // console.log('template send', emailResult);
    }

    // console.log('response>>>', response)
    if (response.status && response.errorCode === -80101) {
      notification.error({
        message: t('insufficient_quota'),
      });
      return
    }
    if (response.status && response.status < 1) {
      notification.error({
        message: t('fail'),
      });
      return
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t('success'),
      });
      callBack()
      setModalVisible(false)
    }
  }
  

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000000' }} />,
    onFilter: (value, record) => {

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  const setTableHeader = () => {

    const columns = [
      {
        title: '操作',
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              {/* <Tooltip title="修改">
                <Link
                  to={{
                    pathname: "/company/member/details/",
                    search: `?id=${value}&key=${defaultActiveKey}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 8 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip> */}

              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8 }}
                  icon={<EditOutlined />}
                  // disabled={record.expired === 1 ? true : false}
                  onClick={() => {
                 


                    setSelectedRecord2(record)
                    setModalVisible(true);
                    
                    // callBack()
                
                    // console.log("record",record)

                    // setEditOption(true)
                    // EditOption = true
                  }}
                // disabled={!isAdmin}
                />
              </Tooltip>

              <Tooltip title="簽到">
                <Popconfirm
                  title="確認簽到?"
                  // onConfirm={() => console.log("value",record)}
                  onConfirm={() => putSigned(record.booking_id,record.user_id)}
                  okText="是"
                  cancelText="否"
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 8 }}
                    icon={<EnvironmentOutlined />}
                    href="#"

                  />
                </Popconfirm>
              </Tooltip>


            </span>
          )
        }
      },

      // {
      //   title: t('booking_id'),
      //   dataIndex: 'booking_id',
      //   sorter: (a, b) => a.user_key.localeCompare(b.user_key),
      //   // sorter: (a, b) => a.user_key - b.user_key,
      //   // ...getColumnSearchProps('item_name'),

      // },
      {
        title: t('user_key'),
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key.localeCompare(b.user_key),
        ...getColumnSearchProps('user_key')
        // sorter: (a, b) => a.user_key - b.user_key,
        // ...getColumnSearchProps('item_name'),

      },
      {
        title: '會員類別',
        dataIndex: 'type',
        render: (value) => displayType(value),
        sorter: (a, b) => a.type - b.type
      },
      {
        title: t('會員資料'),
        dataIndex: 'username',
        sorter: (a, b) => a.username.localeCompare(b.username),
        ...getColumnSearchProps('username')
        // sorter: (a, b) => a.user_key - b.user_key,
        // ...getColumnSearchProps('item_name'),

      },

      {
        title: t('category_name'),
        dataIndex: 'item_name',
        sorter: (a, b) => a.item_name.localeCompare(b.item_name),
        // sorter: (a, b) => a.user_key - b.user_key,
        // ...getColumnSearchProps('item_name'),

      },
      {
        title: t('Tel_Contact_Person'),
        dataIndex: 'mobile',
        sorter: (a, b) => a.mobile.localeCompare(b.mobile),
        // sorter: (a, b) => a.user_key - b.user_key,
        // ...getColumnSearchProps('item_name'),

      },
      

      {
        title: '開始時間',
        dataIndex: 'start_time',
        render: (date) => {
          return moment.unix(date).format('YYYY-MM-DD')
        },
        // render: (value) => displayEmailStatus(value),
        sorter: (a, b) => a.start_time - b.start_time
      },
      // {
      //   title: '預約完結時間',
      //   dataIndex: 'end_time',
      //   render: (date) => {
      //     return moment.unix(date).format('YYYY-MM-DD')
      //   },
      //   // render: (value) => displayEmailStatus(value),
      //  sorter: (a, b) => a.end_time - b.end_time
      // },
      // {
      //   title: '分段時間',
      //   dataIndex: 'section_duration',
      //   render: (date) => {
      //     return moment.unix(date).format('YYYY-MM-DD HH:mm')
      //   },
      //   sorter: (a, b) => a.section_duration - b.section_duration
      // },

      {
        title: '備註',
        dataIndex: 'remarks',
        sorter: (a, b) => a.remarks.localeCompare(b.remarks)
      },
     

      {
        title: '確認狀態',
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },


    ];
    return columns;
  }



  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "使用中 Active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "停用 Inactive";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = ""
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);


    switch (statusValue) {
      case 10:
        displayStr = "已預約";
        tagColor = '#5cc461';
        break;
      case 8:
        displayStr = "已簽到";
        tagColor = '#5099a3';
        break;
      case 1:
        displayStr = "可預約";
        tagColor = '#5e5e5d';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#FF1A53';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayEmailStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已發送";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未發送";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <div>
      <Layout>
        <Table
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
          size="small"
          pagination={{ pageSize: 100 }}
        />
      </Layout>

      <BookingInfoModal
        formType={1}

        dataObj={selectedRecord2}

        modalVisible={modalVisible}
        setModalVisible={
          (value) => {

            setModalVisible(value)
            callBack()
            // getAllData()
          }
        }
        // setModalVisible={setModalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />


    </div>


  )
}

export default BookingListTable;