import React, { Component, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, Divider, notification } from 'antd';
import FormUploadFile from './FormUploadFile';

const debug = require('debug')('app:component/form/upload_section');

function UploadSection(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    debug(info);
    // return;
    console.log('info>>>', info)
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  return (
    <div>
      <FormUploadFile
        type="button"
        imageURL={null}
        data={{
          scope: 'public',
          // webcast_event_id
        }}
        onChange={onChange}
        buttonText="上傳"
      />

    </div>

  );
}

export default UploadSection
