import React, { useState, useEffect } from "react";
import _, { set } from "lodash";
import {
  Space,
  Row,
  Col,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  Checkbox,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
  Button,
  Spin,
} from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "querystring";
import * as Main from "../core/Main";
import * as Service from '../core/Service';
import TextArea from "antd/lib/input/TextArea";
import FormUploadFile from './form/FormUploadFile';
import { T } from "antd/lib/upload/utils";

const PostsFormModal = (props) => {
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    callBack
  } = props;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [imageURL, setImageURL] = useState("");
  const [filepath, setFilePath] = useState("");
  const [inserting, setInserting] = useState(false);
  const [PostsForm] = Form.useForm();
  
  const STATIC_SERVER_URL = useSelector(
    (state) => state.app.config.STATIC_SERVER_URL
    );
    
    useEffect(() => {
    if (selectedRecord) {
      if (selectedRecord.post_date > 0) {
        selectedRecord.postDate = moment.unix(selectedRecord.post_date);
      }
      if (selectedRecord.post_cover_image) {
        setImageURL(`${STATIC_SERVER_URL}/${selectedRecord.post_cover_image}`);
        setFilePath(selectedRecord.post_cover_image);
      } else {
        setImageURL("");
      }
      
      if (selectedRecord.on_landing === 1) {
        selectedRecord.onLanding = true;
      }
      // selectedRecord.onLanding = selectedRecord.on_landing === 1
      setInitialFormValues(selectedRecord);
    }
    PostsForm.resetFields();
  },[selectedRecord]);

  const uploadOnChange = (info) => {
    const { status, response } = info.file;
    if (status === "done") {
      message.destroy();
      if (response.status > 0) {
        message.success(t("uploaded"));
        let data = {
          filename: response.filename,
          filepath: response.filepath,
          url: response.url
        };

        setImageURL(response.url);
        setFilePath(data.filepath);
      } else {
        message.error(t("fail"));
      }
    }
  };

  const onFinish = async (formData) => {
    if (inserting) return;
    setInserting(true);
    let endpoint = '/api/posts';
    let post_id = selectedRecord.post_id;

    let params = formData;
    if (filepath == ''){
      setInserting(false);
      return message.error(t('image_blank_failure'));
    }
    params.post_cover_image = filepath;
    params.post_date = formData.postDate.unix();
    params.on_landing = formData.onLanding ? 1 : 0;
    params.post_type = "news"
    // params.post_cover_image = imageURL;
    delete params.postDate;

    if (post_id > 0) params.post_id = post_id;
    console.log("post_idpost_id",post_id)

  
    console.log(params);
    let resp = await Service.call(post_id > 0 ? 'POST' : 'PUT', endpoint, params);

    console.log(resp);
    if (!resp || resp.status < 1) {
      message.error(t('fail'));
      return;
    }
    setInserting(false);
    setFilePath("")
    message.success(t('modal_success'));
    callBack()
    setModalVisible(false);
  };

  return (
    <Modal
      destroyOnClose
      title="Posts Form"
      visible={modalVisible}
      footer={null}
      width={1280}
      style={{maxWidth: "95%"}}
      onCancel={() => {
        setFilePath("");
        setImageURL("");
        setModalVisible(false);
      }}
    >
      <Form
        form={PostsForm}
        name={t("posts_form")}
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={onFinish}
      >
        <Form.Item
          label={t("title") + "(EN)"}
          name="post_title"
          rules={[{required: true, message: t('input_required')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("title") + "(TC)"}
          name="post_title_tc"
          rules={[{required: true, message: t('input_required')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("title") + "(SC)"}
          name="post_title_sc"
          rules={[{required: true, message: t('input_required')}]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <>
              <span
                style={{
                  display: "inline-block",
                  marginRight: 4,
                  color: "#ff4d4f",
                  fontSize: 14,
                  fontFamily: "SimSun, sans-serif",
                  lineHeight: 1,
                }}
              >
                *
              </span>
              {t("image")}
            </>
          }
          name="post_cover_image"
          // rules={[{required: true, message: t('upload_required')}]}
        >
          <FormUploadFile
            data={{ scope: "private" }}
            onChange={uploadOnChange}
            imageURL={imageURL}
          />
        </Form.Item>
        <Form.Item
          label={t("excerpt") + "(EN)"}
          name="post_excerpt"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("excerpt") + "(TC)"}
          name="post_excerpt_tc"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("excerpt") + "(SC)"}
          name="post_excerpt_sc"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("content") + "(EN)"}
          name="post_content"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("content") + "(TC)"}
          name="post_content_tc"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label={t("content") + "(SC)"}
          name="post_content_sc"
          rules={[{required: true, message: t('input_required')}]}
        >
          <TextArea />
        </Form.Item>
        {/* <Form.Item
          label={t("sorting")}
          name="sorting"
          rules={[{required: true, message: t('input_required')}]}
        >
          <InputNumber
            min={1}
            defaultValue="1"
            formatter={(value) => _.floor(value)}
            parser={(value) => _.floor(value)}
          />
        </Form.Item> */}
        <Form.Item
          label={t("post_date")}
          name="postDate"
          rules={[{required: true, message: t('input_required')}]}
        >
          <DatePicker
            showTime
          />
        </Form.Item>

        <Form.Item
          label={t("hyperlink")}
          name="hyperlink"
        >
          <Input />
        </Form.Item>
        {
        // <Form.Item
        //   label={t("keywords")}
        //   name="keywords"
        //   rules={[{required: true, message: t('input_required')}]}
        // >
        //   <Input />
        // </Form.Item>
        // <Form.Item name="onLanding" valuePropName="checked">
        //   <Checkbox>{t('on_landing')}</Checkbox>
        // </Form.Item>
        }
        {/* <Form.Item
          // label={t("onLanding")}
          name="onLanding"
          rules={[{required: true, message: t('select_required')}]}
        >
          <Checkbox
            onChange={(e) => { selectedRecord.on_landing = e.target.checked ? 1 : 0; }}>
            {t('onLanding')}
          </Checkbox>
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PostsFormModal;
