import React, { Component, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, Divider, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import 'react-quill/dist/quill.snow.css';
import FormUploadFile from './FormUploadFile'
import UploadSectionPublic from './UploadSectionPublic'

const debug = require('debug')('app:component/form/file_edit_form');

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const FileEditForm = (props) => {
  const { t } = useTranslation()
  const history = useHistory();
  const app = useSelector((state) => state.app);
  const [dataSource, setDataSource] = useState([]);
  const [contentValue, setContentValue] = useState('');
  const [postImageURL, setPostImageURL] = useState(null);

  const [form] = Form.useForm();
  let query = useQuery()
  let site_meta_id = query.get("site_meta_id")

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //   form.resetFields()
  // }, [dataSource])

  const fetchData = async() => {
    const result = await Service.call('get', `/api/admin/meta/id?site_meta_id=${site_meta_id}`)
    let [siteMeta] = result.meta
    let {
      pathname,
      meta_value,
      meta_key,
    } = siteMeta
    setDataSource(siteMeta);
    form.setFieldsValue({pathname, meta_key})
    setContentValue(_.toString(meta_value))

    // setPostImageURL(`${app.config.STATIC_SERVER_URL}/${meta_value}`);
    console.log('app>>>', app)
  }

  const uploadOnChange = (info) => {
    // debug(`info >> `, info);
    // console.log('props', props);
    // const {t, i18n} = useTranslation('common');
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success('Uploaded file');
        let data = {
          filename: response.filename,
          filepath: response.filepath,
          url: response.url,
        };
        // setPostBannerImage(data.filepath);
        // setPostImageURL(data.url);
      } else {
        message.error(t('common:Failed'))
      }
    }
  };

  const handleFormSubmit = (formData) => {
    let postData = formData
    postData.site_meta_id = site_meta_id
    postData.meta_value = contentValue
    let response = Service.call('patch', `/api/admin/meta`, postData);

    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return;
    }
    notification.success({
      message: t("success"),
    });
    history.push("/company/setting")
  }

  return (
    <Form
      style={{ width: '100%' }}
      layout="vertical"
      form={form}
      onFinish={handleFormSubmit}
      initialValues={dataSource}
    >
      <Form.Item
        label="相關"
        name="meta_key"
      >
        <p>{dataSource.meta_key}</p>
      </Form.Item>
      <Form.Item
        label="路徑"
        name="pathname"
      >
        <p>{dataSource.pathname}</p>
      </Form.Item>
      <Form.Item
        label="內容"
        name="meta_value"
      >
        <p>{contentValue}</p>
        <Button
          onClick={() => { window.location = `${app.config.API_SERVER_URL}/media/${contentValue}` }}
          style={{marginRight: '10px'}}
        >
          下載
        </Button>
        <UploadSectionPublic
          onSuccess={(resp) => {
            const {
              originalname,
              filename,
              filepath,
              url,
            } = resp;
            setContentValue(filename)
            console.log(resp)
            // this.importUserCSV(webcast_event_id, filepath);
          }}
        />
      </Form.Item>



      {/* <FormUploadFile
        data={{ scope: "public" }}
        onChange={uploadOnChange}
        // onRemove={onRemove}
        imageURL={postImageURL}
      /> */}
      
      <Divider />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FileEditForm
