import React, { Component, useEffect, useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { setAuth, setAdmin } from '../../redux/actions/common'
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from '../../core/Service';
import logo from '../../assets/Logo_Black.png';
import _ from 'lodash';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import AdminSettingsTable from './AdminSettingsTable'

const { TabPane } = Tabs;

const selectedKey = 3;

const AdminSettings = () => {
  const { t } = useTranslation()

  useEffect(() => {
    // setLoading(false)
  }, []);

  return (
    <NavAndSideFrame title={t('setting')} selectedKey={selectedKey}>

      <Tabs defaultActiveKey="1">
        <TabPane tab="條款" key="terms">
          <AdminSettingsTable metaName="tnc" t={t} pathname="/member/terms" type="static_page" />
        </TabPane>
        <TabPane tab="PDF" key="pdf">
          <AdminSettingsTable metaName="pdf" t={t} pathname="/member/form" type="file" />
        </TabPane>

        <TabPane tab="成功" key="success">
          <AdminSettingsTable metaName="success" t={t} pathname="/member/success" type="static_page" />
        </TabPane>

      </Tabs>
    </NavAndSideFrame>
  )
}

export default AdminSettings
