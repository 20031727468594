import React, { useState, useEffect } from 'react';
import { LockOutlined, MailOutlined, KeyOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Descriptions, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import * as Service from '../../core/Service';
import logo from '../../assets/Logo_White.png';
import _ from 'lodash';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import LanguageSelector from '../../components/LanguageSelector';
import { MemberFrame } from '../../components/MemberFrame'
import { setAuth, setCompanyAdmin, setCompanyUser } from '../../redux/actions/common'

const { Option } = Select;

const RegForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation()
  const location = useLocation();
  const app = useSelector((state) => state.app);

  const config = useSelector(state => state.app.config)
  const auth = useSelector(state => state.app.auth)
  const [isStudent, setIsStudent] = useState(''); // 1: Personal, 2: Student
  const [isReceive, setIsReceive] = useState('');
  const [isOtherField, setIsOtherField] = useState(false);
  const [isOther, setIsOther] = useState('');
  const [isReceiveNotice2, setIsReceiveNotice2] = useState('');
  const [pdfUrl, setPdfUrl] = useState('')
  const [metaRecord, setMetaRecord] = useState({});
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState([])
  const [MaxBirth, setMaxBirth] = useState('')
  const [MaxBirth18, setMaxBirth18] = useState('')
  const [isBackground, setIsBackground] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const [isNumberValid, setIsNumberValid] = useState(false)
  const [isEmergencyNumberValid, setIsEmergencyNumberValid] = useState(false)
  const [ec_number, setec_number] = useState("");
  const [numberLimit, setnumberLimit] = useState(852);
  const [numberEmergencyLimit, setnumberEmergencyLimit] = useState(852);
  const [number, setNumber] = useState("");
  const [initialValues, setInitialValues] = useState({
    prefixMobile: '+852',
    contactEmergencyMobilePrefix: '+852',
    gender: 'm',
    pick_up_area: 'hong_kong',
    contactPersonMobilePrefix: '+852',
  });
  useEffect(() => {
    if (history.location.query == null) {
      window.location.href = "/register"
    }
    getMeta();
    updateMaxBirth();
    setIsStudent(_.toInteger(history.location.query.id));
  }, [initialValues])

  useEffect(() => {
    getMeta();
  }, [i18n.language])

  // useEffect(() => {
  //   updateMeta();
  // }, [i18n.language]);

  const updateMaxBirth = () => {


    // return current && current > moment(customDate, "YYYY-MM-DD");

    setMaxBirth(moment().subtract(16, 'years').format("YYYY-MM-DD"))
    setMaxBirth18(moment().subtract(18, 'years').format("YYYY-MM-DD"))
  }

  const disabledDate = (current) => {
    let customDate
    if (isStudent === 1) {
      customDate = moment().subtract(18, 'years').format("YYYY-MM-DD");
    } else {
      customDate = moment().subtract(16, 'years').format("YYYY-MM-DD");
    }

    return current && current >= moment(customDate, "YYYY-MM-DD");

  }


  const getMeta = async () => {
    setLoading(true)
    let url = `/api/meta/path?pathname=${location.pathname}`;
    let Response = await Service.call('get', url)

    let metaRecord = Response.meta
    metaRecord = _.keyBy(metaRecord, 'meta_key')
    // console.log('Response>>>', Response)
    if (i18n.language === "zh_hant") {
      setPdfUrl(`${app.config.API_SERVER_URL}/media/${metaRecord.pdf_tc.meta_value}`);
    }
    if (i18n.language === "zh_hans") {
      setPdfUrl(`${app.config.API_SERVER_URL}/media/${metaRecord.pdf_sc.meta_value}`);
    }
    if (i18n.language === "en") {
      setPdfUrl(`${app.config.API_SERVER_URL}/media/${metaRecord.pdf_en.meta_value}`);
    }
    setLoading(false)
  }

  const updateMeta = () => {
    if (!loading) {
      if (i18n.language === "zh_hant") {
        setPdfUrl(`${app.config.API_SERVER_URL}/media/${metaRecord.pdf_tc.meta_value}`);
      }
      if (i18n.language === "zh_hans") {
        setPdfUrl(`${app.config.API_SERVER_URL}/media/${metaRecord.pdf_sc.meta_value}`);
      }
      if (i18n.language === "en") {
        setPdfUrl(`${app.config.API_SERVER_URL}/media/${metaRecord.pdf_en.meta_value}`);
      }
    }
  }

  const openOtherField = e => {

    if (e.target.checked) {
      setIsOtherField(true)
    } else {
      setIsOtherField(false)
    }
  };

  const onFinish = async (formData) => {
    let postData = formData

    postData.ec_tel = '';
    postData.mobile = _.toString(`${postData.contactPersonMobilePrefix}-${postData.contact_person_mobile}`);
    postData.source_info = _.toString(postData.source_info)

    if (postData.contact_Emergency_mobile !== undefined) {
      postData.ec_tel = _.toString(`${postData.contactEmergencyMobilePrefix}-${postData.contact_Emergency_mobile}`);
    }

    postData.type = history.location.query.id
    postData.date_of_birth = moment(postData.date_of_birth).unix()
    let response
    if (!isNumberValid && !isEmergencyNumberValid) {
      response = await Service.call('put', `/api/user/public`, postData);



      if (response.status === -1) {
        if (response.errorCode === -40102) {
          notification.error({
            message: t("email_duplicated"),
          });
          setIsValid(true)
          return;
        }
        if (response.errorCode === -40101) {
          notification.error({
            message: t("mobile_duplicated"),
          });

          setIsValid(true)
          return;
        }
        notification.error({
          message: t("error"),
        });
        return;
      }

      history.push(`/member/success?id=${response.userkey}`)
    }
  }




  const onNumberChange = (e) => {
    const { value } = e.target;
    setIsValid(false)
    onNumberCheck(value, numberLimit)
    //Value = 12345678
    //numberLimit = +852 /+86
  };
  const onNumberCheck = (value, prefix) => {
    if (prefix == 852) {
      if (value.length !== 8 || value.length > 8) {
        setIsNumberValid(true)
      } else {
        setIsNumberValid(false)
      }
    } else {
      setIsNumberValid(false)
    }
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setNumber(value);
      form.setFieldsValue({ contact_person_mobile: value })
    }
  }

  const ec_onNumberCheck = (value, prefix) => {

    if (prefix == 852) {
      if (value.length !== 8 || value.length > 8) {
        setIsEmergencyNumberValid(true)
      } else {
        setIsEmergencyNumberValid(false)
      }
    } else {
      setIsEmergencyNumberValid(false)
    }
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      setec_number(value);
      form.setFieldsValue({ contact_Emergency_mobile: value })
    }
  }


  const ec_onNumberChange = (e) => {
    const { value } = e.target;
    ec_onNumberCheck(value, numberEmergencyLimit)
    // if (numberEmergencyLimit == 852) {
    //   if (value.length !== 8 || value.length > 8) {
    //     setIsEmergencyNumberValid(true)
    //   } else {
    //     setIsEmergencyNumberValid(false)
    //   }
    // } else {
    //   setIsEmergencyNumberValid(false)
    // }
    // const reg = /^-?\d*(\.\d*)?$/;
    // if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {

    //   setec_number(value);
    //   form.setFieldsValue({ contact_Emergency_mobile: value })
    // }
  };

  function handleOnNumberChange(value) {
    setnumberLimit(value)
    if (value == 86) {
      setIsNumberValid(false)
    } else {
      onNumberCheck(form.getFieldsValue().contact_person_mobile, value)
    }
    // console.log(" form.setFieldsValue", form.getFieldsValue().contact_person_mobile)

  }
  function handleOnEmergencyNumberChange(value) {
    setnumberEmergencyLimit(value)
    if (value == 86) {
      setIsEmergencyNumberValid(false)
    } else {
      ec_onNumberCheck(form.getFieldsValue().contact_Emergency_mobile, value)
    }
  }

  const disableValue = (e) => {
    setIsValid(false)
  };



  const displayRegForm = () => {
    return (

      <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
        <Form
          className="login-form col-12 mt-2"
          style={{ width: '100%' }}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          {/* <h3>A. {t('intro')}</h3>
          <Divider /> */}
          <h4>{`${t('name')} ${t('description_name')}`}</h4>



          <Row gutter={[36, 0]} justify="space-between">
            <Col md={12} xs={24}>
              {isValid ? (
                <Form.Item
                  label={t('Last_name_en')}
                  name="last_name"
                  rules={[{ required: true, message: t("must"), max: 60 }]}
                  validateStatus={isValid ? 'error' : ''}
                  help={isValid ? t("name_field_duplicated") : ''}
                  onChange={disableValue}
                >
                  <Input
                  />
                </Form.Item>
              )
                : (<Form.Item
                  label={t('Last_name_en')}
                  name="last_name"
                  rules={[{ required: true, message: t("must"), max: 60 }]}
                  onChange={disableValue}
                >
                  <Input
                  />
                </Form.Item>)
              }
            </Col>
            <Col md={12} xs={24}>
              {isValid ? (
                <Form.Item
                  label={t('first_name_en')}
                  name="first_name"
                  rules={[{ required: true, message: t("must"), max: 60 }]}
                  validateStatus={isValid ? 'error' : ''}
                  help={isValid ? t("name_field_duplicated") : ''}
                  onChange={disableValue}
                >
                  <Input
                  />
                </Form.Item>
              )
                : (<Form.Item
                  label={t('first_name_en')}
                  name="first_name"
                  rules={[{ required: true, message: t("must"), max: 60 }]}
                  onChange={disableValue}
                >
                  <Input
                  />
                </Form.Item>)
              }

            </Col>
          </Row>

          <Row gutter={[35, 0]} justify="space-between">
            <Col md={12} xs={24}>
              <Form.Item
                label={t('Last_name_ch')}
                name="last_name_zh"
              >
                <Input />
              </Form.Item>


            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t('first_name_ch')}
                name="first_name_zh"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={[36, 0]} justify="space-between">
            <Col md={12} xs={24}>
              <Form.Item
                label={t('gender')}
                name="gender"
                rules={[{ required: true, message: t("must"), max: 60 }]}
              >
                <Select>
                  <Option value="m">{t('male')}</Option>
                  <Option value="f">{t('female')}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t('date_of_birth')}
                name="date_of_birth"
                rules={[{ required: true, message: t("must") }]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  defaultPickerValue={isStudent === 1 ? moment(MaxBirth18, 'YYYY-MM-DD') : moment(MaxBirth, 'YYYY-MM-DD')}
                  // disabledDate={d => !d || d.isSameOrBefore(MaxBirth)}
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD"
                  placeholder=""
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[36, 0]} justify="space-between">
            <Col md={12} xs={24}>

              {isNumberValid || isValid ? (
                <Form.Item
                  label={t('moblie')}
                  name="contact_person_mobile"
                  rules={[{ required: true, message: t("must"), max: 60 }]}
                  validateStatus={isNumberValid || isValid ? 'error' : ''}
                  help={(() => {
                    if (isNumberValid) {
                      return (
                        t("moblie_digit")
                      )
                    } else if (isValid) {
                      return (
                        t("mobile_field_duplicated")
                      )
                    }

                    return null;
                  })()}
                >
                  <Tooltip>
                    <Input
                      placeholder="12345678"
                      value={number}
                      onChange={onNumberChange}
                      addonBefore={(
                        <Form.Item name="contactPersonMobilePrefix" noStyle>
                          <Select style={{ width: 80 }} onChange={handleOnNumberChange}>
                            <Option value="+852">+852</Option>
                            <Option value="+86">+86</Option>
                          </Select>
                        </Form.Item>
                      )}
                    />
                  </Tooltip>
                </Form.Item>
              )
                : (
                  <Form.Item
                    label={t('moblie')}
                    name="contact_person_mobile"
                    rules={[{ required: true, message: t("must"), max: 60 }]}
                  >
                    <Tooltip>
                      <Input
                        placeholder="12345678"
                        value={number}
                        onChange={onNumberChange}
                        addonBefore={(
                          <Form.Item name="contactPersonMobilePrefix" noStyle>
                            <Select style={{ width: 80 }} onChange={handleOnNumberChange}>
                              <Option value="+852">+852</Option>
                              <Option value="+86">+86</Option>
                            </Select>
                          </Form.Item>
                        )}
                      />
                    </Tooltip>
                  </Form.Item>
                )
              }
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label={t('email')}
                name="email"
                rules={[{ required: true, message: t("error_message"), type: 'email', max: 60 }]}

              >
                <Input
                  prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="abc@abc.com"
                  help="Email Format"
                />
              </Form.Item>
            </Col>
          </Row>



          <Row gutter={[35, 0]} justify="space-between">
            <Col md={12} xs={24}>
              <Form.Item
                label={`${t('en')} ${t('address')}`}
                name="address"
                // rules={[{ required: true, message: t("must"), max: 60 }]}
              >
                <Input />
              </Form.Item>

            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={`${t('zh')} ${t('address')}`}
                name="address_zh"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>


          <Row gutter={[35, 0]} justify="space-between">
            <Col md={12} xs={24}>

              <Form.Item
                label={t('hkid')}
                name="hkid"
                rules={[{ required: true, message: t("must"), max: 60 }]}
              >
                <Input
                  placeholder="A123456(7)"
                />
              </Form.Item>

            </Col>
            <Col md={12} xs={24}>
              {isStudent === 1 ? (
                <Form.Item
                  label={t('occupation')}
                  name="occupation"
                  // rules={[{ required: true, message: t("must"), max: 60 }]}
                >
                  <Input />
                </Form.Item>
              )
                : null}
              {isStudent === 2 ? (
                <Form.Item
                  label={t('student_no')}
                  name="student_no"
                  rules={[{ required: true, message: t("must"), max: 60 }]}
                >
                  <Input />
                </Form.Item>
              )
                : null}
            </Col>
          </Row>

          {isStudent === 2 ? (
            <Form.Item
              label={t('school_name')}
              name="school_name"
              rules={[{ required: true, message: t("must"), max: 60 }]}
            >
              <Input />
            </Form.Item>
          )
            : null
          }

          <Row gutter={[35, 0]} justify="space-between">
            <Col md={12} xs={24}>
              <Form.Item
                label={t('ec_name_en')}
                name="ec_name_en"
                // rules={[{ required: true, message: t("must"), max: 60 }]}

              >
                <Input

                />
              </Form.Item>


            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t('ec_name_zh')}
                name="ec_name_zh"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[35, 0]} justify="space-between">
            <Col md={12} xs={24}>


              {isEmergencyNumberValid ? (
                <Form.Item
                  label={t('ec_tel')}
                  name="contact_Emergency_mobile"
                  // rules={[{ required: true, message: t("must"), max: 60 }]}
                  validateStatus={isEmergencyNumberValid ? 'error' : ''}
                  help={isEmergencyNumberValid ? t("moblie_digit") : ''}
                >
                  <Tooltip>
                    <Input
                      placeholder="12345678"
                      value={ec_number}
                      onChange={ec_onNumberChange}
                      addonBefore={(
                        <Form.Item name="contactEmergencyMobilePrefix" noStyle>
                          <Select style={{ width: 80 }} onChange={handleOnEmergencyNumberChange}>
                            <Option value="+852">+852</Option>
                            <Option value="+86">+86</Option>
                          </Select>
                        </Form.Item>
                      )}
                      style={{ width: '100%' }}
                    />
                  </Tooltip>
                </Form.Item>
              ) : (
                  <Form.Item
                    label={t('ec_tel')}
                    name="contact_Emergency_mobile"
                    // rules={[{ required: true, message: t("must"), max: 60 }]}
                  >
                    <Tooltip>
                      <Input
                        placeholder="12345678"
                        value={ec_number}
                        onChange={ec_onNumberChange}
                        addonBefore={(
                          <Form.Item name="contactEmergencyMobilePrefix" noStyle>
                            <Select style={{ width: 80 }} onChange={handleOnEmergencyNumberChange}>
                              <Option value="+852">+852</Option>
                              <Option value="+86">+86</Option>
                            </Select>
                          </Form.Item>
                        )}
                        style={{ width: '100%' }}
                      />
                    </Tooltip>
                  </Form.Item>
                )
              }

            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label={t('rwp')}
                name="rwp"
                // rules={[{ required: true, message: t("must"), max: 60 }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={t('preferred_language')}
            name="preferred_language"
            rules={[
              {
                required: true,
                // message: "必須填寫"
                message: t("must")
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={t('selectLabel')}
            // onChange={(value) => {
            //   setIsStudent(value)
            // }}
            >
              <Option value="tc">繁體</Option>
              <Option value="sc">简体</Option>
              <Option value="en">English</Option>
            </Select>
          </Form.Item>



          <h3 style={{ marginTop: '40px' }}>{t("receive_jhub_message")}</h3>

          <Form.Item
            label={t('notice_two')}
            name="receive_news_info"
            style={{ marginBottom: isReceive === 1 ? '5px' : '24px' }}
            rules={[{ required: true, message: t("must") }]}
          >
            <Radio.Group onChange={(value) => {
              setIsReceive(value.target.value)
            }} >

              <Radio value={1}>{t("receive")}</Radio>
              <Radio value={0}>{t("not_receive")}</Radio>
            </Radio.Group>
          </Form.Item>

          {isReceive === 1 ? (
            <Form.Item
              name="receive_news_info_option_source"
              rules={[{ required: true, message: t("must") }]}
            >
              <Checkbox.Group>
                <Checkbox value="1">Whatsapp</Checkbox>
                <Checkbox value="2">{t("email")}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          )
            : null
          }

          <Form.Item
            label={t("notice_one")}
            name="receive_market_info"
            style={{ marginBottom: isReceiveNotice2 === 1 ? '5px' : '24px' }}
            rules={[{ required: true, message: t("must") }]}
          >
            <Radio.Group onChange={(value) => {
              setIsReceiveNotice2(value.target.value)
            }} >
              <Radio value={1}>{t("receive")}</Radio>
              <Radio value={0}>{t("not_receive")}</Radio>
            </Radio.Group>
          </Form.Item>

          {isReceiveNotice2 === 1 ? (
            <Form.Item
              name="receive_market_info_option_source"
              rules={[{ required: true, message: t("must") }]}
            >
              <Checkbox.Group >
                <Checkbox value="1">Whatsapp</Checkbox>
                <Checkbox value="2">{t("email")}</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          )
            : null
          }

          <h3 style={{ marginTop: '40px' }}>{t("source")}</h3>

          <Form.Item
            // label={t("source")}
            name="source_info"
            style={{ marginBottom: _.includes(isOther, 6) ? '5px' : '24px' }}

          >
            <Checkbox.Group onChange={(value) => {
              setIsOther(value)
            }}
            >
              <Checkbox value="1" >{t("facebook")}</Checkbox>
              <Checkbox value="2">{t("instagrtam")}</Checkbox>
              <Checkbox value="3">{t("friend")}</Checkbox>
              <Checkbox value="4">{t("Jewelry")}</Checkbox>
              <Checkbox value="5">{t("Jewelry_web")}</Checkbox>
              <Checkbox value="6" onChange={openOtherField}>{t("other")}</Checkbox>
            </Checkbox.Group>
          </Form.Item>

          {isOtherField ? (
            <Form.Item
              name="source_other"
              rules={[{ required: true, message: t("must") }]}
            >
              <Input
                placeholder={t("fill_in")}
              />
            </Form.Item>
          )
            : null
          }



          <h3 style={{ marginTop: '40px' }}><small style={{ color: '#ff4d4f' }}>*</small> {t("declaration")}</h3>


          <Form.Item
            name="agree_tnc"
            rules={[{ required: true, message: t("must") }]}
          >
            <Checkbox.Group >

              <Checkbox value={1}>{t('declaration_text')}</Checkbox>
            </Checkbox.Group>
          </Form.Item>


          <p>{t('please')}<a href={pdfUrl} target="_blank">{t('click')}</a>{t('to_read_membership_information')}</p>

          <div
            style={{ fontSize: '10px' }}
            dangerouslySetInnerHTML={{ __html: t('privacy_terms') }}
          />

          <Row gutter={[35, 0]} justify="end">
            <Col md={5}>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: '#D1A42B',
                  border: '#D1A42B',
                }}
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t('submit')}
              </Button>
            </Col>
          </Row>

        </Form>
      </div >
    );
  }

  return (
    <MemberFrame>
      <Row justify="space-between" align="middle" gutter={[48, 24]}>
        <Col xs={24} sm={24} md={4} />
        <Col xs={24} sm={24} md={16} style={{ textAlign: 'center' }}>
          <img
            alt="logo"
            src={logo}
            style={{
              width: '100%',
              maxWidth: '400px',
              padding: '50px',

            }}
          />
        </Col>
        <Col xs={24} sm={24} md={4} style={{ textAlign: 'center' }}>
          <LanguageSelector
            size="large"
            style={{ padding: '20px 0' }}
          />
        </Col>
        <Col xs={0} sm={0} md={24} style={{ height: '50px' }} />
        <Col span={24} style={{ backgroundColor: "white", padding: '5%' }}>


          {isStudent === 1 ?
            <h1 style={{ textAlign: 'center', paddingTop: '20px', fontSize: 32 }}>{`${t('application_form')} (${t('member_label')})`}</h1>
            : null}
          {isStudent === 2 ?
            <h1 style={{ textAlign: 'center', paddingTop: '20px', fontSize: 32 }}>{`${t('application_form')} (${t('student_label')})`}</h1>
            : null}
          {displayRegForm()}
        </Col>
      </Row>
    </MemberFrame>
  )
}

export default RegForm;