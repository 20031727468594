import React, { useState, useEffect } from 'react';
import { LockOutlined, MailOutlined, KeyOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Descriptions, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAuth, setCompanyAdmin, setCompanyUser } from '../../redux/actions/common'

import logo from '../../assets/Logo_White.png';
import zh_individual from '../../assets/individual.png';
import zh_student from '../../assets/student.png';
import en_individual from '../../assets/EN_personal.png';
import en_student from '../../assets/EN_student.png';
import sc_individual from '../../assets/SC_personal.png';
import sc_student from '../../assets/SC_student.png';
import { MemberFrame } from '../../components/MemberFrame'
import LanguageSelector from '../../components/LanguageSelector';
import * as Service from '../../core/Service';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import queryString from 'query-string';

const { Option } = Select;
// const [whatLang, setwhatLang] = useState('');

const RegSucessForm = () => {
  const { t, i18n } = useTranslation();
  const [whatLang, setwhatLang] = useState('');
  const [metaRecord, setMetaRecord] = useState({});
  const history = useHistory();
  const [meta, setMeta] = useState([])
  const [loading, setLoading] = useState(true)
  const location = useLocation();
  const { id } = queryString.parse(location.search);


  useEffect(() => {
    getMeta();
  }, []);

  useEffect(() => {
    updateMeta();
  }, [i18n.language]);


  const getMeta = async () => {
    setLoading(true)
    let url = `/api/meta/path?pathname=${location.pathname}`;
    let Response = await Service.call('get', url)
    let metaRecord = Response.meta
    metaRecord = _.keyBy(metaRecord, 'meta_key')
    setMetaRecord(metaRecord)
    if (i18n.language === "zh_hant") {

      setMeta(metaRecord.success_tc.meta_value);
    }
    if (i18n.language === "zh_hans") {
      setMeta(metaRecord.success_sc.meta_value);
    }
    if (i18n.language === "en") {
      setMeta(metaRecord.success_en.meta_value);
    }
    setLoading(false)
  }

  const updateMeta = () => {
    if (!loading) {
      if (i18n.language === "zh_hant") {
        setMeta(metaRecord.success_tc.meta_value);
      }
      if (i18n.language === "zh_hans") {
        setMeta(metaRecord.success_sc.meta_value);
      }
      if (i18n.language === "en") {
        setMeta(metaRecord.success_en.meta_value);
      }
    }
  }

  const displayRegForm = () => {

    return (
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <div>
        <p style={{ textAlign: 'center', paddingTop: '20px', paddingLeft: '100px', paddingRight: '100px', fontSize: 24, color: "black" }}>{t('application_id')} : {id}</p>
          {loading ?
            null :
            // <div dangerouslySetInnerHTML={{ __html: meta }} />
            <ReactQuill
              readOnly
              theme="bubble"
              value={meta}
            />
          }
        
        </div>
        {/* <div style={{ textAlign: 'right' }}>
          <Link
            to={{
              pathname: "/member/form",
              query: { id: optionType }
            }}
          >
            <Button
              type="primary"
              size="large"
              style={{ background: "#D1A42B", borderColor: "#D1A42B", margin: '100px 0 10px' }}
            >
              {t("confirm_and_continue_application")}
            </Button>
          </Link>
        </div> */}
      </Spin>
    );
  }




  const [openModal, setOpenModal] = useState(false);
  // const [selectedItem, setSelectedItem] = useState("");


  return (
    <MemberFrame>
      <Row justify="space-between" align="middle" gutter={[48, 24]}>
        <Col xs={24} sm={24} md={4} />
        <Col xs={24} sm={24} md={16} style={{ textAlign: 'center' }}>
          <img
            alt="logo"
            src={logo}
            style={{
              width: '100%',
              maxWidth: '400px',
              padding: '50px',

            }}
          />
        </Col>
        <Col xs={24} sm={23} md={4} style={{ textAlign: 'center' }}>
          <LanguageSelector
            size="large"
            style={{ padding: '20px 0' }}
          />
        </Col>
        <Col xs={0} sm={0} md={20} style={{ height: '50px' }} />
        <Col span={24} style={{ backgroundColor: "white", width: '10px', height: '250px' }}>
          <div style={{ textAlign: 'center', paddingTop: '20px', paddingLeft: '100px', paddingRight: '100px', fontSize: 24, color: "black" }}>{displayRegForm()}</div>
        </Col>
      </Row>
    </MemberFrame>
  )
}

export default RegSucessForm;