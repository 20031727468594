import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, Space
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
// import MemberListTable from './MemberListTable';

import QuotaListTable from './QuotaListTable';
import FormUploadFile from '../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import UserQuotaModal from './UserQuotaModal';
import { useTranslation } from 'react-i18next';
// import MemberListTableConfirm from './MemberListTableConfirm';
const { TabPane } = Tabs;
const debug = require('debug')('app:admin:client:src:AdvertisementList');


const involvedModelName = "company";
// const title = "會員列表";
const selectedKey = 3;
const tableIDName = "company_user_id";

function UploadSection(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    debug(info);
    // return;
    console.log('info>>>', info)
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
        // webcast_event_id
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

const MemberList = (props) => {
  const app = useSelector((state) => state.app);

  const { t } = useTranslation()
  const title = t('member_quota_table');

  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getTapkey, setgetTapkey] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  // debug(`modalVisible cp1>>>`, modalVisible)
  const [selectedRecord, setSelectedRecord] = useState(1);

  const history = useHistory();
  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key

  useEffect(() => {
    getAllData();
  }, []);

  // debug(`modalVisible cp1>>>`, modalVisible)
  // useEffect(() => {
  //   debug(`modalVisible>>>`, modalVisible)
  // }, [modalVisible]);

  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    let dataList2 = []; //for other status
    let inactive_dataList = [];
    try {
      let url = "/api/user_quota/list";
      let response = await Service.call('get', url);
      dataList = response;


      url = `/api/user/list/1`;
      let userArr = await Service.call('get', url);
      let userArrMap = _.keyBy(userArr, "user_id");





      // url = '/api/company/user';
      // let companyUserArr = await Service.call('get', url);
      // let companyUserArrMap = _.keyBy(companyUserArr["companyUsers"], "company_user_id");

      // console.log("companyUserArrMap",companyUserArrMap)

      url = '/api/item/category/list';
      let categoryArr = await Service.call('get', url);
      let categoryArrMap = _.keyBy(categoryArr, "item_category_id");


      _.each(response, (quotaItem, i) => {
        if (!_.isUndefined(userArrMap[quotaItem.user_id].company_name_en)) {
          dataList[i].username = userArrMap[quotaItem.user_id].company_name_en;
        } else {
          dataList[i].username = userArrMap[quotaItem.user_id].first_name + ' ' + userArrMap[quotaItem.user_id].last_name;
        }
        dataList[i].user_key = userArrMap[quotaItem.user_id].user_key
        dataList[i].type = userArrMap[quotaItem.user_id].type
        // dataList[i].companyUsername = companyUserArrMap[quotaItem.company_user_id].first_name + ' ' + companyUserArrMap[quotaItem.company_user_id].last_name;
        dataList[i].item_category_id = categoryArrMap[quotaItem.item_category_id].item_category_id
        dataList[i].category_name = categoryArrMap[quotaItem.item_category_id].category_name;
      })

      // console.log("dataList", dataList)

    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(dataList);
      // setDataList2(dataList2);
      // setInactive_dataList(inactive_dataList);
      setLoading(false)
    }
  }

  const callback = async () => {
    console.log("call back")
  }

  const importUserCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/user/import', {
      filepath,
    });

    if (!resp || resp.status <= 0) {
      notification.error({
        message: 'User import failed, please contact administrator',
        description: '',
      });
      return;
    }

    // if (resp.statusError === -1) {
    //   notification.error({
    //     message: `User import failed. CSV index ${resp.row}`,
    //     description: `The First Name ${resp.first_name},Last Name ${resp.last_name} and Mobile ${resp.mobile} Duplicated.`,
    //     duration: 0
    //   });
    //   getAllData();
    //   return;
    // }
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }
    notification.success({
      message: `User import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description: description,
      duration: 0
    });

    getAllData();
  }


  const handleTestEmail = async () => {
    await Service.call('post', '/api/user/email/confirm_member');
  }
  const onClickExportExcel = async () => {
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    window.location = `${app.config.API_SERVER_URL}/api/export/user/excel?type=individual`;
  }

  const handleFormSubmit = async (submitRecord) => {
    let { expiry_date } = submitRecord;
    if (expiry_date) submitRecord.expiry_date = moment(expiry_date).unix();
    let submit = await Service.call('patch', '/api/user_quota', submitRecord)
    getAllData()
    setModalVisible(false)

    notification.success({
      message: t("success"),
    });

  }

  // const getTapValue = (activeKey) => {
  //   setgetTapkey(activeKey)

  // };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
    
      {/* <div
      // style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Space size={8}>
          <Link
            to={{
              pathname: "/company/member/details/",
              search: "?id=0",
            }}
          >
            <Button
              type="primary"
            >
              新增會員
          </Button>
          </Link>

          <Button
            type="primary"
            onClick={() => onClickExportExcel()}
          >
            導出紀錄 (Excel)
          </Button>

          <UploadSection
            webcast_event_id="1"
            onSuccess={(resp) => {
              debug(`resp >>SendEmailButton `, resp);
              const {
                originalname,
                filename,
                filepath,
                url,
              } = resp;
              importUserCSV(filepath)
              // this.importUserCSV(webcast_event_id, filepath);
            }}
          />

        </Space>
      </div>
      <Divider /> */}
      {/* <Button
        onClick={() => handleTestEmail()}
      >
        Send Test Email
      </Button> */}
      <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <QuotaListTable
              dataObj={dataList}
              adminChangeData={getAllData}
              defaultActiveKey={1}
              handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              callBack={() => getAllData()}
            />
          </Spin>

      {/* <Tabs defaultActiveKey={defaultActiveKey}
        onChange={(value) => {
          // getTapValue(value)
        }}>
        <TabPane >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <QuotaListTable
              dataObj={dataList}
              adminChangeData={getAllData}
              defaultActiveKey={1}
              handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              callBack={() => getAllData()}
            />
          </Spin>
        </TabPane>
      </Tabs> */}

      {/* <UserQuotaModal
        selectedRecord={selectedRecord}
        categoryList={dataList}
        modalVisible={modalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
        // particularUserView={particularUserView}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getAllData()
          }
        }
      // handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      /> */}
      {/* <MemberListTable
        dataObj={dataList}
        adminChangeData={getAllData}
      /> */}
    </NavAndSideFrame>
  )
}

export default MemberList;