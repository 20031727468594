import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Menu, Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import { ActionCreators } from '../../redux/actions';
import * as Service from '../../core/Service';
import Sidebar from '../../components/Sidebar';
import Navbar from '../../components/Navbar';
import AppLayout from '../../components/AppLayout';
import _ from 'lodash';
import moment from 'moment';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const data = [
  {
    name: '10/7', door1: 4000, door2: 2400,
  },
  {
    name: '11/7', door1: 3000, door2: 1398,
  },
  {
    name: '12/7', door1: 2000, door2: 9800,
  },
  {
    name: '13/7', door1: 2780, door2: 3908,
  },
  {
    name: '14/7', door1: 1890, door2: 4800,
  },
  {
    name: '15/7', door1: 2390, door2: 3800,
  },
  {
    name: '16/7', door1: 3490, door2: 4300,
  },
];

const styles = {
  chartCol: {
    height: "400px",
    textAlign: "center",
    // border: 'solid 1px #ccc',
    padding: '30px 10px 60px',
    borderRadius: '5px',
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)'
  },
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      clientInfo: {}
    };
  }

  async componentDidMount() {
    // await this.getAllData('/api/dashboard/client');
  }

  async getAllData(link) {
    // this.setState({loading: true})
    // let resObj = await Service.call('get', link);
    // let clientInfo = resObj.result;
    // // console.log('clientInfo>>>', clientInfo)
    // this.setState({clientInfo});

    // let currentTime = moment().add(1, 'months')
    // let clientGraphMonth = []
    // let i
    // for (i = 0; i < 6; i++) {
    //   let newTime = currentTime.subtract(1, 'months').unix()
    //   clientGraphMonth.push(newTime)
    //   // console.log('clientGraphMonth>>>', clientGraphMonth)
    // }
    // let clientGraphData = _.map(clientGraphMonth, (month) => {
    //   // console.log('month>>>', month)
    //   let startTime = moment.unix(month).startOf('month').unix()
    //   let endTIme = moment.unix(month).endOf('month').unix()
    //   // console.log('startTime>>>', startTime, 'endTIme>>>', endTIme)
    //   let clientWithinMonth = _.filter(clientInfo, (client) => {
    //     return (
    //       client.client_create_time >= startTime && client.client_create_time <= endTIme
    //     )
    //   })
    //   // console.log('clientWithinMonth>>>', clientWithinMonth)
    //   return (
    //     {
    //       month: moment.unix(month).format('MMM-YY'),
    //       leads: clientWithinMonth.length
    //     }
    //   )
    // })
    // clientGraphData.reverse()
    // // console.log('clientGraphData>>>', clientGraphData)

    // this.setState({clientGraphData})

    // if (!resObj || resObj.status <= 0) {
    //   this.setState({loading: false})
    //   notification.error({
    //     message: 'Fetch Data Fail',
    //     description: '',
    //   });
    //   return;
    // }
    // notification.success({
    //   message: 'Fetch Data Success',
    //   description: '',
    // });
    // this.setState({loading: false})
  }

  render() {
    return (
      <AppLayout title="儀表板">
        <Content
          // style={{
          //   background: '#fff',
          //   padding: 24,
          //   margin: 0,
          //   minHeight: 280
          // }}
        >
          {/* <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={styles.chartCol}>
              <h2> Access Logs </h2>
              <ResponsiveContainer>
                <LineChart
                  data={data}
                  margin={{
                    top: 25, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="door1" stroke="#006ABF" />
                  <Line type="monotone" dataKey="door2" stroke="#FFA31F" />
                </LineChart>
              </ResponsiveContainer>
            </Col>
          </Row> */}
        </Content>
      </AppLayout>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);