import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  Avatar, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Select, notification, Badge, Spin
} from 'antd';

import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
// import CompanyUserQuotaLogListTable from './CompanyUserQuotaLogListTable';
import * as debugLib from 'debug';
import FormUploadFile from '../../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/zh-tw';
import { isUndefined } from 'util';
import BookingInfoModal from '../../../components/BookingInfoModal';

const { Option } = Select;

const localizer = momentLocalizer(moment)

const UserbookingListCalender = (props) => {
  const { t } = useTranslation()
  const involvedModelName = "company";
  const title = "Item Category";
  const selectedKey = 3;
  const tableIDName = "item_category_id";
  const [modalVisible, setModalVisible] = useState(false);
  const [eventObj, setEventObj] = useState([]);
  const [userList, setUserList] = useState([])
  const [bookStatus, setbookStatus] = useState([])
  const app = useSelector((state) => state.app);

  const [dataList, getCalendarData] = useState([]);
  const [eventList, setEventList] = useState([]);
  const {
    serverAction, BookingItem, callBack, setLoading, selectDateCallBack
  } = props
  const formType = 1
  console.log()
  useEffect(() => {
    setEventList(BookingItem)
  }, [BookingItem])

  // console.log('booking_item', props.BookingItem)

  const lookDetail = async (event) => {
    setLoading(true)

    let categoryArr = await Service.call('get', "/api/item/category/list");
    let categoryArrMap = _.keyBy(categoryArr, "item_category_id")
    event.category_name = categoryArrMap[event.item_category_id].category_name
    let userArr = await Service.call('get', `/api/user/${event.user_id}`);
    event.type = userArr.type

    ///api/user/:user_id'
    // let userArr = await Service.call('get', "/api/user/list/1");
    // let userArrMap = []
    // _.forEach(userArr, (user) => {
    //   userArrMap.push(<Option key={user.user_id} value={user.user_id}>{user.first_name + " " + user.last_name}</Option>)
    // })

    // setUserList(userArrMap)

    let getbookStatus = await Service.call('get', "/api/booking_item/status");
    //statusDataList = getbookStatus
    setEventObj(event)

    // console.log("testevent",event)

    //setbookStatus()
    setLoading(false)
    setModalVisible(true)
  }



  const renderBackgroundColor = (status) => {
    let backgroundColor = ""
    switch (status) {
      case 1:
        backgroundColor = "#5e5e5d"
        break;
      case 10:
        backgroundColor = "#5cc461"
        break;
      case 8:
        backgroundColor = "#5099a3"
        break;
      default:
        backgroundColor = "#FF1A53"
    }
    return backgroundColor
  }

  const calendarStyle = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: renderBackgroundColor(event.status),
        borderColor: 'rgba(255, 255, 255, 0.3)'
      }
    }
  }

  const handleFormSubmit = async (submitRecord) => {

    // setLoading(true)
    let response
    let to = submitRecord.email
    // console.log('submitRecord>>>', submitRecord.user_id)


    let patchData = {
      status: submitRecord.status,
      user_id: submitRecord.user_id || 'Guest',
      booking_id: submitRecord.booking_id,
      remarks: submitRecord.remarks
    }

    // console.log('patchData>>>', patchData)
    if (patchData && patchData.status === 1) {
      response = await Service.call('patch', '/api/booking_item/confirm', patchData)
      let userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

      // let emailResult = await Service.call('post', '/api/email/template', {
      //   type: "booking_confirmation",
      //   // to: "kevinchoi012580@gmail.com",
      //   subject: "Booking Confirmation",

      //   data: {
      //     site_logo: "",
      //     booking_id: submitRecord.booking_id,
      //     user_id: submitRecord.user_id,
      //     booking_items: submitRecord,
      //   }
      // });
      // console.log('template send', emailResult);
    }
    if (patchData && patchData.status === 10) {
      response = await Service.call('patch', '/api/booking_item/cancel', patchData)
    }







    // console.log("submitRecord", submitRecord)
    if (submitRecord.user_option === 'user') {
      // let patchData = {
      //   status: submitRecord.status,
      //   user_id: submitRecord.user_id || 'Guest',
      //   booking_id: submitRecord.booking_id
      // }
      // if (patchData && patchData.status === 1) {
      //   response = await Service.call('patch', '/api/booking_item/confirm', patchData)

      //   let userRes = await Service.call('get', `/api/user/${submitRecord.user_id}`);

      //   let emailResult = await Service.call('post', '/api/email/template', {
      //     type: "booking_confirmation",
      //     // to: "kevinchoi012580@gmail.com",
      //     subject: "Booking Confirmation",

      //     data: {
      //       site_logo: "",
      //       booking_id: submitRecord.booking_id,
      //       user_id: submitRecord.user_id,
      //       booking_items: submitRecord,
      //     }
      //   });
      //   console.log('template send', emailResult);
      // }
      // if (patchData && patchData.status === 10) {
      //   response = await Service.call('patch', '/api/booking_item/cancel', patchData)
      // }
    } else {
      // let patchData = {
      //   status: submitRecord.status,
      //   email: submitRecord.email,
      //   mobile: submitRecord.mobile,
      //   first_name: submitRecord.first_name,
      //   last_name: submitRecord.last_name,
      //   nickname: submitRecord.nickname,
      //   remarks: submitRecord.remarks,
      //   booking_id: submitRecord.booking_id
      // }

      // if (patchData && patchData.status === 1) {
      //   response = await Service.call('patch', '/api/booking_item//guest/confirm', patchData)
      // }
      // if (patchData && patchData.status === 10) {
      //   console.log("test")
      //   // response = await Service.call('patch', '/api/booking_item/guest/cancel', patchData)
      // }
      // console.log("to>>",to)
      // let emailResult = await Service.call('post', '/api/email/template', {
      //   type: "booking_confirmation",
      //   to: to,
      //   subject: "Booking Confirmation",

      //   data: {
      //     site_logo: "",
      //     booking_id: submitRecord.booking_id,
      //     user_id: submitRecord.user_id,
      //     booking_items: submitRecord,
      //   }
      // });
      // console.log('template send', emailResult);
    }

    // console.log('response>>>', response)
    if (response.status && response.errorCode === -80101) {
      notification.error({
        message: t('insufficient_quota'),
      });
      return
    }
    if (response.status && response.status < 1) {
      notification.error({
        message: t('fail'),
      });
      return
    }
    if (response.status && response.status > 0) {
      notification.success({
        message: t('success'),
      });
      callBack()
      setModalVisible(false)
    }
  }

  // const selectDate = (prevOrNext,focusWeek) => {

  //   let nextWeek = moment(focusWeek).endOf('week').unix()
  //   let previousWeek = moment(focusWeek).startOf('week').unix()
  //   console.log("previousWeek", nextWeek,previousWeek)
  // }
  



  return (
    <Layout>
      <Calendar
        // selectable
        scrollToTime={new Date(new Date().setHours(9, 1))}
        localizer={localizer}
        events={eventList}
        startAccessor="start"
        endAccessor="end"
       
        style={{
          height: "500px",
          background: "white"
        }}
        
        defaultView={Views.DAY}
        views={['day', 'week','month']}
        messages={{
          day: t('day'),
          month: t('month'),
          week: t('week'),
          today: t('today'),
          next: t('next'),
          previous: t('previous')
        }}
        
        // endAccessor={({ end }) => new Date(end.getTime() - 1)}
        // onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDate(prevOrNext,focusWeek)}
        onNavigate={(focusWeek, flipUnit, prevOrNext) => selectDateCallBack(prevOrNext, focusWeek)}
        // components={(value)=>{console.log('check', value)}}

        // view="week"
        // views={{ 'month': true}}
        onSelectEvent={(event) => lookDetail(event)}
        // onSelectSlot={(slotInfo) => addNewEvent(slotInfo)}
        eventPropGetter={(event) => calendarStyle(event)}
      // for one day change color
      // dayPropGetter={calendarStyle}
      // defaultDate={new Date(2015, 3, 1)}
      />

      <BookingInfoModal
        formType={formType}
        //userObj={userObj}
        userList={userList}
        dataObj={eventObj}
        //eventObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />

      {/* <BookingInfoModal
        formType={3}
        userList={userList}
        dataObj={eventObj}
        modalVisible={modalVisible}
        setModalVisible={
          (value) => {
            setModalVisible(value)
            getCalendarData()
            //getData()
          }
        }
        handleFormSubmit={(submitRecord) => handleFormSubmit(submitRecord)}
      />  */}

    </Layout>
  )
}

export default UserbookingListCalender;