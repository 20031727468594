import React from 'react';
import {
  Button, Form, Input, InputNumber, notification, Select
} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import _ from 'lodash';
import * as Service from '../../core/Service';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 },
  },
};

const AdminAccountForm = (props) => {
  let { recordId: company_user_id, selectedFormData, closeMethod, selectedEditFormData } = props;
  let initialValues = {
    prefix: '+852',
    gender: 'm',
    admin_role: 'super'
  }

  //  Handle mobile number
  let [prefix, mobile] = _.split(selectedFormData.mobile, '-')
  selectedFormData.prefix = prefix;
  selectedFormData.mobile = mobile;

  let combinedInitialValues = { ...initialValues, ...selectedFormData }

  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }}>
        <Option value="+852">+852</Option>
        <Option value="+853">+853</Option>
        <Option value="+86">+86</Option>
      </Select>
    </Form.Item>
  );

  const onFinish = async (values) => {
    let data = {};
    try {
      let reqObj = values;
      let action = 'post';
      let url = '/api/admin/admin_account'

      if (company_user_id !== 0) { //  Edit
        action = 'patch';
        reqObj.company_user_id = company_user_id;
      }

      if (selectedEditFormData) {
        url = '/api/admin/admin_account/password'
      }

      ///api/admin/admin_account/password

      data = await Service.call(action, url, reqObj);

      if (data.errorCode === -40101) {
        notification.error({
          message: "Email Repeated",
        });
        return;
      }

      if (data.status === 1) {
        closeMethod();
        return notification.success({ message: 'Success', description: 'Successful Operation' });
      }

      throw data;
    } catch (error) {
      console.error('error >>> ', error);
      notification.warning({
        message: 'Warning',
        description: 'Unsuccessful Operation'
      });
    } finally {
      // closeMethod();
    }
  };

  return (
    <Form
      labelCol={formItemLayout.labelCol}
      wrapperCol={formItemLayout.wrapperCol}
      onFinish={onFinish}
      form={form}
      initialValues={combinedInitialValues}
    >


      {!selectedEditFormData ? (
        <div>

          <Form.Item label="用戶ID">
            <span className="ant-form-text">{company_user_id}</span>
          </Form.Item>

          <Form.Item
            label="名字"
            name="first_name"
            rules={[{ required: true, message: "請輸入此字段" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="姓氏"
            name="last_name"
            rules={[{ required: true, message: "請輸入此字段" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="用戶名稱"
            name="username"
            rules={[{ required: true, message: "請輸入此字段" }]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
        label="手機號碼"
        name="mobile"
        rules={[
          {
            required: true,
            message: "請輸入此字段"
          },
        ]}
      >
        <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
      </Form.Item> */}
          <Form.Item
            label="電子郵件"
            name="email"
            rules={[
              {
                type: 'email',
                message: '請輸入有效電子郵件的',
              },
              {
                required: true,
                message: '請輸入此字段',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="gender" label="性別" rules={[{ required: true }]}>
            <Select>
              <Option value="m">男</Option>
              <Option value="f">女</Option>
            </Select>
          </Form.Item>

          {/* <Form.Item
        label="管理員角色"
        name="admin_role"
        rules={[
          {
            required: true,
            message: "請輸入此字段",
          },
        ]}
      >
        <Select>
          <Option value="super">Super</Option>
         
        </Select>
      </Form.Item> */}

          <Form.Item
            label="暱稱"
            name="nickname"
          >
            <Input />
          </Form.Item>

          {/* {
        this.state.profile ?             
        <Form.Item
          label={`${t('Original')}${t("Password")}`}
          name="old_password"
          rules={[
            {
              required: true,
              message: `${t('Original')}${"密碼"}${t('common:is_required')}`,
            },
          ]}
        >
          <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
        </Form.Item>
        :
        <></>
      }   */}


        </div>
      )
        : null
      }


      {selectedEditFormData || _.isUndefined(combinedInitialValues.company_user_id) ? (
        <div>
          <Form.Item
            label="電子郵件"
            name="email"
            rules={[
              {
                type: 'email',
                message: '請輸入有效電子郵件的',
              },
              {
                required: true,
                message: '請輸入此字段',
              },
            ]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="密碼"
            name="password"
            rules={[
              {
                required: true,
                message: "密碼必填",
              },
            ]}
          >
            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>

          <Form.Item
            label="確認密碼"
            name="passwordConfirm"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: "確認密碼必填",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("密碼不相同");
                },
              }),
            ]}
          >
            <Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
          </Form.Item>
        </div>
      )
        : null
      }



      <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
        <Button type="primary" htmlType="submit">
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AdminAccountForm;