import React, { Component, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Input, Descriptions, Spin
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../core/Service';
import * as Main from '../../core/Main';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import MemberAccessLogListTable from './MemberAccessLogListTable';
import { useSelector } from 'react-redux';

const debug = require('debug')('app:admin:client:src:MemberAccessLogList');

const involvedModelName = "company";
const title = "到訪紀錄";
const selectedKey = 3;
const tableIDName = "company_user_id";
const { Search } = Input;
let checkNull = 0;
let user_key = 0;


const MemberList = (props) => {
  const app = useSelector((state) => state.app);
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [UserForm] = Form.useForm();
  const [initialValues, setInitialValues] = useState({})
  const [isNotNull, setIsNotNull] = useState('');
  const state = false;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    try {
      let url = "/api/user/accesslog";
      let data = await Service.call('get', url);
      data = _.orderBy(data, ["access_time"], ["desc"])
      dataList = data;

    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(dataList);
      setLoading(false)
    }
  }

  const onClickExportExcel = async () => {
    // let url = "/api/export/access_log/excel";
    // let data = await Service.call('get', url);
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    window.location = `${app.config.API_SERVER_URL}/api/export/access_log/excel`;
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Button
        type="primary"
        onClick={() => onClickExportExcel()}
      >
        導出紀錄 (Excel)
      </Button>
      <Divider />
      <Spin
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <MemberAccessLogListTable
          dataObj={dataList}
          adminChangeData={getAllData}
        />
      </Spin>
    </NavAndSideFrame>
  )
}

export default MemberList;
