import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, notification, Input, Space, Select
} from 'antd';
import {
  EditOutlined,
  EnvironmentOutlined,
  MailOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Service from '../../core/Service';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;
const CompanyListTable = (props) => {
  const tableIDName = "user_id";
  const { t } = useTranslation()
  const [LogInfoForm] = Form.useForm();
  const { callBack } = props
  const [dataList, setDataList] = useState(props.dataObj);
  const [logInfoModalVisible, setLogInfoModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [numberLimit, setnumberLimit] = useState(852);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isNumberValid, setIsNumberValid] = useState(false)
  const [number, setNumber] = useState("");
  const [isValid, setIsValid] = useState(false)
  const { defaultActiveKey } = props
  let searchInput

  const [initialValues, setInitialValues] = useState({
    prefixMobile: '+852',
    contactEmergencyMobilePrefix: '+852',
    gender: 'm',
    pick_up_area: 'hong_kong',
    contactPersonMobilePrefix: '+852',
  });


  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const onFinish = async (formData) => {
    // let postObj = {
    //   user_id,
    //   first_name: "test",
    //   last_name: "test",
    //   email: "test",
    //   mobile: "test",
    // }


    formData.mobile = _.toString(`${formData.contactPersonMobilePrefix}-${formData.mobile}`);
    formData.user_id = selectedRecord
    if (!isNumberValid) {
      let response = await Service.call('put', '/api/user/accesslog/ams?type=company', formData)
      if (response.status === -1) {
        notification.error({
          message: t("error"),
        });
        return
      }
      notification.success({
        message: t("success"),
      });
      setLogInfoModalVisible(false)
    }
  
  }
  const putEmail = async (user_id) => {

    let postObj = {
      user_id
    }
    let response = await Service.call('post', '/api/user/email/confirm_member?type=company', postObj)
    //call back function get all data
    callBack()
    if (response.status === -1) {
      notification.error({
        message: t("error"),
      });
      return
    }
    notification.success({
      message: t("success"),
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#000000' }} />,
    onFilter: (value, record) => {

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });

  function handleOnNumberChange(value) {
    setnumberLimit(value)
    if (value == 86) {
      setIsNumberValid(false)
    } else {
      onNumberCheck(LogInfoForm.getFieldsValue().mobile, value)
    }
    // console.log(" form.setFieldsValue", form.getFieldsValue().contact_person_mobile)

  }
  const onNumberChange = (e) => {
    const { value } = e.target;
    setIsValid(false)
    onNumberCheck(value, numberLimit)

  };

  const onNumberCheck = (value, prefix) => {
    if (prefix == 852) {
      console.log("value", value)
      if (value.length !== 8 || value.length > 8) {
        setIsNumberValid(true)
      } else {
        setIsNumberValid(false)
      }
    } else {
      setIsNumberValid(false)
    }
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: '操作',
        dataIndex: tableIDName,
        render: (value, record, key) => {
          return (
            <span>
              <Tooltip title="修改">
                <Link
                  to={{
                    pathname: "/company/company/details/",
                    search: `?id=${value}&key=${defaultActiveKey}`,
                  }}
                >
                  <Button
                    shape="circle"
                    style={{ marginRight: 8 }}
                    icon={<EditOutlined />}
                  />
                </Link>
              </Tooltip>
              {record.status === 1 && record.is_active === 1 && (
                <Tooltip title="簽到">
                  <Popconfirm
                    title="確認簽到?"
                    onConfirm={() => {
                      setLogInfoModalVisible(true)
                      setSelectedRecord(value)
                    }}
                    okText="是"
                    cancelText="否"
                  >
                    <Button
                      shape="circle"
                      style={{ marginRight: 8 }}
                      icon={<EnvironmentOutlined />}
                      href="#"
                    />
                  </Popconfirm>
                </Tooltip>
              )}
              {record.status === 1 && record.is_active === 1 && (
                <Tooltip title="發送電郵">
                  {/* <Popconfirm
                  title="確認登記?"
                  onConfirm={() => putAccessLog(value)}
                  okText="是"
                  cancelText="否"
                > */}
                  <Button
                    shape="circle"
                    style={{ marginRight: 8 }}
                    icon={<MailOutlined />}
                    onClick={() => putEmail(value)}

                  />
                  {/* </Popconfirm> */}
                </Tooltip>
              )}
            </span>
          )
        }
      },
      {
        title: '會員編號',
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key.localeCompare(b.user_key),
        ...getColumnSearchProps('user_key'),
      },
      {
        title: '公司名稱 (中文)',
        dataIndex: 'company_name_zh',
        sorter: (a, b) => a.company_name_zh.localeCompare(b.company_name_zh),
      },
      {
        title: '公司名稱 (英文)',
        dataIndex: 'company_name_en',
        sorter: (a, b) => a.company_name_en.localeCompare(b.company_name_en),
        ...getColumnSearchProps('company_name_en'),
      },
      // {
      //   title: '公司電話',
      //   dataIndex: 'company_phone',
      //   sorter: (a, b) => a.first_name.localeCompare(b.company_phone)
      // },
      // {
      //   title: '公司電郵',
      //   dataIndex: 'company_email',
      //   sorter: (a, b) => a.last_name.localeCompare(b.company_email)
      // },
      {
        title: '聯絡人名字 (英文)',
        dataIndex: 'contact_first_name_en',
        sorter: (a, b) => a.contact_first_name_en.localeCompare(b.contact_first_name_en)
      },
      {
        title: '聯絡人姓氏 (英文)',
        dataIndex: 'contact_last_name_en',
        sorter: (a, b) => a.contact_last_name_en.localeCompare(b.contact_last_name_en)
      },
      {
        title: '聯絡人姓氏 (中文)',
        dataIndex: 'contact_last_name_zh',
        sorter: (a, b) => a.contact_last_name_zh.localeCompare(b.contact_last_name_zh)
      },
      {
        title: '聯絡人名字 (中文)',
        dataIndex: 'contact_first_name_zh',
        sorter: (a, b) => a.contact_first_name_zh.localeCompare(b.contact_first_name_zh)
      },
      {
        title: '使用狀態',
        dataIndex: 'is_active',
        render: (value) => displayIsActive(value),
        sorter: (a, b) => a.is_active - b.is_active
      },
      {
        title: '確認狀態',
        dataIndex: 'status',
        render: (value) => displayStatus(value),
        sorter: (a, b) => a.status - b.status
      },
      {
        title: '發送電郵狀態',
        dataIndex: 'is_confirm_send_email',
        render: (value) => displayEmailStatus(value),
        sorter: (a, b) => a.is_confirm_send_email - b.is_confirm_send_email
      },
      {
        title: '申請時間',
        dataIndex: 'create_time',
        render: (date) => {
          return moment.unix(date).format('YYYY-MM-DD HH:mm')
        },
        // render: (value) => displayEmailStatus(value),
        sorter: (a, b) => a.ctime - b.ctime
      },
      {
        title: '最後更新時間',
        dataIndex: 'utime',
        render: (date) => {
          return moment.unix(date).format('YYYY-MM-DD HH:mm')
        },
        // render: (value) => displayEmailStatus(value),
        sorter: (a, b) => a.utime - b.utime
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "使用中 Active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "停用 Inactive";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "一般會員";
        break;
      case 2:
        displayStr = "學生會員";
        break;
      case 3:
        displayStr = "公司會員";
        break;
      default:
        displayStr = ""
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已確認";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未確認";
        tagColor = 'red';
        break;
      case -1:
        displayStr = "已過期";
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }

    return <Tag color={tagColor}>{displayStr}</Tag>;
  }
  const displayEmailStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "已發送";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "尚未發送";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{ pageSize: 100 }}
      />
      <Modal
        title="輸入訪客資料"
        visible={logInfoModalVisible}
        footer={null}
        onCancel={() => setLogInfoModalVisible(false)}
      >
        <Form
          form={LogInfoForm}
          name="UserForm"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.Item
            label="名稱"
            name="first_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="姓氏"
            name="last_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="電郵"
            name="email"
          // rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="電話"
            name="mobile"
            rules={[{ required: true }]}
            validateStatus={isNumberValid || isValid ? 'error' : ''}
            help={(() => {
              if (isNumberValid) {
                return (
                  t("moblie_digit")
                )
              }

              return null;
            })()}
          >
            <Input
              placeholder="12345678"
              value={number}
              onChange={onNumberChange}
              addonBefore={(
                <Form.Item name="contactPersonMobilePrefix" noStyle>
                  <Select style={{ width: 80 }} onChange={handleOnNumberChange}>
                    <Option value="+852">+852</Option>
                    <Option value="+86">+86</Option>
                  </Select>
                </Form.Item>
              )}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </Layout>
  )
}

export default CompanyListTable;