import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, message, Tabs, DatePicker, notification, Divider, InputNumber, Radio, AutoComplete, Checkbox
} from 'antd';
import moment from 'moment';
import * as Main from '../../core/Main';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;


const CompanyStatusForm = (props) => {
  // const history = useHistory();
  let location = useLocation();
  const { t } = useTranslation()


  let { initialValues } = props

  const [UserForm] = Form.useForm();

  const { id } = queryString.parse(location.search);
  const { key } = queryString.parse(location.search);
  const history = useHistory();
  const [initialValuesForm, setInitialValues] = useState({
    gender: "m",
    contactPersonMobilePrefix: "+852",
    contactEmergencyMobilePrefix: "+852"
  })

  useEffect(() => {
    if (id > 0) {
      // initialValues append to initialValuesForm
      let newObject = { ...initialValuesForm, ...initialValues }
      setInitialValues(newObject)
    }

  }, [initialValues])


  // if (id > 0) {
  //   let newObject = { ...initialValuesForm, ...initialValues }
  //   setInitialValues(newObject)
  //   console.log(newObject)
  // }

  useEffect(() => {
    UserForm.resetFields()
  }, [initialValuesForm])

  const displayMoment = (unixTime, outputFormat = 'YYYY/MM/DD HH:mm') => {
    if (unixTime !== 0) {
      return moment.unix(unixTime).format(outputFormat);
    }
    return '-';
  }

  const onFinish = async (formData) => {
    formData.user_id = id;



    if (id > 0) {
      let response = await Service.call('patch', `/api/user/status`, formData);

      if (response.status === -1) {
        notification.error({
          message: t("error"),
        });
        return;
      }
      notification.success({
        message: t("success"),
      });
      if (initialValuesForm.is_active != formData.is_active) {
        history.push("/company/company/list?id=3")
      }

      if (id === 0) {
        history.push("/company/company/list")
      } else {
        history.push(`/company/company/list?key=${key}`)
      }

    }
  }

  return (
    <Form
      form={UserForm}
      name="UserForm"
      layout="vertical"
      initialValues={initialValuesForm}
      onFinish={onFinish}
    >
      <Form.Item
        label={t("confirmation_status")}
        name="status"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t('selectLabel')}
        >
          <Option value={1}>{t("confirmed")}</Option>
          <Option value={0}>{t("pending_confirm")}</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={t("is_active")}
        name="is_active"
        rules={[
          {
            required: true,
            message: "必須填寫"
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t('selectLabel')}
        >
          <Option value={1}>{t("active")}</Option>
          <Option value={0}>{t("inactive")}</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CompanyStatusForm;
