import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, Space
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import MemberListTable from '../MemberListTable';
// import FormUploadFile from '../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
// import MemberListTableConfirm from './MemberListTableConfirm';
const { TabPane } = Tabs;
const debug = require('debug')('app:admin:client:src:AdvertisementList');


const involvedModelName = "company";
const selectedKey = 3;
const tableIDName = "company_user_id";

function UploadSection(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    debug(info);
    // return;
    console.log('info>>>', info)
    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }
  }

  // return (
  //   // <FormUploadFile
  //   //   type="button"
  //   //   imageURL={null}
  //   //   data={{
  //   //     scope: 'private',
  //   //     // webcast_event_id
  //   //   }}
  //   //   onChange={onChange}
  //   //   buttonText="上傳CSV"
  //   // />
  // );
}

const MemberList = (props) => {
  const app = useSelector((state) => state.app);
  const { t } = useTranslation()
  const title = t('quotalist');
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getTapkey, setgetTapkey] = useState(1);
  const history = useHistory();
  let location = useLocation();
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    let dataList2 = []; //for other status
    let inactive_dataList = [];
    try {
      let url = "/api/user/list?type=individual";
      let data = await Service.call('get', url);


      // reorder records
      data = _.orderBy(data, ['utime'], ['desc'])

      for (let i = 0; i < data.length; i++) {
        if (data[i].status === 1) {
          dataList.push(data[i]);
        } else {
          dataList2.push(data[i]);
        }
        if (data[i].is_active === 0) {
          inactive_dataList.push(data[i]);
        }
      }

    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(dataList);
      setDataList2(dataList2);
      setInactive_dataList(inactive_dataList);
      setLoading(false)
    }
  }

  const callback = async () => {
    console.log("call back")
  }

  const importUserCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/user/import', {
      filepath,
    });

    if (!resp || resp.status <= 0) {
      notification.error({
        message: 'User import failed, please contact administrator',
        description: '',
      });
      return;
    }

    // if (resp.statusError === -1) {
    //   notification.error({
    //     message: `User import failed. CSV index ${resp.row}`,
    //     description: `The First Name ${resp.first_name},Last Name ${resp.last_name} and Mobile ${resp.mobile} Duplicated.`,
    //     duration: 0
    //   });
    //   getAllData();
    //   return;
    // }
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }
    notification.success({
      message: `User import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description: description,
      duration: 0
    });

    getAllData();
  }

  const handleTestEmail = async () => {
    await Service.call('post', '/api/user/email/confirm_member');
  }
  const onClickExportExcel = async () => {
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    window.location = `${app.config.API_SERVER_URL}/api/export/user/excel?type=individual`;
  }

  // const getTapValue = (activeKey) => {
  //   setgetTapkey(activeKey)

  // };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <div
      // style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Space size={8}>
          <Link
            to={{
              pathname: "/company/member/details/",
              search: "?id=0",
            }}
          >
            <Button
              type="primary"
            >
              新增會員
          </Button>
          </Link>

          <Button
            type="primary"
            onClick={() => onClickExportExcel()}
          >
            導出紀錄 (Excel)
          </Button>

          <UploadSection
            webcast_event_id="1"
            onSuccess={(resp) => {
              debug(`resp >>SendEmailButton `, resp);
              const {
                originalname,
                filename,
                filepath,
                url,
              } = resp;
              importUserCSV(filepath)
              // this.importUserCSV(webcast_event_id, filepath);
            }}
          />




        </Space>





      </div>
      <Divider />
      {/* <Button
        onClick={() => handleTestEmail()}
      >
        Send Test Email
      </Button> */}

      <Tabs defaultActiveKey={defaultActiveKey}
        onChange={(value) => {
          // getTapValue(value)
        }}>
        <TabPane tab="已確認" key="1" >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <MemberListTable
              dataObj={dataList}
              adminChangeData={getAllData}
              defaultActiveKey={1}
              callBack={() => getAllData()}
            />
          </Spin>
        </TabPane>
        <TabPane tab="等待確認" key="2">
          <MemberListTable
            dataObj={dataList2}
            defaultActiveKey={2}
            adminChangeData={getAllData}
            callBack={() => getAllData()}
            
          />
        </TabPane>

        <TabPane tab="停用" key="3">
          <MemberListTable
            dataObj={inactive_dataList}
            defaultActiveKey={3}
            adminChangeData={getAllData}
            callBack={() => getAllData()}
          />
        </TabPane>


      </Tabs>
      {/* <MemberListTable
        dataObj={dataList}
        adminChangeData={getAllData}
      /> */}
    </NavAndSideFrame>
  )
}

export default MemberList;