import React, { Component, useEffect, useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import * as Service from '../../core/Service';

const AdminSettingsTable = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [t] = useTranslation('common');
  const { pathname, type } = props
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true)
    const result = await Service.call('get', `/api/meta/path?pathname=${pathname}`)
    setDataSource(result.meta);
    setLoading(false)
  }

  const getColumns = () => {
    const columns = [
      {
        title: "操作",
        dataIndex: 'site_meta_id',
        key: 'site_meta_id',
        render: (value, record, index) => {
          let { site_meta_id, meta_key } = record;
          return (
            <Link
              to={{
                pathname: "/company/setting/details",
                search: `?site_meta_id=${site_meta_id}&type=${type}`,
              }}
            >
              <Button
                shape="circle"
                icon={<EditOutlined />}
                style={{ color: '#00AA00' }}
              />
            </Link>
          )
        }
      },
      {
        title: "相關",
        dataIndex: 'meta_key',
        key: 'meta_key',
      },
      {
        title: "路徑",
        dataIndex: 'pathname',
        key: 'pathname',
      },
      {
        title: "內容",
        dataIndex: 'meta_value',
        key: 'meta_value',
        render: (value) => value.substring(0, 100) + "..."
      },
    ]

    return columns;
  }


  return (
    <Spin
      indicator={<LoadingOutlined />}
      spinning={loading}
    >
      <Table
        columns={getColumns()}
        dataSource={dataSource}
        rowKey={(record, index) => index}
        pagination={{ defaultPageSize: 100 }}
        size="small"
        bordered
      />
      {/* <Table
        scroll={{x: true}}
        dataSource={dataSource}
        columns={getColumns()}
        bordered
        pagination={{ defaultPageSize: 100 }}
        size="small"
      /> */}
    </Spin>
  )
}

export default AdminSettingsTable
