import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  message,
  Tabs,
  DatePicker,
  TimePicker,
  Switch,
  notification,
  Divider,
  InputNumber,
  Radio,
  AutoComplete,
  Modal,
} from "antd";
import moment from "moment";

import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import * as debugLib from "debug";
import { useTranslation } from "react-i18next";
import * as Service from "../../core/Service";
import * as Main from "../../core/Main";

const debug = require("debug")(
  "app:admin:client:src:pages:company:UserQuotaModal"
);

const { Option } = Select;

const UserQuotaModal = (props) => {
  const { t } = useTranslation();
  const [isCompany, setIsCompany] = useState();
  const [title, setTitle] = useState('');
  const [itemCategoryFilter, setItemCategoryFilter] = useState(0);
  const [itemCategory, setItemCategory] = useState([]);
  const [userObj, setUserObj] = useState([]);
  const [userID, setUserID] = useState(0);
  const [user_quota_amount, setUserQuotaAmount] = useState("Na");
  const [formType, setformType] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    handleFormSubmit,
    particularUserView,
    type
  } = props;
  // let setUserQuotaAmount = 0

  // if (selectedRecord) {
  //   selectedRecord.utime = moment.unix(selectedRecord.utime).format('YYYY-MM-DD HH:mm')
  //   selectedRecord.ctime = moment.unix(selectedRecord.ctime).format('YYYY-MM-DD HH:mm')
  // }

  const [QuotaInfoForm] = Form.useForm();

  useEffect(() => {
    QuotaInfoForm.resetFields();

    if (selectedRecord.user_quota_id !== 0) {
      setUserQuotaAmount(selectedRecord.quota_amount);
      setItemCategoryFilter(selectedRecord.item_category_id);
      setUserID(selectedRecord.user_id);
      getUserQuota(userID, itemCategoryFilter);
    }
    setTitle('add_quota');
    setUserID(selectedRecord.user_id);

    getUserList(selectedRecord.type);
    setformType(0);
    QuotaInfoForm.setFieldsValue({
      user_id: selectedRecord.user_id,
      expiry_date: moment.unix(selectedRecord.expiry_date),
    });
    // console.log("testestts",selectedRecord.user_quota_id)
    // getUserQuota(userID, itemCategoryFilter)
  }, [selectedRecord]);

  useEffect(() => {
    getItemCategoryList();
  }, []);

  useEffect(() => {
    getUserQuota(userID, itemCategoryFilter);
  }, [userID, itemCategoryFilter, type]);

  const getItemCategoryList = async () => {
    let itemCategoryArr = await Service.call("get", "/api/item/category/list");
    setItemCategory(itemCategoryArr);
  };

  const getUserQuota = async (user_id, item_categoryID) => {
    if (selectedRecord.user_id > 0) {
      user_id = selectedRecord.user_id;
    }
    if (item_categoryID > 0 && user_id > 0) {
      let user_ListArr = await Service.call(
        "get",
        `/api/user_quota/list/Expired/${user_id}/${item_categoryID}`
      );
      if (!_.isUndefined(user_ListArr.user_quota_id)) {
        if (type === 'new')setTitle('add_quota');
        if (type === 'quota')setTitle('edit_quota');
        if (type === 'date')setTitle('edit_expiry_date');
        // setUserQuotaAmount(0)
        setUserQuotaAmount(user_ListArr.quota_amount);
        setformType(1);
        // console.log("testetsset",user_ListArr)
      } else {
        setTitle('add_quota')
        setUserQuotaAmount(0);
        setformType(0);
      }
      setSubmitDisabled(false);
    } else {
      setUserQuotaAmount("Na");
      setSubmitDisabled(true);
    }
  };

  const handleUserIDChange = (value) => {
    // value : Category ID
    if (value === 0) {
      setUserID(0);
    }
    if (value > 0) {
      setUserID(value);
    }
  };

  const handleItemCategoryFilterChange = (value) => {
    // value : Category ID
    if (value === 0) {
      setItemCategoryFilter(0);
    }
    if (value > 0) {
      setItemCategoryFilter(value);
    }
    // getUserQuota(userID, value)
  };

  const getUserList = async (value) => {
    // QuotaInfoForm.setFieldsValue({ user_id: "" })
    // QuotaInfoForm.setFieldsValue({ item_category_id: "" })
    // QuotaInfoForm.setFieldsValue({ user_quota: "Na" })
    // QuotaInfoForm.setFieldsValue({ change_amount: "" })

    // setItemCategoryFilter(0)
    // setUserID(0)

    // console.log("selectedRecord", selectedRecord)
    if (selectedRecord.user_quota_id === 0) {
      QuotaInfoForm.setFieldsValue({ user_id: "" });
      QuotaInfoForm.setFieldsValue({ item_category_id: "" });
      QuotaInfoForm.setFieldsValue({ user_quota: "Na" });
      QuotaInfoForm.setFieldsValue({ change_amount: "" });
      setItemCategoryFilter(0);
      setUserID(0);
    }

    try {
      let user_ListArr;
      if (value === 1) {
        user_ListArr = await Service.call(
          "get",
          "/api/user/list?type=individualMember"
        );
      } else if (value === 2) {
        user_ListArr = await Service.call("get", "/api/user/list?type=student");
      } else {
        user_ListArr = await Service.call(
          "get",
          "/api/user/list?type=companyMember"
        );
      }
      setUserObj(user_ListArr);
    } catch (error) {
      console.error("error >>> ", error);
    }
  };

  return (
    <Modal
      title={t(title)}
      visible={modalVisible}
      footer={null}
      onCancel={() => {
        setModalVisible(false);
        setItemCategoryFilter(0);
        setUserID(0);
      }}
    >
      <Form
        form={QuotaInfoForm}
        name="UserQuotaForm"
        layout="vertical"
        initialValues={selectedRecord}
        onFinish={(formData) => {
          formData.company_id = selectedRecord.company_id;
          handleFormSubmit(formData);
          setItemCategoryFilter(0);
          setUserID(0);
        }}
      >
        <Form.Item
          label="會員類別"
          name="type"
          rules={[
            {
              required: true,
              message: "必須填寫",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("selectLabel")}
            disabled={selectedRecord.user_id > 0}
            onChange={(value) => {
              setIsCompany(value);
              getUserList(value);
            }}
          >
            <Option key={1} value={1}>
              一般會員
            </Option>
            <Option key={2} value={2}>
              學生會員
            </Option>
            <Option key={3} value={3}>
              公司會員
            </Option>
          </Select>
        </Form.Item>

        {selectedRecord.type === 2
        || selectedRecord.type === 1
        || isCompany === 2
        || isCompany === 1 ? (
          <Form.Item label={t("user")} name="user_id">
            <Select
              showSearch
              disabled={selectedRecord.user_id > 0}
              onChange={(value) => handleUserIDChange(value)}
              filterOption={(input, option) => option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              {_.map(userObj, (user_itemRc) => {
                if (user_itemRc.is_active === 1) {
                  return (
                    <Option
                      key={user_itemRc.user_id}
                      value={user_itemRc.user_id}
                    >
                      {user_itemRc.user_key
                        + " "
                        + user_itemRc.first_name
                        + " "
                        + user_itemRc.last_name}
                    </Option>
                  );
                }
              })}
            </Select>
          </Form.Item>
          ) : (
            <Form.Item label={t("user")} name="user_id">
              <Select
                showSearch
                disabled={selectedRecord.user_id > 0}
              // disabled={particularUserView}
              // disabled={selectedRecord.user_quota_id !== 0 && !_.isUndefined(selectedRecord.user_quota_id) ? true : false}
                onChange={(value) => handleUserIDChange(value)}
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                {_.map(userObj, (user_itemRc) => {
                  if (user_itemRc.is_active === 1) {
                    return (
                      <Option
                        key={user_itemRc.company_name_en}
                        value={user_itemRc.user_id}
                      >
                        {user_itemRc.user_key + " " + user_itemRc.company_name_en}
                      </Option>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          )}
        <Form.Item label={t("category_name")} name="item_category_id">
          <Select
            // defaultValue={0}
            disabled={type === 'date'}
            showSearch
            style={{ width: 200 }}
            placeholder="Search to Category"
            optionFilterProp="children"
            onChange={(value) => handleItemCategoryFilterChange(value)}
            filterOption={(input, option) => option.props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
          >
            {_.map(itemCategory, (itemCategoryRc) => (
              <Option
                key={itemCategoryRc.item_category_id}
                value={itemCategoryRc.item_category_id}
              >
                {itemCategoryRc.category_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={t("user_quota")} name="user_quota">
          {user_quota_amount}
        </Form.Item>

        {type !== 'date' ? (
          <Form.Item
            label={t("change_amount")}
            name="change_amount"
            rules={[{ required: true, message: "Need a number" }]}
          >
            <InputNumber />
          </Form.Item>
        )
          : (
            <Form.Item
              label={t("expiry_date")}
              name="expiry_date"
              rules={[{ required: true, message: "Need a choose a date" }]}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          )}

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={submitDisabled}>
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserQuotaModal;
