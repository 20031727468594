import React, { Component } from 'react';
import {
  Button, Card, DatePicker, Input, Modal, notification, Popconfirm, Space, Table, Badge, Divider, Tag, Spin
} from 'antd';
import * as Icon from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fuse from 'fuse.js';
import _ from 'lodash';
import moment from 'moment';
import { ActionCreators } from '../../redux/actions';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import AdminAccountForm from './AdminAccountForm';

const { RangePicker } = DatePicker;

// #region Change Area #1
const involvedModelName = "admin";
const pageTitle = '管理員用戶列表';
const tableIdName = 'company_user_id';
const selectedKey = 3;
const filterableField = [
  'username', 'email'
]
let useDatePicker = false;
const defaultStartTime = moment().subtract(1, 'months').startOf('day').unix();
const defaultEndTime = moment().endOf('day').unix();
// #endregion Change Area #1

let searchWord = '';
let selectedFormId = 0;
let selectedFormData = {};
let selectedEditFormData = false;
let fuse = [];

class AdminAccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalDataList: [],
      dataList: [],
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      recordId: 0,
      loading: true,
    };
  }

  async componentDidMount() {
    await this.getAllData();
  }

  async getAllData() {
    this.setState({ loading: true })
    let data = [];
    try {
      data = await Service.call('get', `/api/${involvedModelName}/list`);
    } catch (error) {
      console.error(error);
      this.setState({ loading: false })
    }

    this.setState({ loading: false })
    if (data.status !== 1) {
      return;
    }



    let originalDataList = data.admin_accounts;
    let dataList = _.clone(originalDataList);
    const fuseOptions = {
      findAllMatches: true,
      includeScore: true,
      threshold: 0.15,
      keys: filterableField
    };
    fuse = new Fuse(originalDataList, fuseOptions);
    this.setState({ dataList, originalDataList });
  }

  setModalVisible(isVisible) {
    this.setState({ modalVisible: isVisible });
  }

  setTableHeader() {
    // #region Change Area #2
    let columns = [
      {
        title: '操作',
        dataIndex: tableIdName,
        render: (value, record, index) => {
          let wording = "停用"
          let icon = <Icon.StopOutlined />
          let color = "#AA0000"
          return (
            <span>
              <Popconfirm
                title={`Confirm to proceed ${wording} this record?`}
                onConfirm={async () => {
                  let params = {
                    company_user_id: value,
                    is_active: 0,
                  }

                  let result = await Service.call('patch', '/api/admin/admin_account', params);
                  if (result.status !== 1) {
                    notification.warning({ message: '修改失敗' });
                    return;
                  }

                  notification.success({ message: '修改成功' });
                  this.getAllData();
                }}
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button shape="circle" icon={icon} style={{ marginRight: 8 }} />
              </Popconfirm>
              <Button
                shape="circle"
                icon={<Icon.EditOutlined />}
                style={{ marginRight: 8 }}
                onClick={() => {
                  this.setState({ recordId: value })
                  selectedFormId = value;
                  selectedFormData = record;
                  selectedEditFormData = false;
                  this.setModalVisible(true);
                }}
              />

              <Button
                shape="circle"
                icon={<Icon.EditOutlined />}
                style={{ marginRight: 8 }}
                onClick={() => {
                  this.setState({ recordId: value })
                  selectedFormId = value;
                  selectedFormData = record;
                  selectedEditFormData = true;
                  this.setModalVisible(true);
                }}
              />

            </span>
          )
        }
      },
      { title: '用戶名稱', dataIndex: 'username' },
      { title: '暱稱', dataIndex: 'nickname' },
      // { title: '管理員角色', dataIndex: 'admin_role' },

      { title: '電子郵件', dataIndex: 'email' },
      {
        title: '活動',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (value) => this.displayIsActive(value),
        //   render: (text, record, index) => {
        //     let {is_active} = record;
        //     return (
        //       <span>
        //         { is_active === 1 ? (<Badge status="processing" />) : <Badge color="#555" /> }
        //       </span>
        //     )
        //   }
      },
    ];
    // #endregion Change Area #2
    let columumnsWithSorter = [];
    _.forEach(columns, (column) => {
      let columnWithSorter = column;
      columnWithSorter.sorter = (_a, _b) => {
        let a = _a;
        let b = _b;
        a = `${a[`${column.dataIndex}`]}`;
        b = `${b[`${column.dataIndex}`]}`;
        return a.localeCompare(b);
      }
      columumnsWithSorter.push(columnWithSorter);
    })
    return columumnsWithSorter;
  }

  async filterData(_filterObj) {
    let filterObj = _filterObj || {};
    let {
      originalDataList,
      startTime, endTime
    } = this.state;
    let dataList = _.clone(originalDataList);
    if (filterObj.searchWord) { searchWord = filterObj.searchWord; }

    if (useDatePicker) {
      if (filterObj.startTime) { startTime = filterObj.startTime; }
      if (filterObj.endTime) { endTime = filterObj.endTime; }
      dataList = _.filter(dataList, (data) => {
        return moment(data.ctime).isBetween(startTime, endTime);
      })
    }

    const fuzzyResults = fuse.search(searchWord);
    let fuzzyArray = [];
    if (fuzzyResults.length !== 0) {
      _.forEach(fuzzyResults, (result) => {
        fuzzyArray.push(result.item);
      })
      dataList = fuzzyArray;
    } else if (searchWord !== '') {
      notification.warning({ message: 'Warning', description: 'No record within date range' });
      dataList = fuzzyArray;
    }
    this.setState({ dataList });
  }

  displayMoment(unixTime, outputFormat = 'YYYY/MM/DD HH:mm') {
    if (unixTime !== 0) {
      let displayTime = moment.unix(unixTime).format(outputFormat);
      return displayTime;
    }
    return '-';
  }

  displayBoolean(key_value) {
    let displayText = 'No'
    if (key_value === 1) {
      displayText = 'Yes'
    }
    return (
      <span>
        {displayText}
      </span>
    )
  }

  displayIsActive(value) {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "使用中 Active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "停用 Inactive";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  render() {

    let {
      modalVisible, dataList,
      startTime, endTime, recordId
    } = this.state;
    return (
      <NavAndSideFrame
        title={pageTitle}
        selectedKey={`${selectedKey}`}
      >
        {/* <Button
          type="primary"
          onClick={() => {
            this.setState({ recordId: 0 })
            selectedFormId = 0;
            selectedFormData = {};
            selectedEditFormData = false;
            this.setModalVisible(true);
          }}
        >
          新增管理員用戶
        </Button>
        <Divider /> */}
        <Modal
          title={recordId > 0 ? "修改" : "新增"}
          visible={modalVisible}
          footer={null}
          style={{ maxWidth: 800 }}
          width="90%"
          destroyOnClose
          onCancel={() => {
            this.setModalVisible(false);
          }}
        >
          <AdminAccountForm
            recordId={recordId}
            selectedFormData={selectedFormData}
            selectedEditFormData={selectedEditFormData}
            closeMethod={async () => {
              await this.getAllData();
              this.setModalVisible(false);
            }}
          />
        </Modal>
        <div />
        <Spin
          indicator={<LoadingOutlined />}
          spinning={this.state.loading}
        >
          <Table
            // pagination={{
            //   defaultPageSize: 25,
            //   showSizeChanger: true,
            //   pageSizeOptions: [25, 50, 100, 200, 500]
            // }}
            scroll={{ x: 'auto' }}
            dataSource={dataList}
            columns={this.setTableHeader()}
            rowKey={tableIdName}
            bordered
            size="small"
            pagination={{ pageSize: 100 }}
          />
        </Spin>
      </NavAndSideFrame>
    )
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAccountList);