import React, { Component, useState, useEffect } from 'react';
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Input, Space
} from 'antd';
import {
  EditOutlined,
  FileTextOutlined,
  KeyOutlined,
  QrcodeOutlined,
  BankOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const MemberAccessLogListTable = (props) => {
  const involvedModelName = "company";
  const title = "Company User";
  const selectedKey = 3;
  const tableIDName = "user_id";
  const admin = useSelector(state => state.app.admin);

  const [dataList, setDataList] = useState(props.dataObj);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  let searchInput
  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();

    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText("")
    // this.setState({ searchText: '' });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
          text
        ),
  });


  useEffect(() => {
    setDataList(props.dataObj)
  }, [props.dataObj]);

  const setTableHeader = () => {
    const columns = [
      {
        title: '會員編號',
        dataIndex: 'user_key',
        sorter: (a, b) => a.user_key.localeCompare(b.user_key),
        ...getColumnSearchProps('user_key'),
      },
      {
        title: '公司名稱',
        dataIndex: 'company_name',
        sorter: (a, b) => a.company_name.localeCompare(b.company_name),
        ...getColumnSearchProps('company_name'),
      },
      {
        title: '名字',
        dataIndex: 'first_name',
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
        ...getColumnSearchProps('first_name'),
      },
      {
        title: '姓氏',
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
        ...getColumnSearchProps('last_name'),
      },
      {
        title: '手機號碼',
        dataIndex: 'mobile',
        sorter: (a, b) => a.mobile.localeCompare(b.mobile)
      },
      {
        title: '電子郵件',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email)
      },
      {
        title: '簽到時間',
        dataIndex: 'access_time',
        render: (value) => moment.unix(value).format("YYYY-MM-DD HH:mm:ss"),
        sorter: (a, b) => a.first_name.localeCompare(b.access_time)
      },
      {
        title: '簽到方式',
        dataIndex: 'log_type',
        render: (value) => displayLogType(value),
        sorter: (a, b) => a.log_type - b.log_type
      },
    ];
    return columns;
  }

  const displayIsActive = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "使用中 Active";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "停用 Inactive";
        tagColor = 'red';
        break;
      default:
        displayStr = "錯誤 Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  const displayLogType = (value) => {
    let displayStr = '';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "後台系統";
        break;
      case 2:
        displayStr = "應用程式";
        break;
      default:
        displayStr = "-"
        break;
    }
    return displayStr;
  }

  const displayStatus = (value) => {
    let displayStr = '';
    let tagColor = 'green';
    let statusValue = _.toInteger(value);
    switch (statusValue) {
      case 1:
        displayStr = "Yes";
        tagColor = 'green';
        break;
      case 0:
        displayStr = "No";
        tagColor = 'red';
        break;
      case -1:
        displayStr = "Disable";
        tagColor = '#9A9A9A';
        break;
      default:
        displayStr = "Error"
        tagColor = '#f50';
        break;
    }
    return <Tag color={tagColor}>{displayStr}</Tag>;
  }

  return (
    <Layout>
      <Table
        rowKey={tableIDName}
        scroll={{ x: 'max-content' }}
        dataSource={dataList}
        columns={setTableHeader()}
        bordered
        size="small"
        pagination={{pageSize: 100}}
      />
    </Layout>

  )

}

export default MemberAccessLogListTable;