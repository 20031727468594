import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Avatar, Button, Divider, Layout, Menu, Modal, Popconfirm, Table } from 'antd';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const { Header, Content, Sider } = Layout;

export default class NavAndSideFrame extends React.PureComponent {
  render() {
    let {title, children, selectedKey} = this.props;
    return (
      <Layout style={{width: '100%'}}>
        <Navbar {...this.props} />
        <Layout>
          <Sidebar {...this.props} defaultSelectedKeys={selectedKey} />
          <Layout style={{width: '100%'}}>
            <Content
              style={{
                background: '#fff', margin: 0, minHeight: 280, padding: 24
              }}
            >
              <h3 style={{marginTop: '5px'}}>{title}</h3>
              <Divider />
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

NavAndSideFrame.defaultProps = {
  title: "Title",
  children: null
}
