import React, { Component, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Avatar, Button, Divider, Form, Icon, Layout, Menu, Modal, Popconfirm, Table, Tag, Tooltip, Row, Col, Tabs, notification, message, Spin, DatePicker, Space
} from 'antd';
import {
  LoadingOutlined
} from '@ant-design/icons';
// import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import * as Service from '../../../core/Service';
import * as Main from '../../../core/Main';
import NavAndSideFrame from '../../../components/NavAndSideFrame';
import MemberListTable from './BookingListTable';


import FormUploadFile from '../../../components/form/FormUploadFile';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
// import MemberListTableConfirm from './MemberListTableConfirm';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const debug = require('debug')('app:admin:client:src:AdvertisementList');


const involvedModelName = "company";
const title = "當天預約記錄";
const selectedKey = 3;
const tableIDName = "company_user_id";

function UploadSection(props) {
  const {
    onSuccess,
  } = props;

  const onChange = (info) => {
    debug(info);
    // return;

    const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }
    if (status === 'done') {
      const { response } = info.file;
      debug(response);
      if (!response || response.status <= 0) {
        notification.error({
          message: `${info.file.name} uploaded failed, please contact system administrator`,
        });
        return;
      }
      message.success(`成功上載${info.file.name}`);
      if (onSuccess) {
        onSuccess(response);
      }
    }
    if (status === 'error') {
      message.error(`${info.file.name} uploaded failed, please contact system administrator`);
    }


  }

  return (
    <FormUploadFile
      type="button"
      imageURL={null}
      data={{
        scope: 'private',
        // webcast_event_id
      }}
      onChange={onChange}
      buttonText="上傳CSV"
    />
  );
}

const MemberList = (props) => {
  const app = useSelector((state) => state.app);
  const [dataList, setDataList] = useState([]);
  const [dataList2, setDataList2] = useState([]);
  const [inactive_dataList, setInactive_dataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getTapkey, setgetTapkey] = useState(1);
  const history = useHistory();
  let location = useLocation();

  const [viewStartDate, setViewStartDate] = useState(0)
  const [viewEndDate, setViewEndDate] = useState(0)
  const [itemCategoryFilter, setItemCategoryFilter] = useState(0)
  const [itemFilter, setItemFilter] = useState([])
  const [bookingItemStatusFilter, setBookingItemStatusFilter] = useState(0)
  const [calendarEvents, setCalendarEvents] = useState([])
  const [itemEvents1, setItemEvents1] = useState([])
  const [itemEvents2, setItemEvents2] = useState([])
  // const [modalVisible, setModalVisible] = useState(false);
  const { key } = queryString.parse(location.search);
  let defaultActiveKey = key

  useEffect(() => {
    getBookingItem()
    getViewDate()

  }, []);

  useEffect(() => {
    getBookingItem()
    // getViewDate()

  }, [viewStartDate,viewEndDate]);


  const dateFormat = 'YYYY-MM-DD';

  let nextWeek = 0
  let previousWeek = 0
  let thisweek_startof = moment().startOf('day').unix()
  let thisweek_endof = moment().endOf('day').unix()

  // console.log("thisweek_startof",thisweek_startof)

  const getViewDate = () => {
    setViewStartDate(thisweek_startof)
    setViewEndDate(thisweek_endof)
  }

  // const getViewDate = () => {
  //   thisweek_startof = moment().startOf('week').unix()
  //   thisweek_endof = moment().endOf('week').unix()
  //   // selectDateTime(thisweek_startof,thisweek_endof)
  //   // console.log("viewStartDate", thisweek_startof)
  //   // console.log("viewStartDateof", thisweek_endof)
  //   setViewStartDate(thisweek_startof)
  //   setViewEndDate(thisweek_endof)
  //   getBookingItem()
  // }


  // const selectDate = (prevOrNext, focusWeek) => {

  //   nextWeek = moment(focusWeek).endOf('week').unix()
  //   previousWeek = moment(focusWeek).startOf('week').unix()

  //   setViewStartDate(previousWeek)
  //   setViewEndDate(nextWeek)

  //   getBookingItem()
  // }


  const selectDateTime = (prevOrNext, focusWeek) => {
   
    if (focusWeek !== null && prevOrNext !== null) {
      // console.log("prevOrNext",prevOrNext)
      console.log("focusWeek",focusWeek)
      // nextWeek = moment(prevOrNext).unix()
      // previousWeek = moment(focusWeek).unix()
      nextWeek = moment(prevOrNext).startOf('day').unix()
      previousWeek = moment(focusWeek).endOf('day').unix()
      setViewStartDate(nextWeek)
      setViewEndDate(previousWeek)

      getBookingItem()
    }
  }

  const getBookingItem = async () => {
    setLoading(true)
    let calendarData = []
    let orderedCalendarData = []
    let gunfuTable = []
    let shareTable = []

    try {
      // Get & format calander data
      let itemArr = await Service.call('get', "/api/item/list");
      let itemArrMap = _.keyBy(itemArr, "item_id")
      // console.log("itemArr",itemArr)
      let userArr = await Service.call('get', "/api/user/list/1");
      let userArrMap = _.keyBy(userArr, "user_id");
      // let guestArr = await Service.call('get', "/api/user/guest/list");
      // let guestArrMap = _.keyBy(guestArr, "user_guest_id");


      // let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/1610586000/1610812799`)
      // let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/${thisweek_startof}/${thisweek_endof}`)
      let bookingItemArr = await Service.call('get', `/api/booking_item/list/bydate/${viewStartDate}/${viewEndDate}`)
      console.log("bookingItemArr", `/api/booking_item/list/bydate/${viewStartDate}/${viewEndDate}`)

      calendarData = _.filter(bookingItemArr, 'status')
    

      // console.log("calendarData", `/api/booking_item/list/bydate/${viewStartDate}/${viewEndDate}`)
      // Mapping Data
      _.each(calendarData, (bookingItem, i) => {
        calendarData[i].item_name = itemArrMap[bookingItem.item_id].item_name
        calendarData[i].title = itemArrMap[bookingItem.item_id].item_name
        calendarData[i].item_feature_image = itemArrMap[bookingItem.item_id].item_feature_image
        // if (_.indexOf(_.keys(userArrMap), bookingItem.user_id.toString()) !== -1) {
        //   calendarData[i].title += " - " + userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
        // } else if (_.indexOf(_.keys(guestArrMap), bookingItem.user_guest_id.toString()) !== -1) {
        //   calendarData[i].title += " - [GUEST] " + guestArrMap[bookingItem.user_guest_id].first_name + ' ' + guestArrMap[bookingItem.user_guest_id].last_name
        //   calendarData[i].first_name = guestArrMap[bookingItem.user_guest_id].first_name
        //   calendarData[i].last_name = guestArrMap[bookingItem.user_guest_id].last_name
        //   calendarData[i].nickname = guestArrMap[bookingItem.user_guest_id].nickname
        //   calendarData[i].email = guestArrMap[bookingItem.user_guest_id].email
        //   calendarData[i].mobile = guestArrMap[bookingItem.user_guest_id].mobile
        //   calendarData[i].remarks = guestArrMap[bookingItem.user_guest_id].remarks
        // }
        if (_.indexOf(_.keys(userArrMap), bookingItem.user_id.toString()) !== -1) {
          if (userArrMap[bookingItem.user_id].type == 1 || userArrMap[bookingItem.user_id].type == 2) {
            calendarData[i].title += " - " + userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
            calendarData[i].username = userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
            calendarData[i].mobile = userArrMap[bookingItem.user_id].mobile
          }
          if (userArrMap[bookingItem.user_id].type == 3) {
            calendarData[i].title += " - " + userArrMap[bookingItem.user_id].company_name_en
            calendarData[i].username = userArrMap[bookingItem.user_id].company_name_en
            calendarData[i].mobile = userArrMap[bookingItem.user_id].company_phone
          }

          if (userArrMap[bookingItem.user_id].type == 1 || userArrMap[bookingItem.user_id].type == 2) {
            calendarData[i].title += " - " + userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name
            calendarData[i].username = userArrMap[bookingItem.user_id].first_name + ' ' + userArrMap[bookingItem.user_id].last_name

          }
          calendarData[i].user_key = userArrMap[bookingItem.user_id].user_key
          calendarData[i].type = userArrMap[bookingItem.user_id].type

        }
        calendarData[i].remarks = bookingItem.remarks
        calendarData[i].item_category_id = itemArrMap[bookingItem.item_id].item_category_id
        calendarData[i].start = moment.unix(bookingItem.start_time).toDate()
        calendarData[i].end = moment.unix(bookingItem.end_time).toDate()
      })
     


      // Filter Items Category
      if (itemCategoryFilter !== 0) {
        calendarData = _.filter(calendarData, ['item_category_id', itemCategoryFilter]);
      }

     

      // Filter Items
      if (itemFilter.length > 0) {
        let calendarDataFiltered = []
        _.each(itemFilter, (itemFilterRc) => {
          itemFilterRc = _.toInteger(itemFilterRc)
          calendarDataFiltered.push(_.filter(calendarData, ['item_id', itemFilterRc]))
          calendarDataFiltered = _.flatten(calendarDataFiltered)
        })
        calendarData = calendarDataFiltered
      }
      

      // Filter Status
      if (bookingItemStatusFilter !== 0) {
        calendarData = _.filter(calendarData, ['status', bookingItemStatusFilter]);
      }

    

      orderedCalendarData = _.orderBy(calendarData, ["item_id"], ["asc"])

      for (let i = 0; i < orderedCalendarData.length; i++) {
        if (orderedCalendarData[i].status === 10 || orderedCalendarData[i].status === 8) {
          if (orderedCalendarData[i].item_category_id === 1) {
            gunfuTable.push(orderedCalendarData[i]);
          } else {
            shareTable.push(orderedCalendarData[i]);
          }
        }
      }
      // console.log("calendarDatas", orderedCalendarData)


    } catch (error) {
      console.error('error >>> ', error);
    } finally {
      // setItemEvents1(orderedCalendarData)
      setItemEvents1(gunfuTable)
      setItemEvents2(shareTable)
      // setCalendarEvents(orderedCalendarData)
    }

    setLoading(false)
  }


  const handleItemFilterChange = (value) => {
    if (value.length > 0) {
      setItemFilter(value)
    }
    // console.log('itemchange', itemFilter)
  }


  const getAllData = async () => {
    setLoading(true)
    let dataList = [];
    let dataList2 = []; //for other status
    let inactive_dataList = [];
    try {
      let url = "/api/user/list?type=individual";
      let data = await Service.call('get', url);


      // reorder records
      data = _.orderBy(data, ['utime'], ['desc'])

      for (let i = 0; i < data.length; i++) {
        if (data[i].status === 1) {
          dataList.push(data[i]);
        } else {
          dataList2.push(data[i]);
        }
        if (data[i].is_active === 0) {
          inactive_dataList.push(data[i]);
        }
      }


    } catch (error) {
      console.error('error >>> ', error);
      setLoading(false)
    } finally {
      setDataList(dataList);
      setDataList2(dataList2);
      setInactive_dataList(inactive_dataList);
      setLoading(false)
    }
  }

  const callback = async () => {

  }

  const importUserCSV = async (filepath) => {
    let resp = await Service.call('post', '/api/user/import', {
      filepath,
    });

    if (!resp || resp.status <= 0) {
      notification.error({
        message: 'User import failed, please contact administrator',
        description: '',
      });
      return;
    }

    // if (resp.statusError === -1) {
    //   notification.error({
    //     message: `User import failed. CSV index ${resp.row}`,
    //     description: `The First Name ${resp.first_name},Last Name ${resp.last_name} and Mobile ${resp.mobile} Duplicated.`,
    //     duration: 0
    //   });
    //   getAllData();
    //   return;
    // }
    let description = " "
    if (resp.arr.length > 0) {
      description = `Row ${resp.arr} import failed`
    }
    notification.success({
      message: `User import done. ${resp.succeed} succeed, ${resp.failed} failed.`,
      description: description,
      duration: 0
    });

    getAllData();
  }

  const handleTestEmail = async () => {
    await Service.call('post', '/api/user/email/confirm_member');
  }
  const onClickExportExcel = async () => {
    // console.log(`${app.config.API_SERVER_URL}/api/export/access_log/excel`);
    window.location = `${app.config.API_SERVER_URL}/api/export/user/excel?type=individual`;
  }

  // const getTapValue = (activeKey) => {
  //   setgetTapkey(activeKey)

  // };

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey}>
      <Space direction="vertical" size={12}>
        <RangePicker
          onCalendarChange={([startMoment, endMoment]) => { 
            selectDateTime(startMoment, endMoment)
          }}
          defaultValue={[moment(), moment()]}
         

        />
      </Space>
      {/* <div
      // style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Space size={8}>
          <Link
            to={{
              pathname: "/company/member/details/",
              search: "?id=0",
            }}
          >
            <Button
              type="primary"
            >
              新增會員
          </Button>
          </Link>

          <Button
            type="primary"
            onClick={() => onClickExportExcel()}
          >
            導出紀錄 (Excel)
          </Button>

          <UploadSection
            webcast_event_id="1"
            onSuccess={(resp) => {
              debug(`resp >>SendEmailButton `, resp);
              const {
                originalname,
                filename,
                filepath,
                url,
              } = resp;
              importUserCSV(filepath)
              // this.importUserCSV(webcast_event_id, filepath);
            }}
          />




        </Space>





      </div>
      <Divider /> */}
      {/* <Button
        onClick={() => handleTestEmail()}
      >
        Send Test Email
      </Button> */}

      <Tabs defaultActiveKey={defaultActiveKey}
        onChange={(value) => {
          // getTapValue(value)
        }}>
        <TabPane tab="交流空間" key="1" >
          <Spin
            indicator={<LoadingOutlined />}
            spinning={loading}
          >
            <MemberListTable
            
              dataObj={itemEvents2}
              // adminChangeData={getAllData}
              // defaultActiveKey={2}
              // modalVisible={modalVisible}
              // setModalVisible={setModalVisible}
              callBack={() => getBookingItem()}
            />
          </Spin>
        </TabPane>
      

        <TabPane tab="共享工藝室" key="2">
          <MemberListTable
            dataObj={itemEvents1}
            // defaultActiveKey={1}
            // adminChangeData={getAllData}
            // modalVisible={modalVisible}
            // setModalVisible={setModalVisible}
            callBack={() => getBookingItem()}

          />
        </TabPane>

        {/* <TabPane tab="停用" key="3">
          <MemberListTable
            dataObj={inactive_dataList}
            defaultActiveKey={3}
            adminChangeData={getAllData}
            callBack={() => getAllData()}
          />
        </TabPane> */}


      </Tabs>
      
      {/* <MemberListTable
        dataObj={dataList}
        adminChangeData={getAllData}
      /> */}
    </NavAndSideFrame>
  )
}

export default MemberList;