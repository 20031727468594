import React, { Component } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Avatar, Button, Divider, Layout, Menu, Modal, Popconfirm, Table, Row, Col } from 'antd';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import background from '../assets/BG_Jhub.jpg';

const { Header, Content, Sider } = Layout;

const MemberFrame = (props) => {
  let {children} = props;

  return (
    <Layout
      style={{
        width: '100%',
        background: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        minHeight: '100vh',
        padding: '50px',
      }}
    >
      <Content>
        <Row justify="center">
          <Col
            style={{
              maxWidth: '1280px',
              margin: '20px 0'
            }}
          >
            {children}
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export { MemberFrame };
