import React, { Component, useEffect, useState } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Content, Input, Layout, Row, Select, Tabs, message, Form, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, Link, useLocation } from 'react-router-dom';
// import 'antd/dist/antd.css';
// import '@ant-design/compatible/assets/index.css';
import _ from 'lodash';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import StaticPageEditForm from '../../components/form/StaticPageEditForm';
import FileEditForm from '../../components/form/FileEditForm'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const AdminSettingsDetails = (props) => {
  const [t] = useTranslation('common');
  let query = useQuery()
  let type = query.get("type")

  useEffect(() => {
  }, []);

  const renderForm = () => {
    switch (type) {
      case "static_page":
        return <StaticPageEditForm />
        break;
      case "file":
        return <FileEditForm />
        break;
      default:
        return <StaticPageEditForm />
        break;
    }
  }

  return (
    <NavAndSideFrame title="設定">
      {renderForm()}
    </NavAndSideFrame>
  )
}

export default AdminSettingsDetails
